
import Cookies from 'js-cookie'
import { mapState } from 'vuex'

import { LangEnum } from '@/utils/constBase'
import { isHiddenHeaderFn } from '@/utils/utils'
import { removeAnalyticsListCookie } from '@/utils/cookieOperation'
import LOCALE from '@/utils/locale'
import { CHANNEL, STORAGE_KEY } from '@/reporter/config/const.js'
import { channelTrack } from '@/reporter/utils'
import { onCLS, onLCP, onTTFB, onFCP, onFID } from '@/utils/webVitals'
import {
  WebCLS,
  WebLCP,
  WebTTFB,
  WebFCP,
  WebFID,
} from '@/reporter/track/webVitals'

export default {
  data() {
    return {
      curScrollHeight: 0,
      scrollClass: '',
    }
  },
  head() {
    return {
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$t(`navs.commonKeywords`),
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isHome: (state) => state.isHome,
      isHdFtMobile: (state) => state.isHdFtMobile,
      locale: (state) => state.locale,
      loginToken: (state) => state.loginToken,
      isAgree: (state) => state.cookie_agree.agree,
      isMobile: (state) => state.isMobile,
      cookieBtnDisagree: (state) => state.cookie_btn_disagree,
    }),
    // 设置全局antd 语言
    configLocale() {
      return LOCALE[LangEnum[this.locale].code]
    },
    // 判断是否需要隐藏部分公共部分
    isHiddenHeader() {
      return isHiddenHeaderFn(this.$route, this.isMobile)
    },
    query() {
      return this.$route.query
    },
  },
  async created() {
    // 获取物流服务菜单
    const res = await this.$API.product.getLogisticsServiceMenuApi()
    // 物流产品 ，增值服务，附加费 ,前端设置遍历顺序
    const sortArr = ['product', 'increment', 'addition']
    const productMenus = []
    const temp = res?.result || {}
    sortArr.forEach((key) => {
      let item = {}
      if (temp[key]) {
        item = { ...temp[key] }
        item.cname = temp[key].name
        item.path = `/product`
        item.name = key
        item.il8nName = ''
        item.hasNext = key === 'product' // 物流产品有4级菜单
        item.children = this.doMenuData(temp[key].children, key)
        item.params = { hash: key }
        productMenus.push({ ...item })
      }
    })
    this.$store.commit('addProductMenu', productMenus)
  },
  mounted() {
    const preference = Cookies.get('option.preference')
    // 开启了GDPR的分析类
    if (preference) {
      const obj = JSON.parse(preference)
      if (!obj.analytics) {
        removeAnalyticsListCookie()
      } else {
        // 添加百度埋点
        const hm = document.createElement('script')
        hm.src = 'https://hm.baidu.com/hm.js?3bb0f07b0490eb4831bd081b5e06c9d4'
        const s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(hm, s)
      }
    } else {
      removeAnalyticsListCookie()
    }
    this.initTrack()
    onCLS(WebCLS)
    onLCP(WebLCP)
    onTTFB(WebTTFB)
    onFCP(WebFCP)
    onFID(WebFID)
  },
  // 移除滚动条监听事件
  destroyed() {},
  methods: {
    doMenuData(children = [], rootCode) {
      return (
        children?.map((i) => {
          const item = {}
          item.cname = i.name
          item.path = i.code ? `/product/${i.code}` : ''
          item.name = i.code || i.name
          item.il8nName = ''
          i.children && (item.children = this.doMenuData(i.children, rootCode))
          item.params = i.code ? { query: { isProduct: rootCode } } : {}
          item.aliasName = i.aliasName
          return item
        }) || []
      )
    },
    initTrack() {
      const channel = this.getChannel()
      if (channel) {
        channelTrack(channel)
        this.setChannel(channel)
      }
    },
    findAttr(query, object) {
      const sysCode = query.sysCode
      return sysCode || false
    },
    getChannel() {
      const channel = sessionStorage.getItem(STORAGE_KEY.channel)
      return channel || this.findAttr(this.query, CHANNEL)
    },
    setChannel(channel) {
      sessionStorage.setItem(STORAGE_KEY.channel, channel)
    },
  },
}
