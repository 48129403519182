import { singleTrack, urlKeywordTrack, signKeywordTrack } from '../utils'
import { EVENT_TYPE } from '../config/const'

/**
 * 网站顶部-登录按钮点击事件
 * @param {*} property
 */
export const LoginTopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Login_Top_Click, property)
}

/**
 * 网站顶部-注册按钮点击事件
 * @param {*} property
 */
export const RegisterTopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Register_Top_Click, property)
}
/**
 * 网站悬浮栏-客服-快递咨询点击事件
 * @param {*} property
 */
export const ExpressserviceHoverbarClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Expressservice_Hoverbar_Click, property)
}

/**
 * 网站悬浮栏-客服-电商咨询点击事件
 * @param {*} property
 */
export const EcommerceserviceHoverbarClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Ecommerceservice_Hoverbar_Click, property)
}

/**
 * 网站悬浮栏-客服-SFBuy咨询点击事件
 * @param {*} property
 */
export const SFBuyserviceHoverbarClick = (property = {}) => {
  singleTrack(EVENT_TYPE.SFBuyservice_Hoverbar_Click, property)
}
/**
 * 网站悬浮栏-商务合作点击事件
 * @param {*} property
 */
export const CooperateHoverbarClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Cooperate_Hoverbar_Click, property)
}

/**
 * 产品介绍详情页-马上寄件点击事件
 * @param {*} property
 */
export const ShippingProductClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Shipping_Product_Click, property)
}

/**
 * 查运费时效-去下单点击事件
 * @param {*} property
 */
export const ShippingTimeCostClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Shipping_Time_Cost_Click, property)
}

/**
 * 首页-立即查单点击事件
 * @param {*} property
 */
export const TrackingHomeClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Tracking_Home_Click, property)
}

/**
 * 查运单-查询按钮点击事件
 * @param {*} property
 */
export const QueryTrackingClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Tracking_Click, property)
}

/**
 * 查运单-三星件-“预约派送日期/自取”按钮点击
 * @param {*} property
 */
export const TrackingAppointmentClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Tracking_Appointment_Click, property)
}

/**
 * 查运单-三星件-【手机号验证页】的“提交”按钮点击
 * @param {*} property
 */
export const AppointmentMobileSubmitClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Appointment_Mobile_Submit_Click, property)
}

/**
 * 查运单-三星件-【预约派送日期/自取页】的底部“确定”按钮点击
 * @param {*} property
 */
export const AppointmentConfirmClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Appointment_Confirm_Click, property)
}

/**
 * 查运单-三星件-“取消预约”按钮点击
 * @param {*} property
 */
export const AppointmentCancelClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Appointment_Cancel_Click, property)
}

/**
 * 查运单-三星件-【运单查询结果页】的“修改收件人”按钮点击
 * @param {*} property
 */
export const TrackingModifyReceiverClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Tracking_Modify_Receiver_Click, property)
}

/**
 * 查运单-三星件-【取消申请】的弹窗“是”按钮点击
 * @param {*} property
 */
export const ModifyReceiverCancelPopupyesClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Modify_Receiver_Cancel_Popup_yes_Click, property)
}

/**
 * 查运单-修改预约时间成功
 */
export const TrackingAppointmentSuccess = (property = {}) => {
  singleTrack(EVENT_TYPE.Tracking_Modify_Receiver_Success, property)
}
/**
 * 查运单-改单成功
 */
export const TrackingModifyReceiverSuccess = (property = {}) => {
  singleTrack(EVENT_TYPE.Tracking_Modify_Receiver_Success, property)
}

/**
 * 查运费时效-查询按钮点击事件
 * @param {*} property
 */
export const QueryTimeCostClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Time_Cost_Click, property)
}

/**
 * 查收寄标准-查询按钮点击事件
 * @param {*} property
 */
export const QueryItemrestrictionClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Itemrestriction_Click, property)
}

/**
 * 查收送范围-菜单按钮点击事件
 * @param {*} property
 */
export const QueryServicecoverageClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Servicecoverage_Click, property)
}

/**
 * 查服务网点-查询按钮点击事件
 * @param {*} property
 */
export const QueryServicepointClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Servicepoint_Click, property)
}

/**
 * 税金查询-查询按钮点击事件
 * @param {*} property
 */
export const QueryDutytaxClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Dutytax_Click, property)
}

/**
 * 进出口规则查询-菜单按钮点击事件
 * @param {*} property
 */
export const QueryInportexportruleClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Inportexportrule_Click, property)
}

/**
 * 上传清关图片-提交按钮点击事件
 * @param {*} property
 */
export const SubmitClearancepicuploadClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Submit_Clearancepicupload_Click, property)
}

/**
 * 清关申报信息收集-查询按钮点击事件
 * @param {*} property
 */
export const QueryClearanceinfocollectClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Clearanceinfocollect_Click, property)
}

/**
 * 清关申报信息收集-提交按钮点击事件
 * @param {*} property
 */
export const SubmitClearanceinfocollectSubmit = (property = {}) => {
  singleTrack(EVENT_TYPE.Submit_Clearanceinfocollect_Submit, property)
}

/**
 * 报关单查询-查询按钮点击事件
 * @param {*} property
 */
export const QueryClearanceformClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Clearanceform_Click, property)
}

/**
 * 报关单查询-下载按钮点击事件
 * @param {*} property
 */
export const QueryClearanceformDownload = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Clearanceform_Download, property)
}

/**
 * 税种和清关服务费查询-查询按钮点击事件
 * @param {*} property
 */
export const QueryDutyservicefeeQuery = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Dutyservicefee_Query, property)
}

/**
 * 文件自助下载-下载按钮点击事件
 * @param {*} property
 */
export const DownloadDownloadcenterClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Download_Downloadcenter_Click, property)
}

/**
 * 进口清关指南-查询按钮点击事件
 * @param {*} property
 */
export const QueryImportclearanceruleClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_Importclearancerule_Click, property)
}

/**
 * 进口清关指南-文件查看
 * @param {*} property
 */
export const DownloadImportClearanceruleClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Download_ImportClearancerule_Click, property)
}

/**
 * 在线申报平台-"编辑出口申报信息"_点击
 * @param {*} property
 */
export const OnlineCustomDeclarationEditExportClick = (property = {}) => {
  singleTrack(EVENT_TYPE.OnlineCustomDeclaration_EditExport_Click, property)
}
/**
 * 在线申报平台-"编辑进口申报信息"_点击
 * @param {*} property
 */
export const OnlineCustomDeclarationEditImportClick = (property = {}) => {
  singleTrack(EVENT_TYPE.OnlineCustomDeclaration_EditImport_Click, property)
}

/**
 * 在线申报平台-上传单证"提交"按钮_点击
 * @param {*} property
 */
export const OnlineCustomsDeclarationOrderDetailSubmitDocumentClick = (
  property = {}
) => {
  singleTrack(
    EVENT_TYPE.OnlineCustomsDeclaration_OrderDetail_SubmitDocument_Click,
    property
  )
}

/**
 * 在线申报平台-表单"提交"按钮_点击
 * @param {*} property
 */
export const OnlineCustomsDeclarationOrderDetailSubmitFormClick = (
  property = {}
) => {
  singleTrack(
    EVENT_TYPE.OnlineCustomsDeclaration_OrderDetail_SubmitForm_Click,
    property
  )
}

/**
 * 在线申报平台-报关申报指引入口点击量
 */
export const OnlineCustomsDeclarationGuideClick = (property = {}) => {
  singleTrack(EVENT_TYPE.OnlineCustomsDeclaration_Guide_Click, property)
}
/**
 * 官网页面访问量
 * @param {*} property
 */
export const MainlandChinaTaxWebView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_Web_View, property)
}

/**
 * 速运小程序 H5 页面访问量
 * @param {*} property
 */
export const MainlandChinaTaxMiniAppView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_MiniApp_View, property)
}

/**
 * 速运 APP H5 页面访问量
 * @param {*} property
 */
export const MainlandChinaTaxAppView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_App_View, property)
}

/**
 * 速运 APP H5 页面访问量
 * @param {*} property
 */
export const MainlandChinaTaxWebClick = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_Web_Click, property)
}

/**
 * 速运 APP H5 页面访问量
 * @param {*} property
 */
export const MainlandChinaTaxMiniAppClick = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_MiniApp_Click, property)
}

/**
 * 速运 APP H5 页面访问量
 * @param {*} property
 */
export const MainlandChinaTaxAppClick = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_App_Click, property)
}

// 凭证
/**
 * pc C_RP
 * @param {*} property
 */
export const MainlandChinaTaxWebCrpView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_Web_CrpView, property)
}

/**
 * miniapp C_RP
 * @param {*} property
 */
export const MainlandChinaTaxMiniAppCrpView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_MiniApp_CrpView, property)
}

/**
 * app C_RP
 * @param {*} property
 */
export const MainlandChinaTaxAppCrpView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_App_CrpView, property)
}

/**
 * pc C_RP
 * @param {*} property
 */
export const MainlandChinaTaxWebCediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_Web_CediView, property)
}

/**
 * miniapp C_RP
 * @param {*} property
 */
export const MainlandChinaTaxMiniAppCediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_MiniApp_CediView, property)
}
/**
 * app C_RP
 * @param {*} property
 */
export const MainlandChinaTaxAppCediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_App_CediView, property)
}

/**
 * pc C_RP
 * @param {*} property
 */
export const MainlandChinaTaxWebFediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_Web_FediView, property)
}

/**
 * miniapp C_RP
 * @param {*} property
 */
export const MainlandChinaTaxMiniAppFediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_MiniApp_FediView, property)
}

/**
 * app C_RP
 * @param {*} property
 */
export const MainlandChinaTaxAppFediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_App_FediView, property)
}

/**
 * pc C_RP
 * @param {*} property
 */
export const MainlandChinaTaxWebBediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_Web_BediView, property)
}

/**
 * miniapp C_RP
 * @param {*} property
 */
export const MainlandChinaTaxMiniAppBediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_MiniApp_BediView, property)
}

/**
 * app C_RP
 * @param {*} property
 */
export const MainlandChinaTaxAppBediView = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_App_BediView, property)
}

/**
 * 马上寄件-寄件指南内容
 */
export const ShippingShippingLinkClick = (property = {}) => {
  urlKeywordTrack(EVENT_TYPE.Shipping_Shipping_LinkClick, property)
}

/**
 * 加签-加签错误
 */
export const SignError = (property) => {
  signKeywordTrack(EVENT_TYPE.Sign_Error_Track, property)
}

/**
 * 官网页面查询待支付运单号
 */
export const MainlandChinaTaxToBePaid = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_ToBePaid, property)
}

/**
 * 官网页面支付按钮点击量
 */
export const MainlandChinaTaxClickPay = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_ClickPay, property)
}

/**
 * 官网页面支付成功次数
 */
export const MainlandChinaTaxPaySuccess = (property = {}) => {
  singleTrack(EVENT_TYPE.MainlandChinaTax_PaySuccess, property)
}

/**
 * 发票申请--查询按钮点击
 */
export const InvoiceQueryButton = (property = {}) => {
  singleTrack(EVENT_TYPE.Invoice_Query_Button, property)
}

/**
 * 发票申请--发票申请成功
 */
export const InvoiceApplySuccess = (property = {}) => {
  singleTrack(EVENT_TYPE.Invoice_Apply_Success, property)
}
