
// import LOCALE from '@/utils/locale'
import { CountryLangType } from '@/utils/const'
export default {
  props: {
    error: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      timer: null,
      count: 3,
    }
  },
  computed: {
    errorTip1() {
      return this.$t('main.errorTip1')
    },
  },
  created() {
    const { params } = this.$route
    if (params?.lang) {
      this.$i18n.locale = CountryLangType[params.lang]
    } else {
      this.$i18n.locale = 'en'
    }
  },
  mounted() {
    // this.timer = window.setInterval(() => {
    //   this.count--
    //   if (this.count === 0) {
    //     window.clearInterval(this.timer)
    //     this.$router.push({ path: '/' })
    //   }
    // }, 1000)
  },
  methods: {},
}
