
import Icon from './icon.vue'
export default {
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      default: 'info',
    },
    text: {
      type: String,
      default: '',
    },
  },
}
