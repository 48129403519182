import allUrls from '@/utils/config'
import gjUtmConfig from '@/utils/gjUtm'
const config = {
  cn: {
    shareSubList: [],
    service: {
      product: [
        {
          tel: '95338',
          telephone: '95338',
          list: [
            'modules.logisticsService.productList.fast', // 国际标快
            'modules.logisticsService.productList.discts', // 国际特惠
          ],
          time: ['relation.expressTime'],
        },
        {
          list: [
            'modules.logisticsService.productList.parcel', // 国际小包
            'modules.logisticsService.productList.delivery', // 国际电商专递
          ],
          time: ['relation.OfficeTime9_20'], // 营业时间
        },
        {
          list: [
            'modules.logisticsService.productList.warehouse', // 海外仓
          ],
          time: ['relation.OfficeTime9_18'], // 营业时间
        },
      ],
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'modules.about.homePageTitles.about_SF_Express', // 顺丰速运
            href:
              allUrls.sfExpressUrl + '/cn/' + (lang === 'sc' ? 'sc/' : 'en/'),
          },
          {
            type: 'link',
            gjUtm: gjUtmConfig.sfIntlOsLogin.cn,
            name: 'modules.about.homePageTitles.businessSystem', // 顺丰国际下单系统
            href: allUrls.iuoploginUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.sfBuy', // 顺丰集运
            href: allUrls.sfbuyUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.klnSystem', // 嘉里物流联网
            href: allUrls.klnUrl + '/' + lang,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.Ezhou', // 顺丰鄂州国际货站
            href: allUrls.ezhou,
          },
        ]
      },
      customerUrl: allUrls.iuopCustomerUrl, // 商务合作
    },
  },
  sg: {
    shareSubTitle: 'modules.about.homePageTitles.followSg',
    shareSubList: [
      {
        name: 'sg_facebook',
        tips: 'common.media.facebook',
        image: '~/assets/img/icons/facebook.svg',
        link: 'https://www.facebook.com/profile.php?id=61553079237157',
      },
      {
        name: 'sg_whatsApp',
        tips: 'common.media.whatsApp',
        image: '~/assets/img/icons/whatsapp.png',
        qrcode: '~/assets/img/footer/sg_qrcode_whatsapp.png',
      },
    ],
    service: {
      product: [
        {
          name: 'common.country.sg',
          tel: '18003111111',
          telephone: '1800 311 1111',
          list: [],
          time: [
            'relation.OfficeTimeSg1',
            'relation.OfficeTimeSg2',
            'relation.OfficeTimeSg3',
          ], // 营业时间
        },
      ],
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'modules.about.homePageTitles.about_SF_Express', // 顺丰速运
            href:
              allUrls.sfExpressUrl + '/cn/' + (lang === 'sc' ? 'sc/' : 'en/'),
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.businessSystem', // 顺丰国际下单系统
            gjUtm: gjUtmConfig.sfIntlOsLogin.sg,
            href: allUrls.iuopGloballoginUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.sfBuy', // 顺丰集运
            href: allUrls.sfbuyUrl,
          },
          // {
          //   type: 'link',
          //   name: 'modules.about.homePageTitles.klnSystem', // 嘉里物流联网
          //   href: allUrls.klnUrl + '/' + lang,
          // },
        ]
      },
      customerUrl: allUrls.iuopGlobalCustomerUrl, // 商务合作
    },
  },
  my: {
    shareSubTitle: 'modules.about.homePageTitles.followMy',
    shareSubList: [
      {
        name: 'my_facebook',
        tips: 'common.media.facebook',
        image: '~/assets/img/icons/facebook.svg',
        qrcode: '~/assets/img/footer/my_qrcode_facebook.png',
        // link: 'https://www.facebook.com/sfexpressmy/',
      },
      {
        name: 'my_ins',
        tips: 'common.media.instagram',
        image: '~/assets/img/icons/ins.svg',
        qrcode: '~/assets/img/footer/my_qrcode_ins.png',
        // link: 'https://www.instagram.com/sfexpressmy/',
      },
      {
        name: 'my_lingying',
        tips: 'common.media.linkedIn',
        image: '~/assets/img/icons/lingyin.svg',
        qrcode: '~/assets/img/footer/my_qrcode_lingying.png',
        // link: 'https://my.linkedin.com/company/sf-express-malaysia',
      },
    ],
    service: {
      product: [
        {
          name: 'common.country.my',
          tel: '1800183331',
          telephone: '1800 18 3331',
          list: [],
          time: [
            'relation.OfficeTimeMy1',
            'relation.OfficeTimeMy2',
            'relation.OfficeTimeMy3',
          ], // 营业时间
        },
      ],
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'modules.about.homePageTitles.about_SF_Express', // 顺丰速运
            href:
              allUrls.sfExpressUrl + '/cn/' + (lang === 'sc' ? 'sc/' : 'en/'),
          },
          {
            type: 'link',
            gjUtm: gjUtmConfig.sfIntlOsLogin.my,
            name: 'modules.about.homePageTitles.businessSystem', // 顺丰国际下单系统
            href: allUrls.iuopGloballoginUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.sfBuy', // 顺丰集运
            href: allUrls.sfbuyUrl,
          },
          // {
          //   type: 'link',
          //   name: 'modules.about.homePageTitles.klnSystem', // 嘉里物流联网
          //   href: allUrls.klnUrl + '/' + lang,
          // },
        ]
      },
      customerUrl: allUrls.iuopGlobalCustomerUrl, // 商务合作
    },
  },
  th: {
    shareSubList: [],
    service: {
      product: [
        {
          name: 'common.country.th',
          tel: '020971889',
          telephone: '020971889',
          list: [],
          time: [
            'relation.OfficeTimeTh1',
            'relation.OfficeTimeTh2',
            'relation.OfficeTimeTh3',
          ], // 营业时间
        },
      ],
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'modules.about.homePageTitles.about_SF_Express', // 顺丰速运
            href:
              allUrls.sfExpressUrl + '/cn/' + (lang === 'sc' ? 'sc/' : 'en/'),
          },
          {
            type: 'link',
            gjUtm: gjUtmConfig.sfIntlOsLogin.th,
            name: 'modules.about.homePageTitles.businessSystem', // 顺丰国际下单系统
            href: allUrls.iuopGloballoginUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.sfBuy', // 顺丰集运
            href: allUrls.sfbuyUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.klnSystem', // 嘉里物流联网
            href: allUrls.klnUrl + '/' + lang,
          },
        ]
      },
      customerUrl: allUrls.iuopGlobalCustomerUrl, // 商务合作
    },
  },
  id: {
    shareSubList: [],
    service: {
      product: [
        {
          name: 'common.country.id',
          tel: '1500230',
          telephone: '1500 230',
          list: [],
          time: [
            'relation.OfficeTimeId1',
            'relation.OfficeTimeId2',
            'relation.OfficeTimeId3',
          ], // 营业时间
        },
      ],
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'modules.about.homePageTitles.about_SF_Express', // 顺丰速运
            href:
              allUrls.sfExpressUrl + '/cn/' + (lang === 'sc' ? 'sc/' : 'en/'),
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.businessSystem', // 顺丰国际下单系统
            gjUtm: gjUtmConfig.sfIntlOsLogin.id,
            href: allUrls.iuopGloballoginUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.sfBuy', // 顺丰集运
            href: allUrls.sfbuyUrl,
          },
        ]
      },
      customerUrl: allUrls.iuopGlobalCustomerUrl, // 商务合作
    },
  },
  ph: {
    shareSubTitle: 'modules.about.homePageTitles.followPhilippines',
    shareSubList: [
      {
        name: 'ph_facebook',
        tips: 'common.media.facebook',
        image: '~/assets/img/icons/facebook.svg',
        link: 'https://www.facebook.com/sfexpress.philippines',
      },
      {
        name: 'ph_ins',
        tips: 'common.media.instagram',
        image: '~/assets/img/icons/ins.svg',
        link: 'https://www.instagram.com/sfexpress.philippines',
      },
      {
        name: 'ph_wechat',
        tips: 'common.media.wechat',
        image: '~/assets/img/icons/wx.svg',
        qrcode: '~/assets/img/footer/ph_qrcode_wechat.png',
      },
      {
        name: 'ph_whatsApp',
        tips: 'common.media.whatsApp',
        image: '~/assets/img/icons/whatsapp.png',
        qrcode: '~/assets/img/footer/ph_qrcode_whatsapp.png',
      },
      {
        name: 'ph_viber',
        tips: 'common.media.viber',
        image: '~/assets/img/icons/viber.png',
        qrcode: '~/assets/img/footer/ph_qrcode_viber.png',
      },
      {
        name: 'ph_xiaohongshu',
        tips: 'common.media.xiaohongshu',
        image: '~/assets/img/icons/xiaohongshu.png',
        qrcode: '~/assets/img/footer/ph_qrcode_xiaohongshu.png',
      },
    ],
    service: {
      product: [
        {
          name: 'common.country.ph',
          tel: '09985881865',
          telephone: '09985881865',
          list: [],
          time: ['relation.OfficeTimePh1', 'relation.OfficeTimePh2'], // 营业时间
        },
      ],
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'modules.about.homePageTitles.about_SF_Express', // 顺丰速运
            href:
              allUrls.sfExpressUrl + '/cn/' + (lang === 'sc' ? 'sc/' : 'en/'),
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.businessSystem', // 顺丰国际下单系统
            gjUtm: gjUtmConfig.sfIntlOsLogin.ph,
            href: allUrls.iuopGloballoginUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.sfBuy', // 顺丰集运
            href: allUrls.sfbuyUrl,
          },
        ]
      },
      customerUrl: allUrls.iuopGlobalCustomerUrl, // 商务合作
    },
  },
  vn: {
    shareSubList: [],
    service: {
      product: [
        {
          name: 'common.country.vn',
          tel: '1800 8110',
          telephone: '1800 8110',
          list: [],
          time: [
            'relation.OfficeTimeVn1',
            'relation.OfficeTimeVn2',
            // 'relation.OfficeTimeVn3',
          ], // 营业时间
        },
      ],
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'modules.about.homePageTitles.about_SF_Express', // 顺丰速运
            href:
              allUrls.sfExpressUrl + '/cn/' + (lang === 'sc' ? 'sc/' : 'en/'),
          },
          {
            type: 'link',
            gjUtm: gjUtmConfig.sfIntlOsLogin.vn,
            name: 'modules.about.homePageTitles.businessSystem', // 顺丰国际下单系统
            href: allUrls.iuopGloballoginUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.sfBuy', // 顺丰集运
            href: allUrls.sfbuyUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.klnSystem', // 嘉里物流联网
            href: allUrls.klnUrl + '/' + lang,
          },
        ]
      },
      customerUrl: allUrls.iuopGlobalCustomerUrl, // 商务合作
    },
  },
  jp: {
    shareSubList: [],
    service: {
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'common.country.sf', // 国际官网
            href: '/cn/' + (lang === 'sc' ? 'sc' : 'en'),
            replace: true,
          },
          {
            name: 'navs.termsOfUse', // 使用条款
            href: '/support/termsOfUse',
          },
          {
            name: 'navs.personProtection', // 个人信息保护
            href: '/about/infoProtection',
            hidden: {
              lang: ['en', 'sc'],
            },
          },
          {
            name: 'navs.privacyPolicy', // 隐私政策
            href: '/support/privacyPolicy',
          },
          {
            name: 'navs.transactionLaw', // 基于特定商业交易法的展示
            href: '/about/businessLaw',
            hidden: {
              lang: ['en', 'sc'],
            },
          },
        ]
      },
    },
  },
  au: {
    shareSubTitle: 'modules.about.homePageTitles.followSf',
    shareSubList: [
      {
        name: 'my_facebook',
        tips: 'common.media.facebook',
        image: '~/assets/img/icons/facebook.svg',
        qrcode: '~/assets/img/footer/my_qrcode_facebook.png',
        // link: 'https://www.facebook.com/sfexpressmy/',
      },
      {
        name: 'my_ins',
        tips: 'common.media.instagram',
        image: '~/assets/img/icons/ins.svg',
        qrcode: '~/assets/img/footer/my_qrcode_ins.png',
        // link: 'https://www.instagram.com/sfexpressmy/',
      },
      {
        name: 'my_lingying',
        tips: 'common.media.linkedIn',
        image: '~/assets/img/icons/lingyin.svg',
        qrcode: '~/assets/img/footer/my_qrcode_lingying.png',
        // link: 'https://my.linkedin.com/company/sf-express-malaysia',
      },
    ],
    service: {
      product: [
        {
          name: 'navs.auCountry',
          tel: '1300148168',
          telephone: '1300148168',
          list: [],
          time: [
            'relation.OfficeTimeAu1',
            'relation.OfficeTimeAu2',
            'relation.OfficeTimeAu3',
          ],
        },
        {
          name: 'navs.nzCountry',
          tel: '+64 96660336',
          telephone: '+64 96660336',
          list: [],
          time: [
            'relation.OfficeTimeNz1',
            'relation.OfficeTimeNz2',
            'relation.OfficeTimeNz3',
          ], // 营业时间
        },
      ],
      otherSite: (lang) => {
        return [
          {
            type: 'link',
            name: 'modules.about.homePageTitles.about_SF_Express', // 顺丰速运
            href:
              allUrls.sfExpressUrl + '/cn/' + (lang === 'sc' ? 'sc/' : 'en/'),
          },
          {
            type: 'link',
            gjUtm: gjUtmConfig.sfIntlOsLogin.my,
            name: 'modules.about.homePageTitles.businessSystem', // 顺丰国际下单系统
            href: allUrls.iuopGloballoginUrl,
          },
          {
            type: 'link',
            name: 'modules.about.homePageTitles.sfBuy', // 顺丰集运
            href: allUrls.sfbuyUrl,
          },
          // {
          //   type: 'link',
          //   name: 'modules.about.homePageTitles.klnSystem', // 嘉里物流联网
          //   href: allUrls.klnUrl + '/' + lang,
          // },
        ]
      },
      customerUrl: allUrls.iuopGlobalCustomerUrl, // 商务合作
    },
  },
}

export default config
