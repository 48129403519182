
export default {
  props: {
    poster: {
      type: String,
      default: require('~/assets/img/landing/banner_ad.png'),
    },
    height: {
      type: [String, Number],
      default: 680,
    },
  },
  data() {
    return {
      videoOptions: {
        autoplay: true,
        fluid: false,
        loop: true,
        height: this.height,
        muted: true,
        preload: 'auto',
        controls: false,
        poster: this.poster,
        sources: [
          {
            type: 'video/mp4',
            src: require('~/assets/video/banner.mp4'), // 视频url地址
          },
        ],
      },
    }
  },
  methods: {
    setStyle() {
      const style = `background: url(${this.poster}) center center no-repeat; background-size: 100% 100%;height: ${this.height}px`
      return style
    },
    onPlayer() {
      const timer = setInterval(() => {
        if (this.$refs?.videoRef?.player) {
          clearInterval(timer)
          this.$refs.videoRef.player.play()
        }
      }, 1000)
    },
    onPlayerError(ev) {
      console.log(ev)
    },
    onPlayerCanplaythrough(player) {
      this.onPlayer()
    },
  },
}
