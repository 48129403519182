
import { mapState } from 'vuex'

export default {
  data() {
    return {
      unfold: false,
      keyword: '',
    }
  },
  computed: {
    ...mapState({
      isHdFtMobile: (state) => state.isHdFtMobile,
    }),
    routeName() {
      return this.$route.name
    }
  },
  methods: {
    onExpose(ev) {
      if (!this.isHdFtMobile) {
        this.unfold = true
        this.$refs.input.focus()
        this.$emit('expose', this.unfold)
      } else {
        this.goSearchRoute()
        this.$store.commit('saveIsPopup', false)
      }
      ev.stopPropagation()
    },
    onUnExpose() {
      this.unfold = false
      this.$emit('expose', this.unfold)
    },
    onSearch(ev) {
      if (this.unfold) {
        this.onUnExpose()
        this.goSearchRoute()
      }
      ev.stopPropagation()
    },
    goSearchRoute() {
      const params = {
        name: 'site-lang-about-search',
        params: {
          site: this.$route.params.site,
          lang: this.$route.params.lang,
        },
        query: {
          keyword: this.keyword,
        },
      }
      if (this.routeName !== 'site-lang-about-search') {
        this.$router.push(params)
      } else {
        this.$router.replace(params)
        const timer = setTimeout(() => {
          clearTimeout(timer)
          location.reload()
        }, 0);
      }
    },
  },
}
