/*
 * @Author: liyang
 * @Date: 2021-04-20 20:35:15
 * @LastEditTime: 2023-08-30 15:23:41
 * @LastEditors: yuanxing(01407381)
 * @FilePath: \ibu-owt-core-frontend\utils\config.js
 * @Description: 配置文件
 */

// 配置文件环境
// const CONFIG_ENV = process.env.CONFIG_ENV
// 系统编码
const SysCode = 'OWT'
// 对接ICas
// const ICASUrl = {
//   dev: 'http://ibu-icas.sit.sf-express.com',
//   uat: 'https://icas.sit.sf.global',
//   prod: 'https://icas.sf.global',
// }

// 顺丰云 可直接获取全局配置-暂未加上，后面部署可添加
let GlobalConfig = {}

if (process.browser) {
  GlobalConfig = window.g || {}
}

const CONFIG = {
  // 暂时先用的腾讯云COS-静态文件CDN地址
  cdnUrl: 'https://mobanku-1251224359.cos.ap-shanghai.myqcloud.com/owt',
  // webp图片地址
  webpCdnUrl:
    'https://mobanku-1251224359.cos.ap-shanghai.myqcloud.com/owt/webp',
  // 系统编码
  sysCode: SysCode,
  // 默认语言
  defaultLang: 'zh-CN',
  // 版本
  version: '2.5.0',
  // 高德地图 key
  // Web端
  amapKey: 'e9e9290c100dd565ffcc33e58e4e330e',
  // 跳转icas注册
  // icasRegisterUrl: `${ICASUrl[CONFIG_ENV]}/#/register?sysCode=OWT&lang=zh-CN`,
  // 跳转icas登录-后面地址需要替换为新的部署域名地址-暂未加
  // icasLoginUrl: `${ICASUrl[CONFIG_ENV]}/#/?service=&lang=zh-CN&sysCode=OWT&from=https%3A%2F%2Fwww.sf-international.com%2Fzh%2Fuser%2FjumpOwt.do`,
  // 已登录状态-跳转回IUOP下单
  iuopLoginingUrl: `${GlobalConfig.iuopUrl}/#/logining?lang=zh-CN&token={token}&sysCode=${SysCode}&businessCode=OVERSEA_WAREHOUSE`,
  // IUOP
  // 未登录状态-跳转IUOP注册地址
  iuopRegisterUrl: `${GlobalConfig.iuopUrl}/#/register?lang=zh-CN`,
  // 未登录状态-跳转IUOP登录地址
  iuoploginUrl: `${GlobalConfig.iuopUrl}/#/?lang=zh-CN`,
  iuopCustomerUrl: `${GlobalConfig.iuopUrl}/#/customer-online?lang=zh-CN`,
  // 已登录状态-跳转回IUOP下单
  iuopGlobalLoginingUrl: `${GlobalConfig.iuopGlobalUrl}/#/logining?lang=zh-CN&token={token}&sysCode=${SysCode}&businessCode=OVERSEA_WAREHOUSE`,
  // IUOP
  // 未登录状态-跳转IUOP注册地址
  iuopGlobalRegisterUrl: `${GlobalConfig.iuopGlobalUrl}/#/register?lang=zh-CN`,
  // 未登录状态-跳转IUOP登录地址
  iuopGloballoginUrl: `${GlobalConfig.iuopGlobalUrl}/#/?lang=zh-CN`,
  iuopGlobalCustomerUrl: `${GlobalConfig.iuopGlobalUrl}/#/customer-online?lang=zh-CN`,
  // 低值通关申报
  iorderIFCUrl: `${GlobalConfig.iorderUrl}/icsm-fast-customs/index`,
  wherpUrl: `${GlobalConfig.iorderUrl}/icsm-fast-customs/index`,
  // 顺丰速运
  sfExpressRangeUrl: `${GlobalConfig.sfExpressUrl}/we/ow/chn/sc/range-query`, // 查收送范围
  // sfExpressRestrictedDeliveryUrl: `${GlobalConfig.sfExpressUrl}/we/ow/chn/sc/tax/ie`, // 进出口规则查询 --已下线
  sfExpressAcceptUrl: `${GlobalConfig.sfExpressUrl}/we/ow/chn/sc/accept-query`, // 国际件收寄标准
  sfExpressPriceUrl: `${GlobalConfig.sfExpressUrl}/we/ow/chn/sc/product-promotion/more/international_fuel_surcharge_introduction`, // 燃油附加费说明
  sfExpressTaxUrl: `${GlobalConfig.sfExpressUrl}/we/ow/chn/sc/tax/tax-query`, // 税金查询
  // 客服
  ocs2odpUrlSfbuy: `${GlobalConfig.ocs2odpUrl}/app/init?orgName=sy&channelId=140&clientType=1&accountId=`, // sfbuy客服
  ocs2odpUrlExpress: `${GlobalConfig.ocs2odpUrl}/app/init?orgName=sy&channelId=523&clientType=1&accountId=`, // 物流客服
  ocs2odpUrlTencent:
    'https://ockcsim.sf-express.com/webchat_new/static/html/index.html?ht=kGhpmZ', // 电商
  //
  wherpLoginUrl: `${GlobalConfig.wherpUrl}/wherp/login/login_login.action`, // 登陆地址
  // htmAirUrl: `${GlobalConfig.htmUrl}/hk/tc/dynamic_function/restricted_delivery/Prohibited_Items_Air/`, // 航空安全管制物品
  htmAirUrl: `${GlobalConfig.htmUrl}/hk/tc/dynamic_function/restricted_delivery/Prohibited_Items_Air/`, // 航空安全管制物品
  scUrl: GlobalConfig.scUrl,
  modifyReceiverUrl: `${GlobalConfig.sfgoOrderUrl}/#/modify-order`, // 顺丰国际下单平台修改收件人页面
  ciosActivity:
    'https://ibu-cios-activity.sf-express.com/impActivityChannel/redirect/2f9861f0587513bf19789269d1c2d542', // 国际APP下载链接
}

// 判断是否为生产环境
export function isProdEnv() {
  return GlobalConfig.env === 'prd'
}

export default Object.assign(CONFIG, GlobalConfig)
