/*
 * @Author: liyang
 * @Date: 2021-11-02 18:07:45
 * @FilePath: \ibu-owt-core-frontend\utils\httpCode.js
 * @Description: 请求状态码
 */

// 服务端定义code
export const ServiceCode = {
  sc_200: {
    code: 200,
    msg: '成功',
  },
  // 请求正常
  sc_0: {
    code: 0,
    msg: '请求正常',
  },
  sc_110: {
    code: 110,
    msg: 'token重定向',
  },
  sc_111: {
    code: 111,
    msg: '临时token重定向',
  },
}

// 是否属于重定向码
export const isRedirectCode = (val) => {
  const redirectCodes = [ServiceCode.sc_110.code, ServiceCode.sc_111.code]
  return redirectCodes.includes(val)
}

export const TOKEN_FAIL_FLAG = 'token failed'
