/*
 * @Author: liyang
 * @Date: 2021-03-19 16:27:07
 * @LastEditTime: 2024-01-12 19:00:25
 * @LastEditors: Please set LastEditors
 * @FilePath: \ibu-owt-core-frontend\middleware\i18n.js
 * @Description:
 */

// import { getcookiesInServer } from '@/utils/utils'
import { CountryLangType, specialLangSite } from '@/utils/const'

export default function ({ isHMR, app, route, store }) {
  const { site, lang } = route.params
  // const fullPath = route.fullPath
  if (isHMR) {
    return false
  }
  if (site && lang) {
    store.commit('setSite', site)
    store.commit('setLang', lang)
    // // 设置语言
    if (specialLangSite.includes(site)) {
      // 特殊语言
      app.i18n.locale = CountryLangType[site][lang]
      store.commit('setLocale', CountryLangType[site][lang])
    } else {
      // 常规语言
      app.i18n.locale = CountryLangType[lang]
      store.commit('setLocale', CountryLangType[lang])
    }
  }
}
