/*
 * @Author: liyang
 * @Date: 2021-04-26 11:53:29
 * @LastEditTime: 2022-01-19 10:46:37
 * @LastEditors: yuanxing
 * @FilePath: \globalweb\plugins\jump-config.js
 * @Description: 页面单独的跳转路径配置，统一管理
 */

import { routerPathEnum } from '@/router'

export default ({ app, route }, inject) => {
  inject('pconf', routerPathEnum(route.params.site))
}
