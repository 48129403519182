/*
 * @Descripttion: 物流服务路由
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2022-01-19 10:33:15
 * @LastEditors: yuanxing(01407381)
 * @LastEditTime: 2023-09-11 20:12:00
 */
// 物流服务---物流产品---电商产品
export const EbizProductList = [
  {
    // 中文注释 暂时无英文采用中文填充
    cname: '国际电商专递',
    // 标识
    name: 'delivery',
    // 国际化
    il8nName: 'modules.logisticsService.productList.delivery',
    // 路径/目录
    path: '/product/delivery',
    params: { query: { isProduct: 'product' } },
  },
  {
    cname: '国际小包',
    name: 'parcel',
    il8nName: 'modules.logisticsService.productList.parcel',
    path: '/product/parcel',
    params: { query: { isProduct: 'product' } },
  },
  // {
  //   cname: '国际经济小包',
  //   name: 'econparcel',
  //   il8nName: 'modules.logisticsService.productList.econparcel',
  //   path: '/product/econparcel',
  //   params: { query: { isProduct: 'product' } },
  // },
  //   {
  //     cname: '国际专线小包',
  //     name: 'lineparcel',
  //     il8nName: 'modules.logisticsService.productList.lineparcel',
  //     path: '/product/lineparcel',
  //     params: { query: { isProduct: 'product' } },
  //   },
  {
    cname: '海外仓',
    name: 'warehouse',
    il8nName: 'modules.logisticsService.productList.warehouse',
    path: '/product/warehouse',
    params: { query: { isProduct: 'product' } },
  },
  {
    cname: '集转货运(SFBuy)',
    name: 'sfbuy',
    il8nName: 'modules.logisticsService.productList.sfbuy',
    path: '/product/sfbuy',
    params: { query: { isProduct: 'product' } },
  },
]
// 物流服务---物流产品---快递产品
export const ExpressProductList = [
  {
    cname: '国际标快',
    name: 'fast',
    il8nName: 'modules.logisticsService.productList.fast',
    path: '/product/fast',
    params: { query: { isProduct: 'product' } },
  },
  {
    cname: '国际特惠',
    name: 'discts',
    il8nName: 'modules.logisticsService.productList.discts',
    path: '/product/discts',
    params: { query: { isProduct: 'product' } },
  },
]

// 物流服务---物流产品
export const LogisticsServiceList = [
  {
    cname: '快递',
    name: 'express_prod',
    classil8nName: 'modules.logisticsService.productList.express',
    il8nName: 'modules.logisticsService.productList.courier',
    path: '',
    children: ExpressProductList,
  },
  {
    cname: '电商',
    name: 'intl_ebiz_prod',
    classil8nName: 'modules.logisticsService.productList.commerce',
    il8nName: 'modules.logisticsService.productList.onlineRetailers',
    path: '',
    children: EbizProductList,
  },
]
// 物流服务---增值服务
export const ValueAddedProductsList = [
  {
    cname: '保价',
    name: 'insure',
    il8nName: 'modules.logisticsService.incrementList.insure',
    path: '/product/insure',
    params: { query: { isProduct: 'increment' } },
  },
  {
    cname: '派件地址变更服务',
    name: 'addrchange',
    il8nName: 'modules.logisticsService.incrementList.addrchange',
    path: '/product/addrchange',
    params: { query: { isProduct: 'increment' } },
  },
  {
    cname: '包装服务',
    name: 'packservice',
    il8nName: 'modules.logisticsService.incrementList.packservice',
    path: '/product/packservice',
    params: { query: { isProduct: 'increment' } },
  },
]
// 物流服务---附加费
export const AddedProductsList = [
  {
    cname: '国际偏远附加费',
    name: 'remoteAreaSurcharge',
    il8nName: 'modules.logisticsService.additionList.remoteAreaSurcharge',
    path: '/product/remoteAreaSurcharge',
    params: { query: { isProduct: 'addition' } },
  },
  {
    cname: '国际住宅附加费',
    name: 'housesurcharge',
    il8nName: 'modules.logisticsService.additionList.housesurcharge',
    path: '/product/housesurcharge',
    params: { query: { isProduct: 'addition' } },
  },
  {
    cname: '燃油附加费',
    name: 'fuelschge',
    il8nName: 'modules.logisticsService.additionList.fuelschge',
    path: '/product/fuelschge',
    params: { query: { isProduct: 'addition' } },
  },
  {
    cname: '超长超重附加费',
    name: 'overwgtfee',
    il8nName: 'modules.logisticsService.additionList.overwgtfee',
    path: '/product/overwgtfee',
    params: { query: { isProduct: 'addition' } },
  },
  {
    cname: '资源调节费',
    name: 'resourceallocationfee',
    il8nName: 'modules.logisticsService.additionList.resourceallocationfee',
    path: '/product/resourceallocationfee',
    params: { query: { isProduct: 'addition' } },
  },
]

export default {
  path: '',
  name: 'product',
  cname: '物流服务',
  il8nName: 'navs.logisticsService',
  children: [
    // 物流产品
    {
      cname: '物流产品',
      path: '/product',
      name: 'product',
      il8nName: 'modules.logisticsService.product',
      hasNext: true,
      children: LogisticsServiceList,
      params: { hash: 'product' },
    },
    // 增值服务
    {
      cname: '增值服务',
      path: '/product',
      name: 'increment',
      il8nName: 'modules.logisticsService.increment',
      children: ValueAddedProductsList,
      params: { hash: 'increment' },
    },
    // 附加费
    {
      cname: '附加费',
      path: '/product',
      name: 'addition',
      il8nName: 'modules.logisticsService.addition',
      children: AddedProductsList,
      params: { hash: 'addition' },
    },
  ],
}
