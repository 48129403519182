/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-03-21 10:51:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-19 11:02:08
 * @FilePath: \ibu-owt-core-frontend\utils\const\countryLang.js
 * @Description: 国家、语言，国际化配置
 */
// 特殊语言站点
export const specialLangSite = ['hk', 'tw']

// 特殊站点，采用default layout布局
export const specialSite = ['hk', 'tw']

// 特殊站点，控制路由展示
export const specialRouterSite = ['hk', 'tw', 'jp']

// 语言类型
export const CountryLangType = {
  hk: {
    tc: 'zh-HK', // 繁体
    en: 'en-HK',
    sc: 'zh-CN-HK', // 简体
  },
  tw: {
    tc: 'zh-TW', // 繁体
    en: 'en-TW',
    sc: 'zh-CN-TW', // 简体
  },
  // 中文简体
  sc: 'zh-CN',
  // 英文
  en: 'en-US',
  th: 'th-TH',
  vi: 'vi-VN',
  ja: 'ja-JP',
}

// 新官网支持的站点
export const reachableCountry = {
  cn: 'cn',
  hk: 'hk',
  tw: 'tw',
  sg: 'sg',
  my: 'my',
  th: 'th',
  id: 'id',
  ph: 'ph',
  vn: 'vn',
  jp: 'jp',
  au: 'au',
}

// 站内跳转站点列表
export const inSiteList = ['sg', 'my', 'th', 'id', 'ph', 'vn', 'jp', 'au']

export const fontFamilyLangMap = {
  th: '"TH Sarabun New"',
}

export const baseLang = ['zh-CN', 'zh-HK', 'en-US']
