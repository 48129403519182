
import { mapState } from 'vuex'
import MenuPc from './menu/pc.vue'
import MenuMb from './menu/mb.vue'
import config from '@/modules/config/header'

export default {
  components: { MenuPc, MenuMb },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      moduleName: '',
      searchInput: '',
      announcementSidebar: {},
      isPopups: false,
    }
  },
  computed: {
    ...mapState({
      isHome: (state) => state.isHome,
      isMobile: (state) => state.resizeInfo?.isMobile,
      isHdFtMobile: (state) => state.isHdFtMobile,
      isPopup: (state) => state.isPopup,
      router: (state) => state.router,
      isAgree: (state) => state.cookie_agree.agree,
    }),
    logoBlack() {
      if (this.isHdFtMobile) {
        return config[this.$route.params.site]?.logoMobile[
          this.$route.params.lang
        ]
      } else {
        return config[this.$route.params.site]?.logo[this.$route.params.lang]
      }
    },
  },
  watch: {
    isPopup(val) {
      this.isPopups = val
      if (val) {
        // 禁止蒙层下面一起滚动
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
  },
  created() {
    this.getAnnouncement()
  },
  methods: {
    setDefaultActive() {
      // 选中
      const fullPath = this.$route.fullPath
      const path = fullPath.split('?')[0]
      const moduleName = path.split('/')[3]
      this.moduleName = moduleName || 'home'
    },
    // 获取重要公告
    getAnnouncement() {
      this.$API.common.getAnnouncementSidebarApi().then((res) => {
        const pageInfo = res || {}
        this.announcementSidebar = pageInfo.announcementSidebar || {}
      })
    },
    // 移动端点击菜单svg事件
    togglePopup() {
      this.isPopups = !this.isPopups
      this.$store.commit('saveIsPopup', this.isPopups)
    },
    // 移动端点击菜单外关闭左侧菜单
    closePopups() {
      this.$store.commit('saveIsPopup', false)
    },
    backToHomePage() {
      const curLang = this.$route.params.lang
      const curSite = this.$route.params.site
      const url = window.location.origin + '/' + curSite + '/' + curLang
      location.replace(url)
    },
  },
}
