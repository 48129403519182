/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-08-29 17:05:09
 * @LastEditors: yuanxing(01407381)
 * @LastEditTime: 2023-08-30 15:39:43
 * @FilePath: \ibu-owt-core-frontend\plugins\ump.js
 * @Description: UMP APM接入
 * 参考文档，Uni：https://ibu-frame-doc.sit.sf-express.com/apm/sourcemap.html
 * 参考文档 接入事项:https://confluence.sf-express.com/pages/viewpage.action?pageId=348893386
 * 参考文档 接入系统APPID:https://confluence.sf-express.com/pages/viewpage.action?pageId=349963264
 */

import Vue from 'vue'
// import Logger from '@sf-intl/reporter-sdk'
import { CODEBUG } from 'sfapm'
import { isProdEnv } from '@/utils/config'

const apmInit = (app, config = {}) => {
  const defaultConfig = {
    APPID: 'cad65b40-7bc2-4a2d-9b3c-58ee6fd26336', // APPID申请联系人：李洋01394339
    // 区分运行时环境
    ENV: isProdEnv() ? 'PROD' : 'PROD',
    filter: (reportData) => {
      if (reportData.error && reportData.error.reason) {
        const errorList = [
          "TypeError: Cannot read properties of null (reading 'insertBefore')",
          'SyntaxError: Unexpected token',
          'global code@https://www.sf-international.com/sg/en/support/querySupport/waybill',
          'global code@https://www.sf-international.com/vn/vi/support/querySupport/waybill/mobile/result',
          'insert@https://feedback-web.sf-international.com/static/recommend-index.umd.js',
          "TypeError: Cannot read properties of null (reading 'insertAdjacentHTML')",
          "TypeError: Cannot read properties of null (reading 'toUpperCase')",
          'TypeError: this._engines.keys is not a function or its return value is not iterable',
          "TypeError: Cannot set properties of undefined (setting 'style')",
          "TypeError: Cannot read properties of undefined (reading 'style')",
        ]
        return !errorList.find((it) => reportData.error.reason.includes(it))
      } else {
        return true
      }
    },
    autoTrack: true,
  }
  const apmInstanceUmp = new CODEBUG(defaultConfig)
  // 捕获vue框架错误信息
  app.config.errorHandler = (err, vm, info) => {
    apmInstanceUmp.injectReport({
      error: err,
      message: info,
    })
  }
}

Vue.use(apmInit, {})
