/*
 * @Author: liyang
 * @Date: 2021-02-01 13:49:05
 * @LastEditTime: 2023-11-17 21:06:26
 * @LastEditors: Please set LastEditors
 * @FilePath: \ibu-owt-core-frontend\utils\constBase.js
 * @Description:
 */

// 移动端基本尺寸
export const mobilePx = 750

// 顶部导航和底部 展示成移动端样式最大宽度
export const maxContentWidth = 1100

// 国家地区二字码
export const CountryCode = {
  // 中国
  cn: 'cn',
  // 顺丰国际（旧）
  sf: 'sf',
  // 顺丰速运
  ex: 'ex',
  // 美国
  us: 'us',
  // 台湾
  tw: 'tw',
  // 香港
  hk: 'hk',
  // 香港,澳门
  hm: 'hm',
  // 澳门
  mo: 'mo',
  // 新加坡
  sg: 'sg',
  // 韩国
  kr: 'kr',
  // 马来西亚
  my: 'my',
  // 日本
  jp: 'jp',
  // 俄罗斯
  ru: 'ru',
  // 英国
  gb: 'gb',
  // 泰国
  th: 'th',
  // 越南
  vn: 'vn',
  // 澳大利亚、新西兰
  au: 'au',
  // 法国
  fr: 'fr',
  // 德国
  de: 'de',
  // 巴西
  pt: 'pt',
  // 加拿大
  ca: 'ca',
  // 印度尼西亚
  id: 'id',
  // 菲律宾
  ph: 'ph',
}

// 区域组件中映射获取 港澳台二字码
export const AreaRateCode = {
  886: {
    code: CountryCode.tw,
    rateCode: 886,
  },
  852: {
    code: CountryCode.hk,
    rateCode: 852,
  },
  853: {
    code: CountryCode.mo,
    rateCode: 853,
  },
}

// 国际化语言ENUM
export const LangEnum = {
  'zh-CN': {
    name: '中文简体',
    code: 'sc',
    key: 'zh-CN',
  },
  'en-US': {
    name: 'English',
    code: 'en',
    key: 'en-US',
  },
  'zh-HK': {
    name: '中文繁体',
    code: 'tc',
    key: 'zh-HK',
  },
  'en-HK': {
    name: 'English',
    code: 'en',
    key: 'en-HK',
  },
  'zh-CN-HK': {
    name: '中文简体',
    code: 'sc',
    key: 'zh-CN',
  },
  'th-TH': {
    name: 'ภาษาไทย',
    code: 'th',
    key: 'th-TH',
  },
  'zh-TW': {
    name: '中文繁体',
    code: 'tc',
    key: 'zh-TW',
  },
  'en-TW': {
    name: 'English',
    code: 'en',
    key: 'en-TW',
  },
  'zh-CN-TW': {
    name: '中文简体',
    code: 'sc',
    key: 'en-TW',
  },
  'vi-VN': {
    name: 'Tiếng Việt',
    code: 'vi',
    key: 'vi-VN',
  },
  'ja-JP': {
    name: '日本語',
    code: 'ja',
    key: 'ja-JP',
  },
}

// 国家列表
export const CountryList = [
  {
    iln8Name: `common.country.${CountryCode.sf}`,
    key: CountryCode.sf,
    name: '顺丰国际 SF International',
    nameEn: 'SF International'
  },
  {
    iln8Name: `common.country.${CountryCode.ex}`,
    key: CountryCode.ex,
    name: '顺丰速运 SF Express',
    nameEn: 'SF Express'
  },
  // {
  //   iln8Name: `common.country.${CountryCode.cn}`,
  //   key: CountryCode.cn,
  //   name: '中国 China',
  //   nameEn: 'China'
  // },
  {
    iln8Name: `common.country.${CountryCode.hm}`,
    key: CountryCode.hk,
    name: '中国香港/中国澳门 Hong Kong China/ Macau China',
    nameEn: 'Hong Kong China/ Macau China',
  },
  // {
  //   iln8Name: `common.country.${CountryCode.tw}`,
  //   key: CountryCode.tw,
  //   name: '中国台湾 Taiwan China',
  //   nameEn: 'Taiwan China'
  // },
  {
    iln8Name: `common.country.${CountryCode.sg}`,
    key: CountryCode.sg,
    name: '新加坡 Singapore',
    nameEn: 'Singapore'
  },
  {
    iln8Name: `common.country.${CountryCode.kr}`,
    key: CountryCode.kr,
    name: '韩国 Korea',
    nameEn: 'Korea'
  },
  {
    iln8Name: `common.country.${CountryCode.my}`,
    key: CountryCode.my,
    name: '马来西亚 Malaysia',
    nameEn: 'Malaysia'
  },
  {
    iln8Name: `common.country.${CountryCode.jp}`,
    key: CountryCode.jp,
    name: '日本 Japan',
    nameEn: 'Japan'
  },
  {
    iln8Name: `common.country.${CountryCode.us}`,
    key: CountryCode.us,
    name: '美国/加拿大 United States/Canada',
    nameEn: 'United States/Canada'
  },
  {
    iln8Name: `common.country.${CountryCode.ru}`,
    key: CountryCode.ru,
    name: '俄罗斯 Russia',
    nameEn: 'Russia'
  },
  {
    iln8Name: `common.country.${CountryCode.gb}`,
    key: CountryCode.gb,
    name: '英国 United Kingdom',
    nameEn: 'United Kingdom'
  },
  {
    iln8Name: `common.country.${CountryCode.th}`,
    key: CountryCode.th,
    name: '泰国 Thailand',
    nameEn: 'Thailand'
  },
  {
    iln8Name: `common.country.${CountryCode.vn}`,
    key: CountryCode.vn,
    name: '越南 Vietnam',
    nameEn: 'Vietnam'
  },
  {
    iln8Name: `common.country.${CountryCode.au}`,
    key: CountryCode.au,
    name: '澳大利亚/新西兰 Australia/ New Zealand',
    nameEn: 'Australia/ New Zealand'
  },
  {
    iln8Name: `common.country.${CountryCode.fr}`,
    key: CountryCode.fr,
    name: '法国 France',
    nameEn: 'France'
  },
  {
    iln8Name: `common.country.${CountryCode.de}`,
    key: CountryCode.de,
    name: '德国 Germany',
    nameEn: 'Germany'
  },
  {
    iln8Name: `common.country.${CountryCode.id}`,
    key: CountryCode.id,
    name: '印度尼西亚 Indonesia',
    nameEn: 'Indonesia'
  },
  {
    iln8Name: `common.country.${CountryCode.ph}`,
    key: CountryCode.ph,
    name: '菲律宾 Philippines',
    nameEn: 'Philippines'
  },
  // {
  //   iln8Name: `common.country.${CountryCode.pt}`,
  //   key: CountryCode.pt,
  //   name: '巴西 Brazil',
  //   nameEn: 'Brazil'
  // },
]

// 语言类型
export const CountryLang = {
  sf: {
    iln8Name: CountryCode.sf,
    key: CountryCode.sf,
    // 具有的语言类型
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  ex: {
    iln8Name: CountryCode.ex,
    key: CountryCode.ex,
    // 具有的语言类型
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  cn: {
    iln8Name: CountryCode.cn,
    key: CountryCode.cn,
    // 具有的语言类型
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  hk: {
    iln8Name: CountryCode.hk,
    key: CountryCode.hk,
    lang: [LangEnum['zh-HK'], LangEnum['zh-CN'], LangEnum['en-US']],
  },
  tw: {
    iln8Name: CountryCode.tw,
    key: CountryCode.tw,
    lang: [LangEnum['zh-TW'], LangEnum['zh-CN'], LangEnum['en-US']],
  },
  sg: {
    iln8Name: CountryCode.sg,
    key: CountryCode.sg,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  kr: {
    iln8Name: CountryCode.kr,
    key: CountryCode.kr,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  my: {
    iln8Name: CountryCode.my,
    key: CountryCode.my,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  jp: {
    iln8Name: CountryCode.jp,
    key: CountryCode.jp,
    lang: [LangEnum['zh-CN'], LangEnum['en-US'], LangEnum['ja-JP']],
  },
  us: {
    iln8Name: CountryCode.us,
    key: CountryCode.us,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  ru: {
    iln8Name: CountryCode.ru,
    key: CountryCode.ru,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  gb: {
    iln8Name: CountryCode.gb,
    key: CountryCode.gb,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  th: {
    iln8Name: CountryCode.th,
    key: CountryCode.th,
    lang: [LangEnum['zh-CN'], LangEnum['en-US'], LangEnum['th-TH']],
  },
  vn: {
    iln8Name: CountryCode.vn,
    key: CountryCode.vn,
    lang: [LangEnum['zh-CN'], LangEnum['en-US'], LangEnum['vi-VN']],
  },
  au: {
    iln8Name: CountryCode.au,
    key: CountryCode.au,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  fr: {
    iln8Name: CountryCode.fr,
    key: CountryCode.fr,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  de: {
    iln8Name: CountryCode.fr,
    key: CountryCode.fr,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  pt: {
    iln8Name: CountryCode.pt,
    key: CountryCode.pt,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  id: {
    iln8Name: CountryCode.id,
    key: CountryCode.id,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
  ph: {
    iln8Name: CountryCode.ph,
    key: CountryCode.ph,
    lang: [LangEnum['zh-CN'], LangEnum['en-US']],
  },
}

// 顺丰速运支持的语言
export const SFExpressSupportLangs = {
  en: 'en',
  sc: 'sc',
  th: 'en', // 泰语--跳英文站点
  tc: 'tc',
  vi: 'en', // 越南语--跳英文站点
  ja: 'en', // 日本语--跳英文站点
}

// owt-对应iuop lang
export const LangIuopBase = {
  'en-US': 'en',
  'zh-CN': 'zh-CN',
  en: 'en',
  sc: 'zh-CN',
  th: 'th-TH',
  'th-TH': 'th-TH',
  tc: 'zh-CN',
  vi: 'vi-VN',
  'vi-VN': 'vi-VN',
  ja: 'ja-JP',
  'ja-JP': 'ja-JP',
  // 暂时不支持
  // 'zh-HK': 'zh-HK',
  // 'ko-KR': 'ko-KR',
}

// 获取跳转IUOP地址中type参数
export const JumpUrlType = {
  logout: 'logout',
}

// 区域常量 国家-省-市-县区
export const LEVELBASE = {
  one: 1,
  two: 2,
  three: 3,
  // 最后层级
  four: 4,
}

// 切换tab 类型
export const TABTYPE = {
  inland: 'inland',
  hmt: 'hmt',
  overseas: 'overseas',
}
// 区域常量 国家-省-市-县区
export const LEVEL_BASE_NEW = {
  Zero: 0, // 国家
  One: 1, // 省
  Two: 2, // 市
  Three: 3, // 区县
  undefined: 0, // 数据纠正
  0: 0, // 数据纠正
  1: 1, // 数据纠正
  2: 2, // 数据纠正
  3: 3, // 数据纠正
}
// 区域常量 国家-省-市-县区映射标准值
export const LEVEL_BASE_MAP = {
  0: 'Zero', // 国家
  1: 'One', // 省
  2: 'Two', // 市
  3: 'Three', // 区县
}

// 【地区级联-新】不同类型下的最深层级
export const MAX_LEVEL = {
  [TABTYPE.inland]: LEVEL_BASE_NEW.Three, // 大陆地区区县
  [TABTYPE.hmt]: LEVEL_BASE_NEW.Two,
  [TABTYPE.overseas]: LEVEL_BASE_NEW.Two,
}

export const SUBTYPEBASE = {
  // 常用市
  popCity: 'popCity',
  // 省区
  province: 'province',
  // 港澳台
  hmt: 'hmt',
  // 海外
  overseas: 'overseas',
  // 热门国家
  hotCountry: 'hotCountry',
  // 请选择
  select: 'select',
}

// 中国内地 code编码
export const inlandCode = 'A000086000'
// 港澳台 && 国际
export const hmtAndoverseas = 'A000000000'

// 一级tab全量列表
export const allFirstLevelTabList = [
  {
    cname: '中国内地',
    il8nName: 'common.countryArea.china',
    type: TABTYPE.inland,
    code: inlandCode,
    defaultSub: SUBTYPEBASE.popCity,
  },
  {
    cname: '港澳台',
    il8nName: 'common.countryArea.hmt',
    type: TABTYPE.hmt,
    code: hmtAndoverseas,
    defaultSub: SUBTYPEBASE.hmt,
  },
  {
    cname: '国际',
    il8nName: 'common.countryArea.gl',
    type: TABTYPE.overseas,
    code: hmtAndoverseas,
    defaultSub: SUBTYPEBASE.hotCountry,
  },
]
// 二级tab全量列表
export const allSecondLevelTabList = {
  [TABTYPE.inland]: [
    {
      cname: '常用市',
      il8nName: 'common.countryArea.pcity',
      type: SUBTYPEBASE.popCity,
      isSub: true,
    },
    {
      cname: '省/直辖市',
      il8nName: 'common.countryArea.pro',
      type: SUBTYPEBASE.province,
      isSub: true,
    },
  ],
  [TABTYPE.hmt]: [
    {
      cname: '港澳台',
      il8nName: 'common.countryArea.hmt',
      type: SUBTYPEBASE.hmt,
      isSub: true,
    },
  ],
  [TABTYPE.overseas]: [
    {
      cname: '热门国家',
      il8nName: 'common.countryArea.pCountry',
      type: SUBTYPEBASE.hotCountry,
      isSub: true,
    },
    {
      cname: '全部',
      il8nName: 'common.countryArea.ovsAll',
      type: SUBTYPEBASE.overseas,
      isSub: true,
    },
  ],
}

// 货币
export const currencyList = [
  {
    il8nName: 'common.currencySel.CNY',
    value: 'CNY',
    cname: '人民币',
  },
  {
    il8nName: 'common.currencySel.USD',
    value: 'USD',
    cname: '美元',
    countryCode: 'US',
  },
  {
    il8nName: 'common.currencySel.GBP',
    value: 'GBP',
    cname: '英镑',
  },
  {
    il8nName: 'common.currencySel.JPY',
    value: 'JPY',
    cname: '日元',
  },
  {
    il8nName: 'common.currencySel.EUR',
    value: 'EUR',
    cname: '欧元',
  },
  {
    il8nName: 'common.currencySel.HKD',
    value: 'HKD',
    cname: '港币',
  },
  {
    il8nName: 'common.currencySel.KRW',
    value: 'KRW',
    cname: '韩元',
  },
  {
    il8nName: 'common.currencySel.SGD',
    value: 'SGD',
    cname: '新加坡币',
    countryCode: 'SG', // 用于发票查询判断当前币种对应国家
  },
  {
    il8nName: 'common.currencySel.MYR',
    value: 'MYR',
    cname: '马币',
    countryCode: 'MY', // 用于发票查询判断当前币种对应国家
  },
  {
    il8nName: 'common.currencySel.THB',
    value: 'THB',
    cname: '泰铢',
  },
  {
    il8nName: 'common.currencySel.VND',
    value: 'VND',
    cname: '越南盾',
  },
  {
    il8nName: 'common.currencySel.AUD',
    value: 'AUD',
    cname: '澳大利亚元',
  },
  {
    il8nName: 'common.currencySel.NTD',
    value: 'NTD',
    cname: '新台币',
  },
  {
    il8nName: 'common.currencySel.CAD',
    value: 'CAD',
    cname: '加拿大元',
  },
  {
    il8nName: 'common.currencySel.MOP',
    value: 'MOP',
    cname: '澳门元',
  },
  {
    il8nName: 'common.currencySel.AED',
    value: 'AED',
    cname: '阿联酋迪拉姆',
  },
  { il8nName: 'common.currencySel.ARS', value: 'ARS', cname: '阿根廷比索' },
  { il8nName: 'common.currencySel.BDT', value: 'BDT', cname: '孟加拉塔卡' },
  { il8nName: 'common.currencySel.BGN', value: 'BGN', cname: '保加利亚列弗' },
  { il8nName: 'common.currencySel.BHD', value: 'BHD', cname: '巴林第纳尔' },
  { il8nName: 'common.currencySel.BND', value: 'BND', cname: '文莱元' },
  { il8nName: 'common.currencySel.BRL', value: 'BRL', cname: '巴西雷亚尔' },
  { il8nName: 'common.currencySel.CHF', value: 'CHF', cname: '瑞士法郎' },
  { il8nName: 'common.currencySel.CLP', value: 'CLP', cname: '智利比索' },
  { il8nName: 'common.currencySel.COP', value: 'COP', cname: '哥伦比亚比索' },
  { il8nName: 'common.currencySel.CRC', value: 'CRC', cname: '哥斯达黎加科朗' },
  { il8nName: 'common.currencySel.CZK', value: 'CZK', cname: '捷克克朗' },
  { il8nName: 'common.currencySel.DKK', value: 'DKK', cname: '丹麦克朗' },
  {
    il8nName: 'common.currencySel.DZD',
    value: 'DZD',
    cname: '阿尔及利亚第纳尔',
  },
  { il8nName: 'common.currencySel.EGP', value: 'EGP', cname: '埃及镑' },
  { il8nName: 'common.currencySel.HRK', value: 'HRK', cname: '克罗地亚库纳' },
  { il8nName: 'common.currencySel.HUF', value: 'HUF', cname: '匈牙利福林' },
  { il8nName: 'common.currencySel.IDR', value: 'IDR', cname: '印尼卢比' },
  { il8nName: 'common.currencySel.ILS', value: 'ILS', cname: '以色列新谢克尔' },
  { il8nName: 'common.currencySel.INR', value: 'INR', cname: '印度卢比' },
  { il8nName: 'common.currencySel.IRR', value: 'IRR', cname: '伊朗里亚尔' },
  { il8nName: 'common.currencySel.KES', value: 'KES', cname: '肯尼亚先令' },
  { il8nName: 'common.currencySel.KHR', value: 'KHR', cname: '柬埔寨瑞尔' },
  { il8nName: 'common.currencySel.KWD', value: 'KWD', cname: '科威特第纳尔' },
  { il8nName: 'common.currencySel.LAK', value: 'LAK', cname: '老挝基普' },
  { il8nName: 'common.currencySel.LKR', value: 'LKR', cname: '斯里兰卡卢比' },
  { il8nName: 'common.currencySel.MMK', value: 'MMK', cname: '缅甸元' },
  { il8nName: 'common.currencySel.MNT', value: 'MNT', cname: '蒙古币' },
  { il8nName: 'common.currencySel.MXN', value: 'MXN', cname: '墨西哥元' },
  { il8nName: 'common.currencySel.NGN', value: 'NGN', cname: '尼日利亚奈拉' },
  { il8nName: 'common.currencySel.NPR', value: 'NPR', cname: '尼泊尔卢比' },
  { il8nName: 'common.currencySel.NZD', value: 'NZD', cname: '新西兰元' },
  { il8nName: 'common.currencySel.OMR', value: 'OMR', cname: '阿曼里亚尔' },
  { il8nName: 'common.currencySel.PEN', value: 'PEN', cname: '秘鲁索尔' },
  { il8nName: 'common.currencySel.PHP', value: 'PHP', cname: '菲律宾比索' },
  { il8nName: 'common.currencySel.PKR', value: 'PKR', cname: '巴基斯坦卢比' },
  { il8nName: 'common.currencySel.PLN', value: 'PLN', cname: '波兰兹罗提' },
  { il8nName: 'common.currencySel.QAR', value: 'QAR', cname: '卡塔尔里亚尔' },
  { il8nName: 'common.currencySel.RON', value: 'RON', cname: '罗马尼亚列伊' },
  { il8nName: 'common.currencySel.RUB', value: 'RUB', cname: '卢布' },
  { il8nName: 'common.currencySel.SAR', value: 'SAR', cname: '沙特里亚尔' },
  { il8nName: 'common.currencySel.SEK', value: 'SEK', cname: '瑞典克朗' },
  { il8nName: 'common.currencySel.ZAR', value: 'ZAR', cname: '南非兰特' },
]

// 单位中文
export const unitListCN = [
  { value: '件', title: '件' },
  { value: '套', title: '套' },
  { value: '條', title: '條' },
  { value: '把', title: '把' },
  { value: '包', title: '包' },
  { value: '本', title: '本' },
  { value: '袋', title: '袋' },
  { value: '对', title: '对' },
  { value: '份', title: '份' },
  { value: '幅', title: '幅' },
  { value: '个', title: '个' },
  { value: '盒', title: '盒' },
  { value: '根', title: '根' },
  { value: '架', title: '架' },
  { value: '卷', title: '卷' },
  { value: '块', title: '块' },
  { value: '辆', title: '辆' },
  { value: '片', title: '片' },
  { value: '瓶', title: '瓶' },
  { value: '双', title: '双' },
  { value: '板', title: '板' },
  { value: '艘', title: '艘' },
  { value: '台', title: '台' },
  { value: '头', title: '头' },
  { value: '箱', title: '箱' },
  { value: '张', title: '张' },
  { value: '支', title: '支' },
  { value: '只', title: '只' },
  { value: '枝', title: '枝' },
  { value: '组', title: '组' },
  { value: '座', title: '座' },
  { value: '条', title: '条' },
  { value: '米', title: '米' },
  { value: '盘', title: '盘' },
  { value: '筒', title: '筒' },
  { value: '千克', title: '千克' },
  { value: '克', title: '克' },
  { value: '盆', title: '盆' },
  { value: '具', title: '具' },
  { value: '斤', title: '斤' },
  { value: '磅', title: '磅' },
  { value: '码', title: '码' },
  { value: '升', title: '升' },
  { value: '毫升', title: '毫升' },
  { value: '罐', title: '罐' },
  { value: '桶', title: '桶' },
  { value: '打', title: '打' },
  { value: '匹', title: '匹' },
  { value: '枚', title: '枚' },
  { value: '粒', title: '粒' },
  { value: '部', title: '部' },
  { value: '顶', title: '顶' },
  { value: '封', title: '封' },
]
// 单位英文
export const unitListEN = [
  { value: 'piece', title: 'piece' },
  { value: 'set', title: 'set' },
  { value: 'strip', title: 'strip' },
  { value: 'bag', title: 'bag' },
  { value: 'pair', title: 'pair' },
  { value: 'box', title: 'box' },
  { value: 'roll', title: 'roll' },
  { value: 'bottle', title: 'bottle' },
]

// 重量单位配置
export const weightList = [
  { value: 1, label: 'kg' },
  { value: 2, label: 'g' },
  { value: 3, label: 't' },
  { value: 4, label: 'lbs' },
]

// 长度单位配置
export const lengthUnits = [
  { value: 1, label: 'cm' },
  { value: 2, label: 'm' },
  { value: 3, label: 'km' },
  { value: 4, label: 'in' },
]

// 证件类型
export const certTypeList = [
  {
    cname: '身份证',
    il8nName: 'common.certType.001',
    value: '001',
  },
  {
    cname: '护照',
    il8nName: 'common.certType.002',
    value: '002',
  },
  {
    cname: '军官证',
    il8nName: 'common.certType.003',
    value: '003',
  },
  {
    cname: '警察证',
    il8nName: 'common.certType.004',
    value: '004',
  },
  {
    cname: '港澳通行证',
    il8nName: 'common.certType.005',
    value: '005',
  },
  {
    cname: '台湾通行证',
    il8nName: 'common.certType.006',
    value: '006',
  },
  {
    cname: '统一社会信用代码',
    il8nName: 'common.certType.007',
    value: '007',
  },
  {
    cname: '海关注册编码',
    il8nName: 'common.certType.008',
    value: '008',
  },
  {
    cname: '统编号',
    il8nName: 'common.certType.009',
    value: '009',
  },
  {
    cname: '海关进出口号',
    il8nName: 'common.certType.010',
    value: '010',
  },
  {
    cname: '法人番号',
    il8nName: 'common.certType.011',
    value: '011',
  },
  {
    cname: '进口商号码',
    il8nName: 'common.certType.012',
    value: '012',
  },
  {
    cname: '纳税账号',
    il8nName: 'common.certType.013',
    value: '013',
  },
  {
    cname: '社会安全号码',
    il8nName: 'common.certType.014',
    value: '014',
  },
  {
    cname: '经营者注册和识别号码',
    il8nName: 'common.certType.015',
    value: '015',
  },
  {
    cname: '个人清关代码',
    il8nName: 'common.certType.016',
    value: '016',
  },
  {
    cname: '企业清关代码',
    il8nName: 'common.certType.017',
    value: '017',
  },
  {
    cname: '公司营业执照号',
    il8nName: 'common.certType.018',
    value: '18',
  },
  {
    cname: '印度公民身份识别卡',
    il8nName: 'common.certType.019',
    value: '019',
  },
  {
    cname: '永久账户纳税号',
    il8nName: 'common.certType.020',
    value: '020',
  },
  {
    cname: '进出口注册号码',
    il8nName: 'common.certType.021',
    value: '021',
  },
  {
    cname: '货物服务税号',
    il8nName: 'common.certType.022',
    value: '022',
  },
  {
    cname: '居留证',
    il8nName: 'common.certType.023',
    value: '023',
  },
  {
    cname: '经济经营者登记识别号',
    il8nName: 'common.certType.024',
    value: '024',
  },
  {
    cname: '增值税号',
    il8nName: 'common.certType.025',
    value: '025',
  },
  {
    cname: '选民证',
    il8nName: 'common.certType.026',
    value: '026',
  },
  {
    cname: '驾驶证',
    il8nName: 'common.certType.027',
    value: '027',
  },
  {
    cname: 'IOSS增值税识别号',
    il8nName: 'common.certType.028',
    value: '028',
  },
  {
    cname: '印尼身份证',
    il8nName: 'common.certType.029',
    value: '029',
  },
  {
    cname: '印尼护照',
    il8nName: 'common.certType.030',
    value: '030',
  },
  {
    cname: '印尼驾照',
    il8nName: 'common.certType.031',
    value: '031',
  },
  {
    cname: '印尼有限居留许可证',
    il8nName: 'common.certType.032',
    value: '032',
  },
  {
    cname: '印尼永久居留许可证',
    il8nName: 'common.certType.033',
    value: '033',
  },
  {
    cname: '印尼税号',
    il8nName: 'common.certType.034',
    value: '034',
  },
  {
    cname: '其他',
    il8nName: 'common.certType.100',
    value: '100',
  },
]

// 默认cookie信息
export const DefaultCookieAgree = {
  agree: false,
  optimisation: false,
  analytics: false,
}
