// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/error_404bg-m.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/error_404bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width:750px){.container-error-xx{display:flex;align-items:center;justify-content:center;background-color:#fdfdfd;flex-direction:column;height:100vh}.container-error-xx .wrapper{width:100%}.container-error-xx .img-box{width:100%;height:3.88rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%;background-size:100%}.container-error-xx .tips_text{font-style:normal;font-weight:700;font-size:0.24rem;line-height:0.29rem;color:#333;margin-top:0.77rem}.container-error-xx .link_back{text-align:center;font-style:normal;font-weight:400;font-size:0.14rem;line-height:0.17rem;color:#333;display:table;background-repeat:no-repeat;margin:0.3rem auto 0}.container-error-xx .link_back .click_back{color:#ee0303;cursor:pointer}}@media screen and (min-width:751px){.container-error-xx{display:flex;align-items:center;justify-content:center;background-color:#fdfdfd;flex-direction:column;height:100vh}.container-error-xx .img-box{width:8.94rem;height:4.62rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 100% 100%}.container-error-xx .tips_text{font-style:normal;font-weight:700;font-size:0.24rem;line-height:0.29rem;margin-top:-0.35rem;color:#333}.container-error-xx .link_back{text-align:center;font-style:normal;font-weight:400;font-size:0.14rem;line-height:0.17rem;color:#333;display:table;background-repeat:no-repeat;margin:0.1rem auto 0}.container-error-xx .link_back .click_back{color:#ee0303;cursor:pointer}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
