import { singleTrack, keywordTrack } from '../utils'
import { EVENT_TYPE } from '../config/const'

/**
 * 综合落地页-顶部免费报价按钮点击事件
 * @param {*} property
 */
export const QuoteMainlandingpagetopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Mainlandingpagetop_Click, property)
}

/**
 * 综合落地页-底部免费报价按钮点击事件
 * @param {*} property
 */
export const QuoteMainlandingpagedownClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Mainlandingpagedown_Click, property)
}

/**
 * 综合落地页-顶部免费报价提交成功数
 * @param {*} property
 */
export const QuoteMainlandingpagetopSuccsubmit = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Mainlandingpagetop_Succsubmit, property)
}

/**
 * 综合落地页-底部免费报价提交成功数
 * @param {*} property
 */
export const QuoteMainlandingpagedownSuccsubmit = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Mainlandingpagedown_Succsubmit, property)
}

/**
 * 综合落地页-顶部（导航栏）马上寄件按钮点击事件
 * @param {*} property
 */
export const ShippingMainlandingpagetopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Shipping_Mainlandingpagetop_Click, property)
}

/**
 * 综合落地页-底部马上寄件按钮点击事件
 * @param {*} property
 */
export const ShippingMainlandingpagedownClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Shipping_Mainlandingpagedown_Click, property)
}

/**
 * 综合落地页-顶部（导航栏）运费时效查询按钮点击事件
 * @param {*} property
 */
export const TimeCostMainlandingpagetopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.TimeCost_Mainlandingpagetop_Click, property)
}

/**
 * 综合落地页-底部运费时效查询按钮点击事件
 * @param {*} property
 */
export const TimeCostMainlandingpagedownClick = (property = {}) => {
  singleTrack(EVENT_TYPE.TimeCost_Mainlandingpagedown_Click, property)
}

/**
 * 快递落地页-顶部免费报价按钮点击事件
 * @param {*} property
 */
export const QuoteExpresslandingpagetopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Expresslandingpagetop_Click, property)
}

/**
 * 快递落地页-底部免费报价按钮点击事件
 * @param {*} property
 */
export const QuoteExpresslandingpagedownClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Expresslandingpagedown_Click, property)
}

/**
 * 快递落地页-顶部免费报价提交成功数
 * @param {*} property
 */
export const QuoteExpresslandingpagetopSuccsubmit = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Expresslandingpagetop_Succsubmit, property)
}

/**
 * 快递落地页-底部免费报价提交成功数
 * @param {*} property
 */
export const QuoteExpresslandingpagedownSuccsubmit = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Expresslandingpagedown_Succsubmit, property)
}

/**
 * 快递落地页-顶部（导航栏）马上寄件按钮点击事件
 * @param {*} property
 */
export const ShippingExpresslandingpagetopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Shipping_Expresslandingpagetop_Click, property)
}

/**
 * 快递落地页-底部马上寄件按钮点击事件
 * @param {*} property
 */
export const ShippingExpresslandingpagedownClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Shipping_Expresslandingpagedown_Click, property)
}

/**
 * 快递落地页-顶部（导航栏）运费时效查询按钮点击事件
 * @param {*} property
 */
export const TimeCostExpresslandingpagetopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.TimeCost_Expresslandingpagetop_Click, property)
}

/**
 * 快递落地页-底部运费时效查询按钮点击事件
 * @param {*} property
 */
export const TimeCostExpresslandingpagedownClick = (property = {}) => {
  singleTrack(EVENT_TYPE.TimeCost_Expresslandingpagedown_Click, property)
}

/**
 * 电商落地页-顶部免费报价按钮点击事件
 * @param {*} property
 */
export const QuoteEcommercelandingpagetopClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Ecommercelandingpagetop_Click, property)
}

/**
 * 电商落地页-底部免费报价按钮点击事件
 * @param {*} property
 */
export const QuoteEcommercelandingpagedownClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Ecommercelandingpagedown_Click, property)
}

/**
 * 电商落地页-顶部免费报价提交成功数
 * @param {*} property
 */
export const QuoteEcommercelandingpagetopSuccsubmit = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Ecommercelandingpagetop_Succsubmit, property)
}

/**
 * 电商落地页-底部免费报价提交成功数
 * @param {*} property
 */
export const QuoteEcommercelandingpagedownSuccsubmit = (property = {}) => {
  singleTrack(EVENT_TYPE.Quote_Ecommercelandingpagedown_Succsubmit, property)
}

/**
 * 综合/查运费时效-查询按钮点击事件
 * @param {*} property
 */
export const QueryMaintimeCostpageClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_MaintimeCostpage_Click, property)
}

/**
 * 快递/查运费时效-查询按钮点击事件
 * @param {*} property
 */
export const QueryExpresstimeCostpageClick = (property = {}) => {
  singleTrack(EVENT_TYPE.Query_ExpresstimeCostpage_Click, property)
}

/**
 * 关键字上报
 */
export const LandingKeywordTrack = (keyword) => {
  keywordTrack(EVENT_TYPE.Landing_Keyword_Track, keyword)
}
