/*
 * @Author: liyang
 * @Date: 2021-11-03 19:15:31
 * @LastEditTime: 2023-03-29 17:27:11
 * @LastEditors: yuanxing(01407381)
 * @FilePath: \ibu-owt-core-frontend\plugins\injectAxios.js
 * @Description:
 */

import createAxios from '@/services/api'

export default (ctx, inject) => {
  const apiWithAxios = createAxios(ctx.$axios, ctx.store)
  inject('API', apiWithAxios)
}
