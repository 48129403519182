import allUrls from '@/utils/config'
import gjUtmConfig from '@/utils/gjUtm'

const accountList = 
  {
    path: '',
    cname: '账号',
    name: 'account',
    il8nName: 'navs.account',
    children: [
      {
        cname: '登录',
        name: 'login',
        il8nName: 'common.login.login',
        gjUtm: gjUtmConfig.sfIntlOsLogin.jp,
        path: allUrls.iuopGloballoginUrl,
      },
      {
        cname: '注册',
        name: 'register',
        il8nName: 'common.login.register',
        gjUtm: gjUtmConfig.sfIntlOsLogin.jp,
        path: allUrls.iuopGlobalRegisterUrl,
      },
      {
        cname: '商务合作',
        name: 'coop',
        il8nName: 'modules.about.businessCooperation',
        gjUtm: gjUtmConfig.sfIntlOsLogin.jp,
        path: allUrls.iuopGlobalCustomerUrl,
      },
    ],
  }

export default accountList