/*
 * @Descripttion: 查运单模块-常量配置
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2023-03-17 11:25:19
 * @LastEditors: yuanxing(01407381)
 * @LastEditTime: 2023-07-10 14:13:42
 */
// 运单状态对照表
export const waybillMap = [
  {
    cname: '已签收',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_signed',
    type: 'signed', // 三种状态，派送中，已签收，已作废
    valueKey: 'signed',
  },
  {
    cname: '已作废',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_invalid',
    valueKey: 'invalid',
    type: 'invalid',
  },
  {
    cname: '派送中',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_delivery',
    valueKey: 'delivery',
    type: 'other',
  },
  {
    cname: '已揽收',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_collect',
    valueKey: 'collect',
    type: 'other',
  },
  {
    cname: '已取件',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_pickedup',
    valueKey: 'pickedup',
    type: 'other',
  },
  {
    cname: '运送中',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_other',
    valueKey: 'other',
    value: ['other'], // 其余opCode都展示为该状态
    type: 'other',
  },
  {
    cname: '航班起飞',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_aircraft_take_off',
    valueKey: 'aircraft_take_off',
    type: 'other',
  },
  {
    cname: '航班落地',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_aircraft_landing',
    valueKey: 'aircraft_landing',
    type: 'other',
  },
  {
    cname: '已审单',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_received',
    valueKey: 'received',
    type: 'other',
  },
  {
    cname: '拣货中',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_picking',
    valueKey: 'picking',
    type: 'other',
  },
  {
    cname: '已拣货',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_picked',
    valueKey: 'picked',
    type: 'other',
  },
  {
    cname: '已包装',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_packaged',
    valueKey: 'packaged',
    type: 'other',
  },
  {
    cname: '已出库',
    il8nName: 'serviceSupport.querySupport.waybills.opCode_delivered',
    valueKey: 'delivered',
    type: 'other',
  },
]

// 时区
export const timeZone = [
  { label: 'UTC-12:00', value: 'UTC-12' },
  { label: 'UTC-11:00', value: 'UTC-11' },
  { label: 'UTC-10:00', value: 'UTC-10' },
  { label: 'UTC-09:00', value: 'UTC-9' },
  { label: 'UTC-08:00', value: 'UTC-8' },
  { label: 'UTC-07:00', value: 'UTC-7' },
  { label: 'UTC-06:00', value: 'UTC-6' },
  { label: 'UTC-05:00', value: 'UTC-5' },
  { label: 'UTC-04:00', value: 'UTC-4' },
  { label: 'UTC-03:00', value: 'UTC-3' },
  { label: 'UTC-02:00', value: 'UTC-2' },
  { label: 'UTC-01:00', value: 'UTC-1' },
  { label: 'UTC+00:00', value: 'UTC+0' },
  { label: 'UTC+01:00', value: 'UTC+1' },
  { label: 'UTC+02:00', value: 'UTC+2' },
  { label: 'UTC+03:00', value: 'UTC+3' },
  { label: 'UTC+04:00', value: 'UTC+4' },
  { label: 'UTC+05:00', value: 'UTC+5' },
  { label: 'UTC+06:00', value: 'UTC+6' },
  { label: 'UTC+07:00', value: 'UTC+7' },
  { label: 'UTC+08:00', value: 'UTC+8' },
  { label: 'UTC+09:00', value: 'UTC+9' },
  { label: 'UTC+10:00', value: 'UTC+10' },
  { label: 'UTC+11:00', value: 'UTC+11' },
  { label: 'UTC+12:00', value: 'UTC+12' },
  { label: 'UTC+13:00', value: 'UTC+13' },
  { label: 'UTC+14:00', value: 'UTC+14' },
]

// 苹果件
export const appleHotLineMap = {
  sg: {
    title: 'serviceSupport.querySupport.waybills.appleSGTitle',
    mobile: '1800-3111-111',
    countryName: 'serviceSupport.querySupport.waybills.appleSG',
  },
  th: {
    title: 'serviceSupport.querySupport.waybills.appleTHTitle',
    mobile: '02 097 1889',
    countryName: 'serviceSupport.querySupport.waybills.appleTH',
  },
  vn: {
    title: 'serviceSupport.querySupport.waybills.appleVNTitle',
    mobile: '19006092',
    countryName: 'serviceSupport.querySupport.waybills.appleVN',
  },
  cn: {
    title: 'serviceSupport.querySupport.waybills.appleCNTitle',
    mobile: '4001288853',
    countryName: 'serviceSupport.querySupport.waybills.appleCN',
  },
  my: {
    title: 'serviceSupport.querySupport.waybills.appleMYTitle',
    mobile: '1800 18 3331',
    countryName: 'serviceSupport.querySupport.waybills.appleMY',
  },
  hk: {
    title: 'serviceSupport.querySupport.waybills.appleHKTitle',
    mobile: '852-27300273',
    countryName: 'serviceSupport.querySupport.waybills.appleHK',
  },
  852: {
    title: 'serviceSupport.querySupport.waybills.appleHKTitle',
    mobile: '852-27300273',
    countryName: 'serviceSupport.querySupport.waybills.appleHK',
  },
  tw: {
    title: 'serviceSupport.querySupport.waybills.appleTWTitle',
    mobile: '412-8830',
    countryName: 'serviceSupport.querySupport.waybills.appleTW',
  },
  886: {
    title: 'serviceSupport.querySupport.waybills.appleTWTitle',
    mobile: '412-8830',
    countryName: 'serviceSupport.querySupport.waybills.appleTW',
  },
  default: {
    title: 'serviceSupport.querySupport.waybills.appleCNTitle',
    mobile: '4001288853',
    countryName: 'serviceSupport.querySupport.waybills.appleCN',
  },
}

// 三星件指令类型
export const samsungTypeConfig = {
  serviceCode5: {
    type: 1,
    cname: '预约自取',
  },
  serviceCode7: {
    type: 2,
    cname: '预约派送日期',
  },
  serviceCode57: {
    type: 3,
    cname: '预约派送日期/自取',
  },
  serviceCode75: {
    type: 3,
    cname: '预约派送日期/自取',
  },
}

// 三星件指令说明
export const samsungI18nConfig = {
  type1: {
    i18n: 'serviceSupport.querySupport.waybills.appointmentSelf',
    cname: '预约自取',
    serviceCode: '5',
  },
  type2: {
    i18n: 'serviceSupport.querySupport.waybills.appointmentDate',
    cname: '预约派送日期',
    serviceCode: '7',
  },
  type3: {
    i18n: 'serviceSupport.querySupport.waybills.appointmentBoth',
    cname: '预约派送日期/自取',
    serviceCode: '5,7',
  },
}

// 判断有无三星件-预约件
export const hasSamsungAppointmentBtn = (route) => {
  // 指令5、7任选其一
  return hasSuchDirective(route, [5, 7])
}

// 判断有无三星件-修改收件人
export const hasSamsungReceiverBtn = (route) => {
  // 指令11
  return hasSuchDirective(route, [11])
}

// 顺丰速运官网
export const sfExpressUrl = {
  sc: 'https://www.sf-express.com/chn/sc/waybill/list',
  en: 'https://www.sf-express.com/chn/en/waybill/list',
  default: 'https://www.sf-express.com/chn/en/waybill/list',
}

// 判断是否包含该指令或者指令集
function hasSuchDirective(route, directivesArr) {
  const samsungArr =
    route?.directives
      ?.filter((i) => i.isSupport === 1)
      ?.map((m) => m.serviceCode) || []
  for (let i = 0; i < directivesArr.length; i++) {
    if (samsungArr.includes(directivesArr[i])) {
      return true
    }
  }
  return false
}
