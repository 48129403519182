/*
 * @Author: liyang
 * @Date: 2021-04-07 15:57:42
 * @LastEditTime: 2023-08-03 11:34:25
 * @LastEditors: yuanxing(01407381)
 * @FilePath: \ibu-owt-core-frontend\middleware\router.js
 * @Description: 路由切换
 */
import { CountryLang } from '@/utils/constBase'
import { reachableCountry } from '@/utils/const'
// import { getcookiesInServer } from '@/utils/utils'

export default function (context) {
  const { isHMR, route, redirect, $winstonLog } = context
  let { site, lang } = route.params
  if (isHMR) {
    return false
  }
  console.log('middleware is:', route.path)
  $winstonLog?.info(`middleware is: ${route.path}`)
  const reg = /((\/[a-z]{2}){2}\/)(.+)/
  const siteLang = route.path.replace(reg, '$1')

  // index.html 重定向
  if (/^.*index\.html$/.test(route.path)) {
    const link = route.path.replace(/^(.*)\/index\.html$/, '$1')
    return redirect({ statusCode: 200, path: link })
  }

  // 当访问不存在的路由时，site，lang为undefined
  if (!site && !lang) {
    const regex = /[a-z]{2}/g
    const arr = siteLang.match(regex)
    if (arr?.length === 2) {
      site = arr[0]
      lang = arr[1]
    }
    $winstonLog?.error(`site and lang is undefined`)
    return redirect({ statusCode: 404, path: `/${site}/${lang}/error` })
  }
  console.log(siteLang, site, lang, 'siteLang')
  $winstonLog?.info(`siteLang is: ${siteLang},site is: ${site},lang is:${lang}`)
  // 当前站点语言列表
  const curSiteLang = CountryLang[site]?.lang
  // 当不存在站点、语言时跳转自定义错误页面
  if (
    route.path !== '/404.html' &&
    (!reachableCountry[site] ||
      !curSiteLang?.some((item) => item.code === lang))
  ) {
    console.log('not support site: ', site, lang)
    $winstonLog?.error(`not support site: ${site},not support lang: ${lang}`)
    if (site && lang) {
      return redirect({ statusCode: 404, path: `/${site}/${lang}/error` })
    }
    return redirect({ statusCode: 404, path: `/cn/sc/error` })
  }
}
