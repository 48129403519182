import allUrls from '@/utils/config'
import gjUtmConfig from '@/utils/gjUtm'
const config = {
  cn: {
    logo: {
      sc: '~/assets/img/logo-chinese-express.png',
      en: '~/assets/img/logo-black-express.png',
    },
    loginLink: {
      type: 'link',
      gjUtm: gjUtmConfig.sfIntlOsLogin.cn,
      href: allUrls.iuoploginUrl,
      name: 'common.login.login',
    },
    registerLink: {
      type: 'link',
      gjUtm: gjUtmConfig.sfIntlOsLogin.cn,
      href: allUrls.iuopRegisterUrl,
      name: 'common.login.register',
    },
  },
  sg: {
    logo: {
      sc: '~/assets/img/logo-intl-express.png',
      en: '~/assets/img/logo-intl-express.png',
    },
    loginLink: {
      href: allUrls.iuopGloballoginUrl,
      gjUtm: gjUtmConfig.sfIntlOsLogin.sg,
      name: 'common.login.login',
    },
    registerLink: {
      href: allUrls.iuopGlobalRegisterUrl,
      gjUtm: gjUtmConfig.sfIntlOsLogin.sg,
      name: 'common.login.register',
    },
  },
  my: {
    logo: {
      sc: '~/assets/img/logo-intl-express.png',
      en: '~/assets/img/logo-intl-express.png',
    },
    loginLink: {
      href: allUrls.iuopGloballoginUrl,
      name: 'common.login.login',
      gjUtm: gjUtmConfig.sfIntlOsLogin.my,
    },
    registerLink: {
      href: allUrls.iuopGlobalRegisterUrl,
      name: 'common.login.register',
      gjUtm: gjUtmConfig.sfIntlOsLogin.my,
    },
  },
  th: {
    logo: {
      sc: '~/assets/img/logo-intl-express.png',
      en: '~/assets/img/logo-intl-express.png',
      th: '~/assets/img/logo-intl-express.png',
    },
    loginLink: {
      href: allUrls.iuopGloballoginUrl,
      name: 'common.login.login',
      gjUtm: gjUtmConfig.sfIntlOsLogin.th,
    },
    registerLink: {
      href: allUrls.iuopGlobalRegisterUrl,
      name: 'common.login.register',
      gjUtm: gjUtmConfig.sfIntlOsLogin.th,
    },
  },
  id: {
    logo: {
      sc: '~/assets/img/logo-intl-express.png',
      en: '~/assets/img/logo-intl-express.png',
    },
    loginLink: {
      href: allUrls.iuopGloballoginUrl,
      gjUtm: gjUtmConfig.sfIntlOsLogin.id,
      name: 'common.login.login',
    },
    registerLink: {
      href: allUrls.iuopGlobalRegisterUrl,
      gjUtm: gjUtmConfig.sfIntlOsLogin.id,
      name: 'common.login.register',
    },
  },
  ph: {
    logo: {
      sc: '~/assets/img/logo-intl-express.png',
      en: '~/assets/img/logo-intl-express.png',
    },
    loginLink: {
      href: allUrls.iuopGloballoginUrl,
      gjUtm: gjUtmConfig.sfIntlOsLogin.id,
      name: 'common.login.login',
    },
    registerLink: {
      href: allUrls.iuopGlobalRegisterUrl,
      gjUtm: gjUtmConfig.sfIntlOsLogin.id,
      name: 'common.login.register',
    },
  },
  vn: {
    logo: {
      sc: '~/assets/img/logo-intl-express.png',
      en: '~/assets/img/logo-intl-express.png',
      vi: '~/assets/img/logo-intl-express.png',
    },
    loginLink: {
      href: allUrls.iuopGloballoginUrl,
      name: 'common.login.login',
      gjUtm: gjUtmConfig.sfIntlOsLogin.vn,
    },
    registerLink: {
      href: allUrls.iuopGlobalRegisterUrl,
      name: 'common.login.register',
      gjUtm: gjUtmConfig.sfIntlOsLogin.vn,
    },
  },
  jp: {
    logo: {
      sc: '~/assets/img/logo-intl-white.png',
      en: '~/assets/img/logo-intl-white.png',
      ja: '~/assets/img/logo-intl-white.png',
    },
    logoMobile: {
      sc: '~/assets/img/logo-intl-express.png',
      en: '~/assets/img/logo-intl-express.png',
      ja: '~/assets/img/logo-intl-express.png',
    },
    loginLink: {
      href: allUrls.iuopGloballoginUrl,
      name: 'common.login.login',
      gjUtm: gjUtmConfig.sfIntlOsLogin.jp,
    },
    registerLink: {
      href: allUrls.iuopGlobalRegisterUrl,
      name: 'common.login.register',
      gjUtm: gjUtmConfig.sfIntlOsLogin.jp,
    },
  },
  au: {
    logo: {
      sc: '~/assets/img/logo-intl-express.png',
      en: '~/assets/img/logo-intl-express.png',
    },
    loginLink: {
      href: allUrls.iuopGloballoginUrl,
      name: 'common.login.login',
      gjUtm: gjUtmConfig.sfIntlOsLogin.au,
    },
    registerLink: {
      href: allUrls.iuopGlobalRegisterUrl,
      name: 'common.login.register',
      gjUtm: gjUtmConfig.sfIntlOsLogin.au,
    },
  },
}

export default config
