
import '@/utils/imgLazyload'
import { requireCdnUrl } from '@/utils/utils'
export default {
  name: 'Imglazy',
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: 'img',
    },
    isLazy: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    // 非懒加载 进行替换处理
    normalWebpUrl() {
      if (this.src) {
        const url = requireCdnUrl(this.src, true)
        return url
      }
      return ''
    },
    // 非懒加载 进行替换处理
    normalUrl() {
      if (this.src) {
        const url = requireCdnUrl(this.src)
        return url
      }
      return ''
    },
  },
  created() {},
  mounted() {},
}
