import allUrls from '@/utils/config'

// 关于我们
const aboutUsList = {
  path: '',
  cname: '关于我们',
  name: 'about',
  il8nName: 'navs.aboutUs',
  children: [
    {
      path: '',
      cname: '资讯中心',
      name: 'news_info',
      il8nName: 'modules.about.news_info',
      children: [
        {
          path: '/about/board',
          cname: '服务公告',
          name: 'board',
          il8nName: 'modules.about.news_infos.notice',
        },
        {
          path: '/about/news',
          cname: '新闻动态',
          name: 'news',
          il8nName: 'modules.about.news_infos.title',
        },
        {
          path: '/about/promotion',
          cname: '推广专区',
          name: 'promotion',
          il8nName: 'modules.about.news_infos.promotion',
        },
      ],
    },
    {
      path: '',
      cname: '关于我们',
      name: 'intro',
      il8nName: 'modules.intro.title',
      children: [
        {
          path: '/about/intro/detail/about',
          cname: '关于顺丰',
          name: 'intro-detail-about',
          il8nName: 'modules.intro.menu.about',
        },
        {
          path: '/about/intro/detail/course',
          cname: '发展历程',
          name: 'intro-detail-course',
          hidden: { mb: false },
          il8nName: 'modules.intro.menu.course',
        },
        {
          path: '/about/intro/detail/desire',
          cname: '公司愿景',
          name: 'intro-detail-desire',
          hidden: { mb: false },
          il8nName: 'modules.intro.menu.desire',
        },
        {
          path: '/about/intro/detail/culture',
          cname: '公司文化',
          name: 'intro-detail-culture',
          hidden: { mb: false },
          il8nName: 'modules.intro.menu.culture',
        },
        {
          path: '/about/intro/detail/honor',
          cname: '公司荣誉',
          name: 'intro-detail-honor',
          hidden: { mb: false },
          il8nName: 'modules.intro.menu.honor',
        },
      ],
    },
    {
      path: '/about/contact',
      cname: '联系我们',
      name: 'contact_us',
      il8nName: 'modules.about.relations',
    },
    {
      path: allUrls.iuopGlobalCustomerUrl,
      cname: '商务合作',
      name: 'coop',
      il8nName: 'modules.about.businessCooperation',
    },
  ],
}

export default aboutUsList
