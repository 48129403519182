
import { mapState, mapMutations } from 'vuex'
import { RoutesList, FooterRouter } from '@/router/jp/index.js'
import footer from '@/modules/config/footer'
import CookieSettingDialog from '@/components/common/cookieSetting.vue'

export default {
  components: {
    CookieSettingDialog
  },
  data() {
    return {
      currentName: '',
    }
  },
  computed: {
    ...mapState({
      productMenus: (state) => state.productMenus || [],
      // site: (state) => state.site,
    }),
    site() {
      return this.$route.params.site
    },
    lang() {
      return this.$route.params.lang
    },
    year() {
      return new Date().getFullYear()
    },
    configList() {
      return [...this.handleProductMenu(this.productMenus), ...this.handleRouterMenu()]
    },
    shareSubList() {
      return footer[this.site].service.otherSite(this.lang)
    },
    footerRouter() {
      return JSON.parse(JSON.stringify(FooterRouter))
    }
  },
  methods: {
    ...mapMutations({
      setCookieTerms: 'SET_COOKIE_TERMS',
    }),
    hiddenShareSubFn(it) {
      if (it.hidden) {
        if (it.hidden.lang) {
          return !it.hidden.lang.includes(this.lang)
        }
      }
      return true
    },
    handleProductMenu(menuList = []) {
      const tempList = []
      menuList.forEach((menu) => {
        const temp = { ...menu }
        if (menu.children?.length) {
          menu.children.forEach((subMenu) => {
            if (subMenu.children?.length) {
              temp.children = temp.children.concat(subMenu.children)
            }
          })
        }
        temp.children = temp.children.filter((it) => it.path)
        tempList.push(temp)
      })
      return tempList
    },
    handleRouterMenu() {
      const contactUs  = RoutesList.find((it) => it.name === 'contactUs')
      const tempList = this.footerRouter.reduce((total, it) => {
        if (it.name === 'support') {
          const hasContact = it.children?.find((child) => child.name === 'contactUs')
          if (!hasContact) {
            it?.children?.push(contactUs)
          }
        }
        total.push(it)
        return total
      }, [])
      return tempList
    },
    toggle(name) {
      if (this.currentName !== name) {
        this.currentName = name
      } else {
        this.currentName = ''
      }
      this.collapse(this.currentName)
    },
    collapse(name) {
      const collapses = this.$refs.content
      collapses.forEach((ele) => {
        const collapse = ele.querySelector('.collapse-content')
        if (ele.dataset.id === name) {
          ele.style.height = `${collapse.offsetHeight}px`
        } else {
          ele.style.height = '0px'
        }
      })
    },
    openCookieDialog() {
      this.setCookieTerms({
        title: this.$t('cookies.cookie'),
        show: true,
        type: 1,
      })
    }
  }
}
