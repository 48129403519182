/*
 * @Descripttion: 全局路由配置
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2022-01-19 09:51:34
 */
import Support from './support'
import Product from './product'
import AboutUs from './aboutUs'
// 网站导航路由---依据页面访问路径
// 页面目录可依据此来进行
// 页面路由状态可依赖此进行逻辑判断
// 路由层级包括(site、lang、module、detail)
export const RoutesList = [
  // 首页
  {
    path: '/',
    name: 'home',
    cname: '首页',
    il8nName: 'navs.home',
  },
  // 马上寄件
  {
    path: '/consign',
    cname: '马上寄件',
    name: 'consign',
    il8nName: 'navs.shipNow',
  },
  // 立即查单
  {
    path: '/support/querySupport/waybill',
    cname: '立即查单',
    name: 'checkList',
    il8nName: 'navs.checkList',
  },
  // 物流服务
  Product,
  // 服务支持
  Support,
  // 关于我们
  AboutUs
]
