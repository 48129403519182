
import Cookies from 'js-cookie'
import { mapMutations, mapActions } from 'vuex'
// import { scrollTo } from '@/utils/scroll-to'
import {
  onlySaveNecessityCookie,
  removeOptionCookie,
} from '@/utils/cookieOperation'
export default {
  name: 'CookiePanel',
  data() {
    return {
      isAgree: this.$store.state.cookie_agree.agree,
    }
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        // 获取到的cookie是字符串
        let cookieType = {
          agree: false,
          optimisation: false,
          analytics: false,
        }
        if (Cookies.get('option.preference')) {
          cookieType = JSON.parse(Cookies.get('option.preference'))
        }
        this.isAgree = cookieType.agree
        this.setCookieBtnDisagree(false)
      }
    },
    '$store.state.cookie_agree.agree'(n, o) {
      if (n) {
        this.isAgree = true
      }
    },
  },
  mounted() {
    if (!this.isAgree) {
      //   scrollTo(0, 500)
    }
  },
  methods: {
    ...mapMutations({
      setCookieTerms: 'SET_COOKIE_TERMS',
      setCookieAgree: 'set_COOKIE_AGREE',
      setCookieBtnDisagree: 'SER_COOKIE_BTN_DISAGREE',
    }),
    ...mapActions({
      setAgreeCookie: 'setAgreeCookie',
    }),
    showTerms() {
      this.setCookieTerms({
        title: this.$t('cookies.cookie'),
        show: true,
        type: 1,
      })
    },
    agree(val) {
      this.isAgree = true
      const cookieObj = {
        agree: false,
        optimisation: false,
        analytics: false,
      }
      if (val) {
        cookieObj.agree = true
        cookieObj.optimisation = true
        cookieObj.analytics = true
        // 重新设置cookie
        this.setAgreeCookie(JSON.stringify(cookieObj))
        location.reload()
      } else {
        // 点击拒绝按钮删除非必要cookie
        onlySaveNecessityCookie()
        removeOptionCookie()
        this.setCookieBtnDisagree(true)
        // this.setAgreeCookie(JSON.stringify(cookieObj))
        // console.log(this.$store.getters.cookie_agree.agree)
      }
    },
  },
}
