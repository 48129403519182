import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import { requireCdnUrl } from '@/utils/utils'
Vue.use(VueLazyload, {
  lazyComponent: true,
  silent: false,
  filter: {
    // webp图片-外置CDN方案
    // webp (listener, options) {
    //     // 对浏览器端-懒加载图片进行处理
    //     let src = listener.src;
    //     // base64 资源不处理
    //     if( src.match('data:image')) return
    //     // 如果支持webp则 替换我们图片源
    //     // 替换规则
    //     // 1. 适配CDN地址的
    //     // 2. 没有域名前缀的
    //     // 3. cms端的不替换
    //     if(src.match(/http(s)?:\/\//)){
    //         // 主要是背景图 直接用的链接地址
    //         if(src.match(CONFIG.cdnUrl)){
    //             src = src.replace(CONFIG.cdnUrl, '')
    //         }else{
    //             // 其他网站源图片，先不做处理
    //             return
    //         }
    //     }
    //     // 相对路径进行处理
    //     src = src.replace(/^\/?(.*)/g, '$1')

    //     if(src.match(/jpg|png|jpeg/)){
    //         let baseUrl = CONFIG.cdnUrl
    //         if(options.supportWebp){
    //             src = src.replace(/\.(jpg|png|jpeg)$/g, '.webp')
    //             baseUrl = CONFIG.webpCdnUrl
    //         }
    //         listener.src = `${baseUrl}/${src}`
    //     }
    // }
    // 图片内置到域名下
    webp(listener, options) {
      // 对浏览器端-懒加载图片进行处理
      let src = listener.src
      listener.src = requireCdnUrl(src, options.supportWebp)
    },
  },
})
