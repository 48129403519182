/*
 * @Descripttion: 国际跟踪码/渠道推广码
 * @version: V1.0
 * sdk url: https://confluence.sf-express.com/pages/viewpage.action?pageId=269565046
 * @Author: yuanxing(01407381)
 * @Date: 2023-01-13 11:39:31
 * @LastEditors: yuanxing(01407381)
 * @LastEditTime: 2023-04-06 11:24:18
 */
/**
 * 国际推广码统一配置
 */
const site = {
  sg: 'K2:sfIntlOs,K3:sfIntlOsSg', // 新加坡
  my: 'K2:sfIntlOs,K3:sfIntlOsMy', // 马来
  jp: 'K2:sfIntlOs,K3:sfIntlOsJp', // 日本站
  kr: 'K2:sfIntlOs,K3:sfIntlOsKr', // 韩国站
  us: 'K2:sfIntlOs,K3:sfIntlOsUsCa', // 美国加拿大站
  th: 'K2:sfIntlOs,K3:sfIntlOsTh', // 泰国站
  vn: 'K2:sfIntlOs,K3:sfIntlOsVn', // 越南站
  au: 'K2:sfIntlOs,K3:sfIntlOsAuNz', // 澳新站
  uk: 'K2:sfIntlOs,K3:sfIntlOsUk', // 英国站
  fr: 'K2:sfIntlOs,K3:sfIntlOsFr', // 法国站
  de: 'K2:sfIntlOs,K3:sfIntlOsDe', // 德国站
  id: 'K2:sfIntlOs,K3:sfIntlOsId', // 印度尼西亚站
  ph: 'K2:sfIntlOs,K3:sfIntlOsPh', // 菲律宾
}

const gjUtm = {
  // 登陆、注册、顺丰国际下单系统
  sfIntlOsLogin: {
    cn: 'K2:sfIntlOs,K3:sfIntlOsLogin',
    sg: site.sg,
    my: site.my,
    jp: site.jp,
    kr: site.kr,
    us: site.us,
    th: site.th,
    vn: site.vn,
    au: site.au,
    uk: site.uk,
    fr: site.fr,
    de: site.de,
    id: 'K2:sfIntlOs', // site.id,
    ph: site.ph,
  },
  // 物流服务产品介绍页“马上寄件”
  sfIntlOsSs1: {
    cn: 'K2:sfIntlOs,K3:sfIntlOsSs1',
    sg: site.sg,
    my: site.my,
    jp: site.jp,
    kr: site.kr,
    us: site.us,
    th: site.th,
    vn: site.vn,
    au: site.au,
    uk: site.uk,
    fr: site.fr,
    de: site.de,
    id: site.id,
    ph: site.ph,
  },
  // 马上寄件页按钮“前往寄件”
  sfIntlOsSs2: {
    cn: 'K2:sfIntlOs,K3:sfIntlOsSs2',
    sg: site.sg,
    my: site.my,
    jp: site.jp,
    kr: site.kr,
    us: site.us,
    th: site.th,
    vn: site.vn,
    au: site.au,
    uk: site.uk,
    fr: site.fr,
    de: site.de,
    id: site.id,
    ph: site.ph,
  },
  // 两个个落地页对应的马上寄件-前往寄件
  mainLanding: 'K2:sfIntlOs,K3:sfIntlOsSs3',
  expressLanding: 'K2:sfIntlOs,K3:sfIntlOsSs4',

  // 获取落地页对应的key
  getCurrentLandingKey(context, gjUtmStr) {
    const curRouteName = context.$route.name
    const arr = [
      { route: 'site-lang-landing-consign', key: 'mainLanding' },
      { route: 'site-lang-landing-express-consign', key: 'expressLanding' },
    ]
    const key = arr.find((item) => item.route === curRouteName)?.key
    return key ? this[key] : gjUtmStr
  },
}
export default gjUtm
