/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-04-07 14:08:48
 * @LastEditors: yuanxing
 * @LastEditTime: 2023-05-06 17:23:32
 * @FilePath: \ibu-owt-core-frontend\reporter\utils\index.js
 * @Description:
 */

import {
  SENSOR,
  EVENT,
  CHANNEL_ATTR,
  SC_ATTR_PREFIX,
  SC_ATTR_VERSION,
  EVENT_TYPE_ATTR,
  SIGN_ERROR_TYPE,
  SIGN_ERROR_MESSAGE,
  EVENT_MESSAGE_ATTR,
  EVENT_URL_ATTR,
} from '../config/const'

export const prefix = (property) => {
  return `${SC_ATTR_PREFIX}${property}${SC_ATTR_VERSION}`
}

export const singleTrack = (type, property = {}) => {
  console.log(type, 'singleTrack')
  // 公共属性里增加站点
  const owt_site = window?.$nuxt?.$store?.state?.site
  window[SENSOR].track(EVENT, {
    ...{ [EVENT_TYPE_ATTR]: type },
    ...{ owt_site },
    ...property,
  })
}

export const channelTrack = (channel) => {
  window[SENSOR].registerPage({ [CHANNEL_ATTR]: channel })
}

export const messageTrack = (message) => {
  window[SENSOR].registerPage({ [EVENT_MESSAGE_ATTR]: message })
}

export const keywordTrack = (type, keyword) => {
  window[SENSOR].track(EVENT, {
    [EVENT_TYPE_ATTR]: type,
    [EVENT_MESSAGE_ATTR]: keyword,
  })
}

export const urlKeywordTrack = (type, { url, keyword }) => {
  window[SENSOR].track(EVENT, {
    [EVENT_TYPE_ATTR]: type,
    [EVENT_URL_ATTR]: url,
    [EVENT_MESSAGE_ATTR]: keyword,
  })
}

export const signKeywordTrack = (attr, property) => {
  window[SENSOR].track(EVENT, {
    [EVENT_TYPE_ATTR]: attr,
    ...property,
  })
}
