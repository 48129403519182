export const SC_ATTR_PREFIX = 'intl_owt_'

export const SC_ATTR_VERSION = '_v1'

export const EVENT = 'intl_trigger'

export const EVENT_TYPE_ATTR = 'intl_eventType'

export const SIGN_ERROR_TYPE = 'intl_sign_error_type'

export const SIGN_ERROR_MESSAGE = 'intl_sign_error_message'

export const CHANNEL_ATTR = 'intl_qd'

export const EVENT_MESSAGE_ATTR = 'intl_message'

export const EVENT_URL_ATTR = '$url_query'

export const BD_CHANNEL = 'bd'

export const CHANNEL = {
  bd_vid: BD_CHANNEL,
}

export const STORAGE_KEY = {
  channel: 'channel',
  keyword: 'keyword',
  message: 'message',
  localurl: 'localurl',
  search: 'search',
}

export const KEYWORD = 'keyword'

export const MESSAGES = [KEYWORD]

export const SENSOR = 'sensorsDataAnalytic201505'

export const EVENT_TYPE = {
  Landing_Keyword_Track: 'Landing_Keyword_Track',
  Quote_Mainlandingpagetop_Click: 'Quote_Mainlandingpagetop_Click',
  Quote_Mainlandingpagedown_Click: 'Quote_Mainlandingpagedown_Click',
  Quote_Mainlandingpagetop_Succsubmit: 'Quote_Mainlandingpagetop_Succsubmit',
  Quote_Mainlandingpagedown_Succsubmit: 'Quote_Mainlandingpagedown_Succsubmit',
  Shipping_Mainlandingpagetop_Click: 'Shipping_Mainlandingpagetop_Click',
  Shipping_Mainlandingpagedown_Click: 'Shipping_Mainlandingpagedown_Click',
  TimeCost_Mainlandingpagetop_Click: 'TimeCost_Mainlandingpagetop_Click',
  TimeCost_Mainlandingpagedown_Click: 'TimeCost_Mainlandingpagedown_Click',
  Quote_Expresslandingpagetop_Click: 'Quote_Expresslandingpagetop_Click',
  Quote_Expresslandingpagedown_Click: 'Quote_Expresslandingpagedown_Click',
  Quote_Expresslandingpagetop_Succsubmit:
    'Quote_Expresslandingpagetop_Succsubmit',
  Quote_Expresslandingpagedown_Succsubmit:
    'Quote_Expresslandingpagedown_Succsubmit',
  Shipping_Expresslandingpagetop_Click: 'Shipping_Expresslandingpagetop_Click',
  Shipping_Expresslandingpagedown_Click:
    'Shipping_Expresslandingpagedown_Click',
  TimeCost_Expresslandingpagetop_Click: 'TimeCost_Expresslandingpagetop_Click',
  TimeCost_Expresslandingpagedown_Click:
    'TimeCost_Expresslandingpagedown_Click',
  Quote_Ecommercelandingpagetop_Click: 'Quote_Ecommercelandingpagetop_Click',
  Quote_Ecommercelandingpagedown_Click: 'Quote_Ecommercelandingpagedown_Click',
  Quote_Ecommercelandingpagetop_Succsubmit:
    'Quote_Ecommercelandingpagetop_Succsubmit',
  Quote_Ecommercelandingpagedown_Succsubmit:
    'Quote_Ecommercelandingpagedown_Succsubmit',
  Shipping_Mainshippingpage_Click: 'Shipping_Mainshippingpage_Click',
  Shipping_Expressshippingpage_Click: 'Shipping_Expressshippingpage_Click',
  Query_MaintimeCostpage_Click: 'Query_MaintimeCostpage_Click',
  Query_ExpresstimeCostpage_Click: 'Query_ExpresstimeCostpage_Click',

  // 百度推广-免费报价错误收集
  Quote_Mainlandingpagetop_Nameempty: 'Quote_Mainlandingpagetop_Nameempty',
  Quote_Mainlandingpagetop_Nameerror: 'Quote_Mainlandingpagetop_Nameerror',
  Quote_Mainlandingpagetop_Phoneempty: 'Quote_Mainlandingpagetop_Phoneempty',
  Quote_Mainlandingpagetop_Phoneerror: 'Quote_Mainlandingpagetop_Phoneerror',
  Quote_Mainlandingpagetop_Addressempty:
    'Quote_Mainlandingpagetop_Addressempty',
  Quote_Mainlandingpagetop_Addresserror:
    'Quote_Mainlandingpagetop_Addresserror',
  Quote_Mainlandingpagetop_Policynottick:
    'Quote_Mainlandingpagetop_Policynottick',
  Quote_Mainlandingpagetop_Submiterror: 'Quote_Mainlandingpagetop_Submiterror',
  Quote_Mainlandingpagedown_Nameempty: 'Quote_Mainlandingpagedown_Nameempty',
  Quote_Mainlandingpagedown_Nameerror: 'Quote_Mainlandingpagedown_Nameerror',
  Quote_Mainlandingpagedown_Phoneempty: 'Quote_Mainlandingpagedown_Phoneempty',
  Quote_Mainlandingpagedown_Phoneerror: 'Quote_Mainlandingpagedown_Phoneerror',
  Quote_Mainlandingpagedown_Addressempty:
    'Quote_Mainlandingpagedown_Addressempty',
  Quote_Mainlandingpagedown_Addresserror:
    'Quote_Mainlandingpagedown_Addresserror',
  Quote_Mainlandingpagedown_Policynottick:
    'Quote_Mainlandingpagedown_Policynottick',
  Quote_Mainlandingpagedown_Submiterror:
    'Quote_Mainlandingpagedown_Submiterror',
  Quote_Expresslandingpagetop_Nameempty:
    'Quote_Expresslandingpagetop_Nameempty',
  Quote_Expresslandingpagetop_Nameerror:
    'Quote_Expresslandingpagetop_Nameerror',
  Quote_Expresslandingpagetop_Phoneempty:
    'Quote_Expresslandingpagetop_Phoneempty',
  Quote_Expresslandingpagetop_Phoneerror:
    'Quote_Expresslandingpagetop_Phoneerror',
  Quote_Expresslandingpagetop_Addressempty:
    'Quote_Expresslandingpagetop_Addressempty',
  Quote_Expresslandingpagetop_Addresserror:
    'Quote_Expresslandingpagetop_Addresserror',
  Quote_Expresslandingpagetop_Policynottick:
    'Quote_Expresslandingpagetop_Policynottick',
  Quote_Expresslandingpagetop_Submiterror:
    'Quote_Expresslandingpagetop_Submiterror',
  Quote_Expresslandingpagedown_Nameempty:
    'Quote_Expresslandingpagedown_Nameempty',
  Quote_Expresslandingpagedown_Nameerror:
    'Quote_Expresslandingpagedown_Nameerror',
  Quote_Expresslandingpagedown_Phoneempty:
    'Quote_Expresslandingpagedown_Phoneempty',
  Quote_Expresslandingpagedown_Phoneerror:
    'Quote_Expresslandingpagedown_Phoneerror',
  Quote_Expresslandingpagedown_Addressempty:
    'Quote_Expresslandingpagedown_Addressempty',
  Quote_Expresslandingpagedown_Addresserror:
    'Quote_Expresslandingpagedown_Addresserror',
  Quote_Expresslandingpagedown_Policynottick:
    'Quote_Expresslandingpagedown_Policynottick',
  Quote_Expresslandingpagedown_Submiterror:
    'Quote_Expresslandingpagedown_Submiterror',
  Quote_Ecommercelandingpagetop_Nameempty:
    'Quote_Ecommercelandingpagetop_Nameempty',
  Quote_Ecommercelandingpagetop_Nameerror:
    'Quote_Ecommercelandingpagetop_Nameerror',
  Quote_Ecommercelandingpagetop_Phoneempty:
    'Quote_Ecommercelandingpagetop_Phoneempty',
  Quote_Ecommercelandingpagetop_Phoneerror:
    'Quote_Ecommercelandingpagetop_Phoneerror',
  Quote_Ecommercelandingpagetop_Addressempty:
    'Quote_Ecommercelandingpagetop_Addressempty',
  Quote_Ecommercelandingpagetop_Addresserror:
    'Quote_Ecommercelandingpagetop_Addresserror',
  Quote_Ecommercelandingpagetop_Policynottick:
    'Quote_Ecommercelandingpagetop_Policynottick',
  Quote_Ecommercelandingpagetop_Submiterror:
    'Quote_Ecommercelandingpagetop_Submiterror',
  Quote_Ecommercelandingpagedown_Nameempty:
    'Quote_Ecommercelandingpagedown_Nameempty',
  Quote_Ecommercelandingpagedown_Nameerror:
    'Quote_Ecommercelandingpagedown_Nameerror',
  Quote_Ecommercelandingpagedown_Phoneempty:
    'Quote_Ecommercelandingpagedown_Phoneempty',
  Quote_Ecommercelandingpagedown_Phoneerror:
    'Quote_Ecommercelandingpagedown_Phoneerror',
  Quote_Ecommercelandingpagedown_Addressempty:
    'Quote_Ecommercelandingpagedown_Addressempty',
  Quote_Ecommercelandingpagedown_Addresserror:
    'Quote_Ecommercelandingpagedown_Addresserror',
  Quote_Ecommercelandingpagedown_Policynottick:
    'Quote_Ecommercelandingpagedown_Policynottick',
  Quote_Ecommercelandingpagedown_Submiterror:
    'Quote_Ecommercelandingpagedown_Submiterror',

  // 常规站点
  Login_Top_Click: 'Login_Top_Click',
  Register_Top_Click: 'Register_Top_Click',
  Expressservice_Hoverbar_Click: 'Expressservice_Hoverbar_Click',
  Ecommerceservice_Hoverbar_Click: 'Ecommerceservice_Hoverbar_Click',
  SFBuyservice_Hoverbar_Click: 'SFBuyservice_Hoverbar_Click',
  Cooperate_Hoverbar_Click: 'Cooperate_Hoverbar_Click',
  Shipping_Shipping_Click: 'Shipping_Shipping_Click',
  Shipping_Product_Click: 'Shipping_Product_Click',
  Shipping_Time_Cost_Click: 'Shipping_Time_Cost_Click',
  Tracking_Home_Click: 'Tracking_Home_Click',
  Query_Tracking_Click: 'Query_Tracking_Click',
  Tracking_Appointment_Click: 'Tracking_Appointment_Click',
  Appointment_Mobile_Submit_Click: 'Appointment_Mobile_Submit_Click',
  Appointment_Confirm_Click: 'Appointment_Confirm_Click',
  Appointment_Cancel_Click: 'Appointment_Cancel_Click',
  Tracking_Modify_Receiver_Click: 'Tracking_Modify_Receiver_Click',
  Modify_Receiver_Cancel_Popup_yes_Click:
    'Modify_Receiver_Cancel_Popup_yes_Click',
  Tracking_Appointment_Success: 'Tracking_Appointment_Success',
  Tracking_Modify_Receiver_Success: 'Tracking_Modify_Receiver_Success',
  Query_Time_Cost_Click: 'Query_Time_Cost_Click',
  Query_Itemrestriction_Click: 'Query_Itemrestriction_Click',
  Query_Servicecoverage_Click: 'Query_Servicecoverage_Click',
  Query_Servicepoint_Click: 'Query_Servicepoint_Click',
  Query_Dutytax_Click: 'Query_Dutytax_Click',
  Query_Inportexportrule_Click: 'Query_Inportexportrule_Click',
  Submit_Clearancepicupload_Click: 'Submit_Clearancepicupload_Click',
  Query_Clearanceinfocollect_Click: 'Query_Clearanceinfocollect_Click',
  Submit_Clearanceinfocollect_Submit: 'Submit_Clearanceinfocollect_Submit',
  Query_Clearanceform_Click: 'Query_Clearanceform_Click',
  Query_Clearanceform_Download: 'Query_Clearanceform_Download',
  Query_Dutyservicefee_Query: 'Query_Dutyservicefee_Query',
  Download_Downloadcenter_Click: 'Download_Downloadcenter_Click',
  Query_Importclearancerule_Click: 'Query_Importclearancerule_Click', // 进口清关指南--查询
  Download_ImportClearancerule_Click: 'Download_ImportClearancerule_Click', // 进口清关指南--文件查看
  OnlineCustomDeclaration_EditExport_Click:
    'OnlineCustomDeclaration_EditExport_Click', // 在线申报 "编辑出口申报信息"_点击
  OnlineCustomDeclaration_EditImport_Click:
    'OnlineCustomDeclaration_EditImport_Click', // 在线申报 "编辑进口申报信息"_点击
  OnlineCustomsDeclaration_OrderDetail_SubmitDocument_Click:
    'OnlineCustomsDeclaration_OrderDetail_SubmitDocument_Click', // 上传单证"提交"按钮_点击
  OnlineCustomsDeclaration_OrderDetail_SubmitForm_Click:
    'OnlineCustomsDeclaration_OrderDetail_SubmitForm_Click', //  表单"提交"按钮_点击
  OnlineCustomsDeclaration_Guide_Click: 'OnlineCustomsDeclaration_Guide_Click', // 报关申报指引入口点击量
  MainlandChinaTax_Web_View: 'MainlandChinaTax_Web_View', // 官网页面访问量
  MainlandChinaTax_MiniApp_View: 'MainlandChinaTax_MiniApp_View', // 速运小程序 H5 页面访问量
  MainlandChinaTax_App_View: 'MainlandChinaTax_App_View', // 速运 APP H5 页面访问量
  MainlandChinaTax_Web_Click: 'MainlandChinaTax_Web_Click', // 官网页面查询按钮点击量
  MainlandChinaTax_MiniApp_Click: 'MainlandChinaTax_MiniApp_Click', // 速运小程序 H5 页面查询按钮点击量
  MainlandChinaTax_App_Click: 'MainlandChinaTax_App_Click', // 速运 APP H5 页面查询按钮点击量

  MainlandChinaTax_Web_CrpView: 'MainlandChinaTax_Web_CrpView', // pc C_RP
  MainlandChinaTax_MiniApp_CrpView: 'MainlandChinaTax_MiniApp_CrpView', // miniapp C_RP
  MainlandChinaTax_App_CrpView: 'MainlandChinaTax_App_CrpView', // app C_RP
  MainlandChinaTax_Web_CediView: 'MainlandChinaTax_Web_CediView', // pc C_RP
  MainlandChinaTax_MiniApp_CediView: 'MainlandChinaTax_MiniApp_CediView', // miniapp C_RP
  MainlandChinaTax_App_CediView: 'MainlandChinaTax_App_CediView', // app C_RP
  MainlandChinaTax_Web_FediView: 'MainlandChinaTax_Web_FediView', // pc C_RP
  MainlandChinaTax_MiniApp_FediView: 'MainlandChinaTax_MiniApp_FediView', // miniapp C_RP
  MainlandChinaTax_App_FediView: 'MainlandChinaTax_App_FediView', // app C_RP
  MainlandChinaTax_Web_BediView: 'MainlandChinaTax_Web_BediView', // pc C_RP
  MainlandChinaTax_MiniApp_BediView: 'MainlandChinaTax_MiniApp_BediView', // miniapp C_RP
  MainlandChinaTax_App_BediView: 'MainlandChinaTax_App_BediView', // app C_RP

  Shipping_Shipping_LinkClick: 'Shipping_Shipping_LinkClick',

  // 加签
  Sign_Error_Track: 'Sign_Error_Track',

  // 税金支付
  MainlandChinaTax_ToBePaid: 'MainlandChinaTax_ToBePaid', // 官网页面查询待支付运单号
  MainlandChinaTax_ClickPay: 'MainlandChinaTax_ClickPay', // 官网页面支付按钮点击量
  MainlandChinaTax_PaySuccess: 'MainlandChinaTax_PaySuccess', // 官网页面支付成功次数
  // 发票申请
  Invoice_Query_Button: 'InvoiceQueryButton', // 查询按钮点击
  Invoice_Apply_Success: 'InvoiceApplySuccess', // 发票申请成功
}
