/*
 * @Author: liyang
 * @Date: 2021-04-10 11:00:47
 * @LastEditTime: 2022-05-05 15:27:50
 * @LastEditors: yuanxing
 * @FilePath: \globalweb\plugins\cookies.js
 * @Description:
 */

import Vue from 'vue'
if (!Vue.useCookies) {
  Vue.useCookies = true
  Vue.prototype.$cookies = process.client ? require('js-cookie') : undefined
}
