import { WOW } from 'wowjs'

export default ({ app, store }, inject) => {
  const wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animate__animated',
    offset: 100,
    duration: 2,
    delay: 2,
    mobile: false,
    live: true,
  })
  inject('animate', wow)
}
