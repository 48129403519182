/*
 * @Descripttion: vant组件
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2022-06-20 15:56:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-12 21:08:25
 */
import Vue from 'vue'
import {
  Tab,
  Tabs,
  Popup,
  Cell,
  CellGroup,
  Radio,
  RadioGroup,
  DatetimePicker,
  Stepper,
  Switch,
  Field,
  IndexBar,
  IndexAnchor,
  Form,
  Search,
  Toast,
  Button,
  Dialog,
  List,
  NoticeBar,
  ActionSheet,
  Tag,
  Uploader,
  Swipe,
  SwipeItem,
  ImagePreview,
  Overlay,
  Loading,
  Popover,
} from 'vant'
const components = [
  Tab,
  Tabs,
  Popup,
  Cell,
  CellGroup,
  Radio,
  RadioGroup,
  DatetimePicker,
  Stepper,
  Switch,
  Field,
  IndexBar,
  IndexAnchor,
  Form,
  Search,
  Toast,
  Button,
  Dialog,
  List,
  NoticeBar,
  ActionSheet,
  Tag,
  Uploader,
  Swipe,
  SwipeItem,
  ImagePreview,
  Overlay,
  Loading,
  Popover,
]
export default (ctx, inject) => {
  if (!Vue.useVant) {
    components.forEach((component) => Vue.use(component))
  }
  Vue.useAnt = true
}
