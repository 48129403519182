import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9c1d32ee = () => interopDefault(import('../pages/_site/_lang/index.vue' /* webpackChunkName: "pages/_site/_lang/index" */))
const _4287e7b9 = () => interopDefault(import('../pages/_site/_lang/consign/index.vue' /* webpackChunkName: "pages/_site/_lang/consign/index" */))
const _7bdd392d = () => interopDefault(import('../pages/_site/_lang/detail/index.vue' /* webpackChunkName: "pages/_site/_lang/detail/index" */))
const _5ad17ffc = () => interopDefault(import('../pages/_site/_lang/error/index.vue' /* webpackChunkName: "pages/_site/_lang/error/index" */))
const _abbb34de = () => interopDefault(import('../pages/_site/_lang/landing/index.vue' /* webpackChunkName: "pages/_site/_lang/landing/index" */))
const _ad3133fc = () => interopDefault(import('../pages/_site/_lang/preview/index.vue' /* webpackChunkName: "pages/_site/_lang/preview/index" */))
const _ab176b2e = () => interopDefault(import('../pages/_site/_lang/product/index.vue' /* webpackChunkName: "pages/_site/_lang/product/index" */))
const _2a8701ec = () => interopDefault(import('../pages/_site/_lang/about/address/index.vue' /* webpackChunkName: "pages/_site/_lang/about/address/index" */))
const _4709bcc4 = () => interopDefault(import('../pages/_site/_lang/about/board/index.vue' /* webpackChunkName: "pages/_site/_lang/about/board/index" */))
const _4dc317ba = () => interopDefault(import('../pages/_site/_lang/about/businessLaw/index.vue' /* webpackChunkName: "pages/_site/_lang/about/businessLaw/index" */))
const _dd9fdd26 = () => interopDefault(import('../pages/_site/_lang/about/careers/index.vue' /* webpackChunkName: "pages/_site/_lang/about/careers/index" */))
const _232b4318 = () => interopDefault(import('../pages/_site/_lang/about/contact/index.vue' /* webpackChunkName: "pages/_site/_lang/about/contact/index" */))
const _00a5beaf = () => interopDefault(import('../pages/_site/_lang/about/detail/index.vue' /* webpackChunkName: "pages/_site/_lang/about/detail/index" */))
const _289b7a05 = () => interopDefault(import('../pages/_site/_lang/about/infoProtection/index.vue' /* webpackChunkName: "pages/_site/_lang/about/infoProtection/index" */))
const _56d39bd2 = () => interopDefault(import('../pages/_site/_lang/about/introduce/index.vue' /* webpackChunkName: "pages/_site/_lang/about/introduce/index" */))
const _3afd0370 = () => interopDefault(import('../pages/_site/_lang/about/jobOpenings/index.vue' /* webpackChunkName: "pages/_site/_lang/about/jobOpenings/index" */))
const _5b98891e = () => interopDefault(import('../pages/_site/_lang/about/news/index.vue' /* webpackChunkName: "pages/_site/_lang/about/news/index" */))
const _1372109b = () => interopDefault(import('../pages/_site/_lang/about/promotion/index.vue' /* webpackChunkName: "pages/_site/_lang/about/promotion/index" */))
const _6c9ae974 = () => interopDefault(import('../pages/_site/_lang/about/search/index.vue' /* webpackChunkName: "pages/_site/_lang/about/search/index" */))
const _ebc0bbec = () => interopDefault(import('../pages/_site/_lang/about/topInterview/index.vue' /* webpackChunkName: "pages/_site/_lang/about/topInterview/index" */))
const _3b814284 = () => interopDefault(import('../pages/_site/_lang/landing/consign.vue' /* webpackChunkName: "pages/_site/_lang/landing/consign" */))
const _61968bc2 = () => interopDefault(import('../pages/_site/_lang/landing/ecommerce/index.vue' /* webpackChunkName: "pages/_site/_lang/landing/ecommerce/index" */))
const _790bd29c = () => interopDefault(import('../pages/_site/_lang/landing/express/index.vue' /* webpackChunkName: "pages/_site/_lang/landing/express/index" */))
const _f3a70e82 = () => interopDefault(import('../pages/_site/_lang/landing/freight_aging.vue' /* webpackChunkName: "pages/_site/_lang/landing/freight_aging" */))
const _27716be0 = () => interopDefault(import('../pages/_site/_lang/support/faq/index.vue' /* webpackChunkName: "pages/_site/_lang/support/faq/index" */))
const _70f076b6 = () => interopDefault(import('../pages/_site/_lang/support/guide/index.vue' /* webpackChunkName: "pages/_site/_lang/support/guide/index" */))
const _03cc5e14 = () => interopDefault(import('../pages/_site/_lang/support/privacyPolicy/index.vue' /* webpackChunkName: "pages/_site/_lang/support/privacyPolicy/index" */))
const _83e9f040 = () => interopDefault(import('../pages/_site/_lang/support/suggestion/index.vue' /* webpackChunkName: "pages/_site/_lang/support/suggestion/index" */))
const _8a76baf6 = () => interopDefault(import('../pages/_site/_lang/support/termsOfUse/index.vue' /* webpackChunkName: "pages/_site/_lang/support/termsOfUse/index" */))
const _2d252bc6 = () => interopDefault(import('../pages/_site/_lang/about/careers/detail.vue' /* webpackChunkName: "pages/_site/_lang/about/careers/detail" */))
const _3475da6f = () => interopDefault(import('../pages/_site/_lang/about/intro/detail.vue' /* webpackChunkName: "pages/_site/_lang/about/intro/detail" */))
const _3eb8d0a0 = () => interopDefault(import('../pages/_site/_lang/about/intro/detail/about/index.vue' /* webpackChunkName: "pages/_site/_lang/about/intro/detail/about/index" */))
const _32821da4 = () => interopDefault(import('../pages/_site/_lang/about/intro/detail/course/index.vue' /* webpackChunkName: "pages/_site/_lang/about/intro/detail/course/index" */))
const _036bbade = () => interopDefault(import('../pages/_site/_lang/about/intro/detail/culture/index.vue' /* webpackChunkName: "pages/_site/_lang/about/intro/detail/culture/index" */))
const _17b6355d = () => interopDefault(import('../pages/_site/_lang/about/intro/detail/desire/index.vue' /* webpackChunkName: "pages/_site/_lang/about/intro/detail/desire/index" */))
const _f11f1f66 = () => interopDefault(import('../pages/_site/_lang/about/intro/detail/honor/index.vue' /* webpackChunkName: "pages/_site/_lang/about/intro/detail/honor/index" */))
const _2052fd4f = () => interopDefault(import('../pages/_site/_lang/about/intro/detail/about/info.js' /* webpackChunkName: "pages/_site/_lang/about/intro/detail/about/info" */))
const _e98623b2 = () => interopDefault(import('../pages/_site/_lang/about/intro/detail/about/mb.vue' /* webpackChunkName: "pages/_site/_lang/about/intro/detail/about/mb" */))
const _df2ce0f6 = () => interopDefault(import('../pages/_site/_lang/about/intro/detail/about/pc.vue' /* webpackChunkName: "pages/_site/_lang/about/intro/detail/about/pc" */))
const _04f1ed0d = () => interopDefault(import('../pages/_site/_lang/about/search/detail.vue' /* webpackChunkName: "pages/_site/_lang/about/search/detail" */))
const _046f4b9f = () => interopDefault(import('../pages/_site/_lang/landing/express/consign.vue' /* webpackChunkName: "pages/_site/_lang/landing/express/consign" */))
const _73d50e40 = () => interopDefault(import('../pages/_site/_lang/landing/express/freight_aging.vue' /* webpackChunkName: "pages/_site/_lang/landing/express/freight_aging" */))
const _39434156 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/apply/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/apply/index" */))
const _205d4d80 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/certificateQuery/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/certificateQuery/index" */))
const _6aa11fab = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/certificateQuery.back/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/certificateQuery.back/index" */))
const _ccc68a8e = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/customsRequirements/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/customsRequirements/index" */))
const _16a03ec4 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/customsTaxFee/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/customsTaxFee/index" */))
const _43d329d9 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/declarationNotice/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/declarationNotice/index" */))
const _3f7181a0 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/infromaton/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/infromaton/index" */))
const _7f184e28 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/taxQuery/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/taxQuery/index" */))
const _e185429e = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/uploadPhotos/index.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/uploadPhotos/index" */))
const _c79c50ca = () => interopDefault(import('../pages/_site/_lang/support/querySupport/areaCascade/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/areaCascade/index" */))
const _8a9cdcc6 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/auth_reseller/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/auth_reseller/index" */))
const _0b7ac988 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/authResellerApply/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/authResellerApply/index" */))
const _3f570567 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/dept/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/dept/index" */))
const _23d5e3c7 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/download_file/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/download_file/index" */))
const _7ec03ada = () => interopDefault(import('../pages/_site/_lang/support/querySupport/downloadTextFile/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/downloadTextFile/index" */))
const _0a224184 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/exce_rate/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/exce_rate/index" */))
const _496d8eca = () => interopDefault(import('../pages/_site/_lang/support/querySupport/fee_rate/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/fee_rate/index" */))
const _3bdba298 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/freight_aging/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/freight_aging/index" */))
const _2858f322 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/fuelSurcharge/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/fuelSurcharge/index" */))
const _2a3c15cc = () => interopDefault(import('../pages/_site/_lang/support/querySupport/holiday/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/holiday/index" */))
const _648c5678 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/invoiceRequest/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/invoiceRequest/index" */))
const _11311f5e = () => interopDefault(import('../pages/_site/_lang/support/querySupport/receiving_standard/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/receiving_standard/index" */))
const _05839d86 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/receiving_standard_tc/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/receiving_standard_tc/index" */))
const _5eddff6a = () => interopDefault(import('../pages/_site/_lang/support/querySupport/waybill/index.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/waybill/index" */))
const _20c09ade = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/apply/detail.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/apply/detail" */))
const _37c73a03 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/apply/detailState.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/apply/detailState" */))
const _4e58b711 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/apply/form.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/apply/form" */))
const _669febd9 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/apply/goods.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/apply/goods" */))
const _3d37ede7 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/apply/historyTable.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/apply/historyTable" */))
const _b1c744b6 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/apply/result.js' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/apply/result" */))
const _7b0fec4a = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/certificateQuery/download.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/certificateQuery/download" */))
const _0a44c7b5 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/customsTaxFee/expense-mobile.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/customsTaxFee/expense-mobile" */))
const _7e99e412 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/taxQuery/config.js' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/taxQuery/config" */))
const _61cc4edb = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/taxQuery/configMI.js' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/taxQuery/configMI" */))
const _334b57b2 = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/taxQuery/detail.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/taxQuery/detail" */))
const _08bd05fe = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/taxQuery/taxs.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/taxQuery/taxs" */))
const _0040bfcc = () => interopDefault(import('../pages/_site/_lang/support/customsClearance/taxQuery/taxsMI.vue' /* webpackChunkName: "pages/_site/_lang/support/customsClearance/taxQuery/taxsMI" */))
const _7671b1f6 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/areaCascade/tc.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/areaCascade/tc" */))
const _42c0a716 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/exce_rate/mixins/index.js' /* webpackChunkName: "pages/_site/_lang/support/querySupport/exce_rate/mixins/index" */))
const _09dd507c = () => interopDefault(import('../pages/_site/_lang/support/querySupport/invoiceRequest/form.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/invoiceRequest/form" */))
const _c4fa85ca = () => interopDefault(import('../pages/_site/_lang/support/querySupport/invoiceRequest/result.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/invoiceRequest/result" */))
const _717b6ef1 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/areaCascade/utils/main.js' /* webpackChunkName: "pages/_site/_lang/support/querySupport/areaCascade/utils/main" */))
const _44bba55a = () => interopDefault(import('../pages/_site/_lang/support/querySupport/holiday/component/bottom-modal.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/holiday/component/bottom-modal" */))
const _261f28e6 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/holiday/component/select-country.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/holiday/component/select-country" */))
const _c0c02e7c = () => interopDefault(import('../pages/_site/_lang/support/querySupport/waybill/mobile/result.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/waybill/mobile/result" */))
const _b8c69ab6 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/waybill/mobile/samsung.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/waybill/mobile/samsung" */))
const _21b90434 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/waybill/mobile/samsungReceiver.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/waybill/mobile/samsungReceiver" */))
const _2f5424bc = () => interopDefault(import('../pages/_site/_lang/support/querySupport/waybill/mobile/sign.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/waybill/mobile/sign" */))
const _083ecc94 = () => interopDefault(import('../pages/_site/_lang/support/querySupport/waybill/mobile/signDelivery.vue' /* webpackChunkName: "pages/_site/_lang/support/querySupport/waybill/mobile/signDelivery" */))
const _79d1a2d5 = () => interopDefault(import('../pages/_site/_lang/detail/_id.vue' /* webpackChunkName: "pages/_site/_lang/detail/_id" */))
const _5ef49772 = () => interopDefault(import('../pages/_site/_lang/product/_detail.vue' /* webpackChunkName: "pages/_site/_lang/product/_detail" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/*/*/dynamic_function/waybill/",
    redirect: "/*/*/support/querySupport/waybill"
  }, {
    path: "/",
    redirect: "/cn/sc"
  }, {
    path: "/zh/*",
    redirect: "/cn/404"
  }, {
    path: "/:site?/:lang",
    component: _9c1d32ee,
    name: "site-lang"
  }, {
    path: "/:site?/:lang?/consign",
    component: _4287e7b9,
    name: "site-lang-consign"
  }, {
    path: "/:site?/:lang?/detail",
    component: _7bdd392d,
    name: "site-lang-detail"
  }, {
    path: "/:site?/:lang?/error",
    component: _5ad17ffc,
    name: "site-lang-error"
  }, {
    path: "/:site?/:lang?/landing",
    component: _abbb34de,
    name: "site-lang-landing"
  }, {
    path: "/:site?/:lang?/preview",
    component: _ad3133fc,
    name: "site-lang-preview"
  }, {
    path: "/:site?/:lang?/product",
    component: _ab176b2e,
    name: "site-lang-product"
  }, {
    path: "/:site?/:lang?/about/address",
    component: _2a8701ec,
    name: "site-lang-about-address"
  }, {
    path: "/:site?/:lang?/about/board",
    component: _4709bcc4,
    name: "site-lang-about-board"
  }, {
    path: "/:site?/:lang?/about/businessLaw",
    component: _4dc317ba,
    name: "site-lang-about-businessLaw"
  }, {
    path: "/:site?/:lang?/about/careers",
    component: _dd9fdd26,
    name: "site-lang-about-careers"
  }, {
    path: "/:site?/:lang?/about/contact",
    component: _232b4318,
    name: "site-lang-about-contact"
  }, {
    path: "/:site?/:lang?/about/detail",
    component: _00a5beaf,
    name: "site-lang-about-detail"
  }, {
    path: "/:site?/:lang?/about/infoProtection",
    component: _289b7a05,
    name: "site-lang-about-infoProtection"
  }, {
    path: "/:site?/:lang?/about/introduce",
    component: _56d39bd2,
    name: "site-lang-about-introduce"
  }, {
    path: "/:site?/:lang?/about/jobOpenings",
    component: _3afd0370,
    name: "site-lang-about-jobOpenings"
  }, {
    path: "/:site?/:lang?/about/news",
    component: _5b98891e,
    name: "site-lang-about-news"
  }, {
    path: "/:site?/:lang?/about/promotion",
    component: _1372109b,
    name: "site-lang-about-promotion"
  }, {
    path: "/:site?/:lang?/about/search",
    component: _6c9ae974,
    name: "site-lang-about-search"
  }, {
    path: "/:site?/:lang?/about/topInterview",
    component: _ebc0bbec,
    name: "site-lang-about-topInterview"
  }, {
    path: "/:site?/:lang?/landing/consign",
    component: _3b814284,
    name: "site-lang-landing-consign"
  }, {
    path: "/:site?/:lang?/landing/ecommerce",
    component: _61968bc2,
    name: "site-lang-landing-ecommerce"
  }, {
    path: "/:site?/:lang?/landing/express",
    component: _790bd29c,
    name: "site-lang-landing-express"
  }, {
    path: "/:site?/:lang?/landing/freight_aging",
    component: _f3a70e82,
    name: "site-lang-landing-freight_aging"
  }, {
    path: "/:site?/:lang?/support/faq",
    component: _27716be0,
    name: "site-lang-support-faq"
  }, {
    path: "/:site?/:lang?/support/guide",
    component: _70f076b6,
    name: "site-lang-support-guide"
  }, {
    path: "/:site?/:lang?/support/privacyPolicy",
    component: _03cc5e14,
    name: "site-lang-support-privacyPolicy"
  }, {
    path: "/:site?/:lang?/support/suggestion",
    component: _83e9f040,
    name: "site-lang-support-suggestion"
  }, {
    path: "/:site?/:lang?/support/termsOfUse",
    component: _8a76baf6,
    name: "site-lang-support-termsOfUse"
  }, {
    path: "/:site?/:lang?/about/careers/detail",
    component: _2d252bc6,
    name: "site-lang-about-careers-detail"
  }, {
    path: "/:site?/:lang?/about/intro/detail",
    component: _3475da6f,
    name: "site-lang-about-intro-detail",
    children: [{
      path: "about",
      component: _3eb8d0a0,
      name: "site-lang-about-intro-detail-about"
    }, {
      path: "course",
      component: _32821da4,
      name: "site-lang-about-intro-detail-course"
    }, {
      path: "culture",
      component: _036bbade,
      name: "site-lang-about-intro-detail-culture"
    }, {
      path: "desire",
      component: _17b6355d,
      name: "site-lang-about-intro-detail-desire"
    }, {
      path: "honor",
      component: _f11f1f66,
      name: "site-lang-about-intro-detail-honor"
    }, {
      path: "about/info",
      component: _2052fd4f,
      name: "site-lang-about-intro-detail-about-info"
    }, {
      path: "about/mb",
      component: _e98623b2,
      name: "site-lang-about-intro-detail-about-mb"
    }, {
      path: "about/pc",
      component: _df2ce0f6,
      name: "site-lang-about-intro-detail-about-pc"
    }]
  }, {
    path: "/:site?/:lang?/about/search/detail",
    component: _04f1ed0d,
    name: "site-lang-about-search-detail"
  }, {
    path: "/:site?/:lang?/landing/express/consign",
    component: _046f4b9f,
    name: "site-lang-landing-express-consign"
  }, {
    path: "/:site?/:lang?/landing/express/freight_aging",
    component: _73d50e40,
    name: "site-lang-landing-express-freight_aging"
  }, {
    path: "/:site?/:lang?/support/customsClearance/apply",
    component: _39434156,
    name: "site-lang-support-customsClearance-apply"
  }, {
    path: "/:site?/:lang?/support/customsClearance/certificateQuery",
    component: _205d4d80,
    name: "site-lang-support-customsClearance-certificateQuery"
  }, {
    path: "/:site?/:lang?/support/customsClearance/certificateQuery.back",
    component: _6aa11fab,
    name: "site-lang-support-customsClearance-certificateQuery.back"
  }, {
    path: "/:site?/:lang?/support/customsClearance/customsRequirements",
    component: _ccc68a8e,
    name: "site-lang-support-customsClearance-customsRequirements"
  }, {
    path: "/:site?/:lang?/support/customsClearance/customsTaxFee",
    component: _16a03ec4,
    name: "site-lang-support-customsClearance-customsTaxFee"
  }, {
    path: "/:site?/:lang?/support/customsClearance/declarationNotice",
    component: _43d329d9,
    name: "site-lang-support-customsClearance-declarationNotice"
  }, {
    path: "/:site?/:lang?/support/customsClearance/infromaton",
    component: _3f7181a0,
    name: "site-lang-support-customsClearance-infromaton"
  }, {
    path: "/:site?/:lang?/support/customsClearance/taxQuery",
    component: _7f184e28,
    name: "site-lang-support-customsClearance-taxQuery"
  }, {
    path: "/:site?/:lang?/support/customsClearance/uploadPhotos",
    component: _e185429e,
    name: "site-lang-support-customsClearance-uploadPhotos"
  }, {
    path: "/:site?/:lang?/support/querySupport/areaCascade",
    component: _c79c50ca,
    name: "site-lang-support-querySupport-areaCascade"
  }, {
    path: "/:site?/:lang?/support/querySupport/auth_reseller",
    component: _8a9cdcc6,
    name: "site-lang-support-querySupport-auth_reseller"
  }, {
    path: "/:site?/:lang?/support/querySupport/authResellerApply",
    component: _0b7ac988,
    name: "site-lang-support-querySupport-authResellerApply"
  }, {
    path: "/:site?/:lang?/support/querySupport/dept",
    component: _3f570567,
    name: "site-lang-support-querySupport-dept"
  }, {
    path: "/:site?/:lang?/support/querySupport/download_file",
    component: _23d5e3c7,
    name: "site-lang-support-querySupport-download_file"
  }, {
    path: "/:site?/:lang?/support/querySupport/downloadTextFile",
    component: _7ec03ada,
    name: "site-lang-support-querySupport-downloadTextFile"
  }, {
    path: "/:site?/:lang?/support/querySupport/exce_rate",
    component: _0a224184,
    name: "site-lang-support-querySupport-exce_rate"
  }, {
    path: "/:site?/:lang?/support/querySupport/fee_rate",
    component: _496d8eca,
    name: "site-lang-support-querySupport-fee_rate"
  }, {
    path: "/:site?/:lang?/support/querySupport/freight_aging",
    component: _3bdba298,
    name: "site-lang-support-querySupport-freight_aging"
  }, {
    path: "/:site?/:lang?/support/querySupport/fuelSurcharge",
    component: _2858f322,
    name: "site-lang-support-querySupport-fuelSurcharge"
  }, {
    path: "/:site?/:lang?/support/querySupport/holiday",
    component: _2a3c15cc,
    name: "site-lang-support-querySupport-holiday"
  }, {
    path: "/:site?/:lang?/support/querySupport/invoiceRequest",
    component: _648c5678,
    name: "site-lang-support-querySupport-invoiceRequest"
  }, {
    path: "/:site?/:lang?/support/querySupport/receiving_standard",
    component: _11311f5e,
    name: "site-lang-support-querySupport-receiving_standard"
  }, {
    path: "/:site?/:lang?/support/querySupport/receiving_standard_tc",
    component: _05839d86,
    name: "site-lang-support-querySupport-receiving_standard_tc"
  }, {
    path: "/:site?/:lang?/support/querySupport/waybill",
    component: _5eddff6a,
    name: "site-lang-support-querySupport-waybill"
  }, {
    path: "/:site?/:lang?/support/customsClearance/apply/detail",
    component: _20c09ade,
    name: "site-lang-support-customsClearance-apply-detail"
  }, {
    path: "/:site?/:lang?/support/customsClearance/apply/detailState",
    component: _37c73a03,
    name: "site-lang-support-customsClearance-apply-detailState"
  }, {
    path: "/:site?/:lang?/support/customsClearance/apply/form",
    component: _4e58b711,
    name: "site-lang-support-customsClearance-apply-form"
  }, {
    path: "/:site?/:lang?/support/customsClearance/apply/goods",
    component: _669febd9,
    name: "site-lang-support-customsClearance-apply-goods"
  }, {
    path: "/:site?/:lang?/support/customsClearance/apply/historyTable",
    component: _3d37ede7,
    name: "site-lang-support-customsClearance-apply-historyTable"
  }, {
    path: "/:site?/:lang?/support/customsClearance/apply/result",
    component: _b1c744b6,
    name: "site-lang-support-customsClearance-apply-result"
  }, {
    path: "/:site?/:lang?/support/customsClearance/certificateQuery/download",
    component: _7b0fec4a,
    name: "site-lang-support-customsClearance-certificateQuery-download"
  }, {
    path: "/:site?/:lang?/support/customsClearance/customsTaxFee/expense-mobile",
    component: _0a44c7b5,
    name: "site-lang-support-customsClearance-customsTaxFee-expense-mobile"
  }, {
    path: "/:site?/:lang?/support/customsClearance/taxQuery/config",
    component: _7e99e412,
    name: "site-lang-support-customsClearance-taxQuery-config"
  }, {
    path: "/:site?/:lang?/support/customsClearance/taxQuery/configMI",
    component: _61cc4edb,
    name: "site-lang-support-customsClearance-taxQuery-configMI"
  }, {
    path: "/:site?/:lang?/support/customsClearance/taxQuery/detail",
    component: _334b57b2,
    name: "site-lang-support-customsClearance-taxQuery-detail"
  }, {
    path: "/:site?/:lang?/support/customsClearance/taxQuery/taxs",
    component: _08bd05fe,
    name: "site-lang-support-customsClearance-taxQuery-taxs"
  }, {
    path: "/:site?/:lang?/support/customsClearance/taxQuery/taxsMI",
    component: _0040bfcc,
    name: "site-lang-support-customsClearance-taxQuery-taxsMI"
  }, {
    path: "/:site?/:lang?/support/querySupport/areaCascade/tc",
    component: _7671b1f6,
    name: "site-lang-support-querySupport-areaCascade-tc"
  }, {
    path: "/:site?/:lang?/support/querySupport/exce_rate/mixins",
    component: _42c0a716,
    name: "site-lang-support-querySupport-exce_rate-mixins"
  }, {
    path: "/:site?/:lang?/support/querySupport/invoiceRequest/form",
    component: _09dd507c,
    name: "site-lang-support-querySupport-invoiceRequest-form"
  }, {
    path: "/:site?/:lang?/support/querySupport/invoiceRequest/result",
    component: _c4fa85ca,
    name: "site-lang-support-querySupport-invoiceRequest-result"
  }, {
    path: "/:site?/:lang?/support/querySupport/areaCascade/utils/main",
    component: _717b6ef1,
    name: "site-lang-support-querySupport-areaCascade-utils-main"
  }, {
    path: "/:site?/:lang?/support/querySupport/holiday/component/bottom-modal",
    component: _44bba55a,
    name: "site-lang-support-querySupport-holiday-component-bottom-modal"
  }, {
    path: "/:site?/:lang?/support/querySupport/holiday/component/select-country",
    component: _261f28e6,
    name: "site-lang-support-querySupport-holiday-component-select-country"
  }, {
    path: "/:site?/:lang?/support/querySupport/waybill/mobile/result",
    component: _c0c02e7c,
    name: "site-lang-support-querySupport-waybill-mobile-result"
  }, {
    path: "/:site?/:lang?/support/querySupport/waybill/mobile/samsung",
    component: _b8c69ab6,
    name: "site-lang-support-querySupport-waybill-mobile-samsung"
  }, {
    path: "/:site?/:lang?/support/querySupport/waybill/mobile/samsungReceiver",
    component: _21b90434,
    name: "site-lang-support-querySupport-waybill-mobile-samsungReceiver"
  }, {
    path: "/:site?/:lang?/support/querySupport/waybill/mobile/sign",
    component: _2f5424bc,
    name: "site-lang-support-querySupport-waybill-mobile-sign"
  }, {
    path: "/:site?/:lang?/support/querySupport/waybill/mobile/signDelivery",
    component: _083ecc94,
    name: "site-lang-support-querySupport-waybill-mobile-signDelivery"
  }, {
    path: "/:site?/:lang?/detail/:id",
    component: _79d1a2d5,
    name: "site-lang-detail-id"
  }, {
    path: "/:site?/:lang?/product/:detail",
    component: _5ef49772,
    name: "site-lang-product-detail"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
