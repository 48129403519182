/*
 * @Author: liyang
 * @Date: 2021-03-19 16:27:28
 * @LastEditTime: 2024-01-15 11:25:28
 * @LastEditors: Please set LastEditors
 * @FilePath: \ibu-owt-core-frontend\plugins\i18n.js
 * @Description:
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
if (!Vue.useI18n) {
  Vue.useI18n = true
  Vue.use(VueI18n)
}

export default ({ app, store }) => {
  app.i18n = new VueI18n({
    locale: store.state.locale,
    fallbackLocale: 'zh-CN', // 默认语言为中文
    missing: (locale, key, vm) => {
      // 在线申报missing特殊处理,解决关务没有国际化配置的问题
      if (key.includes('serviceSupport.clearanceService.apply.')) {
        const lastIndex = key.lastIndexOf('.') + 1
        return key.substring(lastIndex)
      }
      return key
    },
    messages: {
      'en-US': require('@/locales/en-US.json'),
      'zh-CN': require('@/locales/zh-CN.json'),
      // 香港地区
      'zh-HK': require('@/locales/hk/zh-HK.json'),
      'en-HK': require('@/locales/hk/en-HK.json'),
      'zh-CN-HK': require('@/locales/hk/zh-CN-HK.json'), // 香港站点下的中文简体
      // 泰国
      'th-TH': require('@/locales/th-TH.json'),
      // 台湾地区
      'zh-TW': require('@/locales/tw/zh-TW.json'),
      'zh-CN-TW': require('@/locales/tw/zh-CN-TW.json'),
      'en-TW': require('@/locales/tw/en-TW.json'),
      // 越南
      'vi-VN': require('@/locales/vi-VN.json'),
      // 日本
      'ja-JP': require('@/locales/ja-JP.json'),
    },
  })
}
