
import { mapState } from 'vuex'
import config from '@/modules/config/header'
import { LoginTopClick, RegisterTopClick } from '@/reporter/track'
// import { getCookie } from '@/utils/auth'
// 页面常量-消除魔术字符串-可抽取放到公共变量
const LoginBase = {
  modify: 'modify',
  cancell: 'cancell',
  exit: 'exit',
}

export default {
  data() {
    return {
      iuopLogin: config[this.$route.params.site].loginLink,
      iuopRegister: config[this.$route.params.site].registerLink,
      trackMap: {
        login: LoginTopClick,
        register: RegisterTopClick,
      },
    }
  },
  computed: {
    ...mapState({
      isHome: (state) => state.isHome,
      isHdFtMobile: (state) => state.isHdFtMobile,
      locale: (state) => state.locale,
      loginUser: (state) => state.loginUser,
      loginToken: (state) => state.loginToken,
    }),
    homeAndPC() {
      // 首页 + 非移动端
      // 首页 + 移动端
      return this.isHome && !this.isHdFtMobile
    },
    isLogin() {
      return !!this.loginUser
    },
  },
  mounted() {},
  methods: {
    // 退出操作
    async loginOut() {
      // 请求后台接口
      const res = await this.$API.common.loginOutApi({
        token: this.loginToken,
      })
      if (res === 'ok') {
        // 复原登录状态
        this.$store.commit('resetLogin')
        this.$router.go(0)
      }
    },
    track(name) {
      this.trackFn(name)
    },
    trackFn(name, properties) {
      this.trackMap[name](properties)
    },
    // 登录之后操作
    loginHandle(type) {
      switch (type) {
        //  修改密码
        case LoginBase.modify:
          break
        //  注销账户
        case LoginBase.cancell:
          break
        //   退出-退出和登录操作需要统一封装处理，暂未加
        case LoginBase.exit:
          this.loginOut()
          break
        default:
          break
      }
    },
  },
}
