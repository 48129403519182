import Vue from 'vue'
import Message from './message.vue'

const messageConstructor = (opts) => {
  let messageInstance = null
  const Constructor1 = Vue.extend(Message)
  messageInstance = new Constructor1({
    propsData: opts,
  }).$mount()
  document.body.appendChild(messageInstance.$el)
  return messageInstance
}

const messageCaller = (opts) => {
  const messageInstance = messageConstructor(opts)
  const timer = setTimeout(() => {
    clearTimeout(timer)
    document.body.removeChild(messageInstance.$el)
  }, opts.duration || 3000)
}

export default {
  install(vue) {
    vue.prototype.$ctMessage = messageCaller
  },
}
