/*
 * @Author: liyang
 * @Date: 2021-03-17 16:19:05
 * @FilePath: \ibu-owt-core-frontend\plugins\axios.js
 * @Description:
 */

import { message } from 'ant-design-vue'
import { Toast } from 'vant'
import { ServiceCode, isRedirectCode } from '@/utils/httpCode'
import { LangEnum } from '@/utils/constBase'
import sign from '@/utils/sign'

// https://axios.nuxtjs.org/
export default async function (context) {
  const {
    $axios,
    redirect,
    store,
    // params: { lang },
    // 是否是预览，在参数中获取
    query: { preview },
    $winstonLog,
    app,
  } = context

  if (process.client) {
    await sign($axios)
  }

  const prompt = (detailMessage) => {
    if (process.client) {
      if (store.state.resizeInfo.isMobile) {
        Toast(detailMessage)
      } else {
        message.error(detailMessage)
      }
    }
  }

  // const getUrlPrefix = (req) => {
  //   let urlPrefix = ''
  //   if (process.server) {
  //     const protocol = req?.headers?.referer?.match(/^http[s]?:\/\//)[0]
  //     const host = req?.headers?.host
  //     urlPrefix = `${protocol}${host}`
  //   } else {
  //     urlPrefix = window?.location?.origin
  //   }
  //   return urlPrefix
  // }

  $axios.onRequest((config) => {
    // eslint-disable-next-line no-console
    // if (process.server) {
    //   console.log('onRequest host', req.headers)
    //   config.headers.host = req.headers.host
    // }
  })

  $axios.interceptors.request.use(function (config) {
    // console.log('request', store.state.locale, store.state.site)
    // eslint-disable-next-line no-console
    // console.log('interceptors', config.url, config)
    // 语言,允许自定义
    const { lang, ...others } = config.headers
    let defaultLang = LangEnum[store.state.locale]?.code
    defaultLang = defaultLang === 'tc' ? 'sc' : defaultLang
    const curLang = config.headers.lang ? config.headers.lang : defaultLang
    // 站点
    const site = config.headers.site ? config.headers.site : store.state.site
    // const urlPrefix = getUrlPrefix(req)
    // 页面场景值 也可以从当前路由中获取，接口中不用特意传 待加上
    // console.log(
    //   Object.assign(config, {
    //     headers: {
    //       lang: curLang,
    //       region: site,
    //       preview: !!preview,
    //       ...others,
    //     },
    //   })
    // )
    return Object.assign(config, {
      headers: {
        lang: curLang,
        region: site,
        preview: !!preview,
        ...others,
      },
      // timeout: 10000

      // url: `${urlPrefix}${config.url}`,
      // baseURL: urlPrefix,
    })
  })

  $axios.onResponse((response) => {
    try {
      $winstonLog?.info(`[${response?.status}] ${response?.request?.path}`)

      const { config, status } = response
      // 成功
      if (status === ServiceCode.sc_200.code) {
        const { code, result, detailMessage, message: msg } = response.data
        if (code === ServiceCode.sc_0.code) {
          // 是否需要原样返回数据
          if (config.backAll) {
            return response.data
          }
          return {
            result,
          }
        } else if (isRedirectCode(code)) {
          // 在线申报场景，token失效
          return response.data
        } else if (detailMessage && !config.noErrorMsg) {
          // 如果noErrorMsg为true,不走公共异常提示逻辑
          prompt(detailMessage)
        } else if (config.responseType !== 'blob' && !config.noErrorMsg) {
          // 请求状态错误，统一处理
          if (
            code === ServiceCode.sc_0.code &&
            !result &&
            !detailMessage &&
            !msg
          ) {
            prompt(msg || app.i18n.t('common.http.dataError'))
          } else if (msg) {
            prompt(msg || app.i18n.t('common.http.networkError'))
          }
        }
      }
    } catch (e) {
      // console.log('onResponse error', e)
    }
  })

  $axios.onError((error) => {
    $winstonLog?.error(
      `[${error?.status}] | ${error?.request?.path} | ${error?.message}`
    )
    $winstonLog?.error(error?.response?.data)

    const code = parseInt(error.response && error.response.status)

    if (/^timeout of [0-9a-z]+ exceeded$/.test(error.message)) {
      prompt(app.i18n.t('common.http.networkError'))
    } else if (/^4|5[0-9]{2}$/.test(code)) {
      prompt(app.i18n.t('common.http.httpError', { status: code }))
    } else {
      prompt(app.i18n.t('common.http.unknownError'))
    }

    if (code === 400) {
      redirect('/400')
    }
  })
}
