
import { mapState } from 'vuex'
import Search from './search.vue'
import { RoutesList } from '@/router/jp/index.js'
import { LoginTopClick, RegisterTopClick } from '@/reporter/track'

export default {
  components: {
    Search,
  },
  data() {
    return {
      unfold: false,
      configList: RoutesList,
      specialKeys: ['contactUs'],
      userInfoList: ['login', 'register'],
    }
  },
  computed: {
    ...mapState({
      loginUser: (state) => state.loginUser,
      loginToken: (state) => state.loginToken,
    }),
    routeName() {
      return this.$route.name
    },
    activeName() {
      return this.routeName?.split('-')?.[2] || ''
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setMenuStyle()
    })
  },
  methods: {
    setMenuStyle() {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        if (this.$refs.menu) {
          const width = this.$refs.menu?.offsetWidth
          this.$refs.menu.style = `width: ${width}px`
        }
      }, 0)
    },
    onExpose(unfold) {
      this.unfold = unfold
    },
    onDropout() {
      this.$API.common
        .loginOutApi({
          token: this.loginToken,
        })
        .then((res) => {
          if (res === 'ok') {
            this.$store.commit('resetLogin')
            this.$router.go(0)
          }
        })
    },
    onTrack(name) {
      if (name === 'login') {
        LoginTopClick()
      } else if (name === 'register') {
        RegisterTopClick()
      }
    },
    setSubMenuText(subMenu) {
      if (this.loginUser && this.userInfoList.includes(subMenu.name)) {
        if (subMenu.name === this.userInfoList[0]) {
          return this.loginUser
        } else {
          return (
            <div onClick={this.onDropout}>
              {this.$t('common.login.dropout')}
            </div>
          )
        }
      }
      return (
        <LinkTo
          path={subMenu.path}
          gjUtm={subMenu.gjUtm}
          target={subMenu.name !== 'coop' ? '_self' : ''}
          onClick={() => this.onTrack(subMenu.name)}
        >
          {this.$t(subMenu.il8nName)}
        </LinkTo>
      )
    },
    renderChildren(name, children) {
      return (
        <div class="menu-item-children">
          {children.map((it) =>
            it.hidden?.menu !== false ? (
              <div key={name + it.name} class="menu-item-children-item">
                {!(it.children && it.children.length) ? (
                  <div>{this.setSubMenuText(it)}</div>
                ) : (
                  <div class="menu-item-word">
                    <span>{this.$t(it.il8nName)}</span>
                    <svg-icon icon-name="chevron-right" class="icon"></svg-icon>
                    <div class="menu-item-children-item-content">
                      {this.renderChildren(it.name, it.children)}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )
          )}
        </div>
      )
    },
  },
  render() {
    return (
      <div class={['custom-menu-pc', 'menu-content']}>
        <div
          ref="menu"
          class={['menu-itmes', this.unfold ? 'menu-expose' : '']}
        >
          {this.configList.map((it) => (
            <div
              key={it.name}
              class={[
                'menu-item',
                this.specialKeys.includes(it.name) ? 'menu-special' : '',
                this.activeName === it.name ? 'current' : '',
              ]}
            >
              <div class="menu-item-content">
                {!(it.children && it.children.length) ? (
                  <LinkTo
                    path={it.path}
                    gjUtm={it.gjUtm}
                    target={it.name !== 'coop' ? '_self' : ''}
                  >
                    {this.$t(it.il8nName)}
                  </LinkTo>
                ) : (
                  <div class="menu-item-word">
                    <span>{this.$t(it.il8nName)}</span>
                    <svg-icon icon-name="arrow-down" class="icon"></svg-icon>
                    <div class="menu-children-container">
                      {this.renderChildren(it.name, it.children)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div class={['menu-item', 'menu-search', this.unfold ? 'expose' : '']}>
          <Search onExpose={this.onExpose} />
        </div>
      </div>
    )
  },
}
