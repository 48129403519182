
import { mapState } from 'vuex'
import LOCALE from '@/utils/locale'
import { LangEnum } from '@/utils/constBase'
import { isHiddenHeaderFn } from '@/utils/utils'
import Header from '@/modules/templates/jp/header/index.vue'
import Footer from '@/modules/templates/jp/footer/index.vue'

export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      isAgree: (state) => state.cookie_agree.agree,
      isHdFtMobile: (state) => state.isHdFtMobile,
    }),
    // 设置全局antd 语言
    configLocale() {
      return LOCALE[LangEnum[this.locale].code]
    },
    query() {
      return this.$route.query
    },
    isHiddenHeader() {
      return isHiddenHeaderFn(this.$route, this.isMobile)
    },
  },
  created() {
    this.getLogisticsService()
  },
  methods: {
    // 获取物流服务菜单
    getLogisticsService() {
      this.$API.product.getLogisticsServiceMenuApi().then((res) => {
        // 物流产品 ，增值服务 前端设置遍历顺序
        const sortArr = ['product', 'increment']
        const productMenus = []
        const temp = res?.result || {}
        sortArr.forEach((key) => {
          let item = {}
          if (temp[key]) {
            item = { ...temp[key] }
            item.cname = temp[key].name
            item.path = `/product`
            item.name = key
            item.il8nName = ''
            item.hasNext = key === 'product' // 物流产品有4级菜单
            item.children = this.doMenuData(temp[key].children, key)
            item.params = { hash: key }
            productMenus.push({ ...item })
          }
        })
        this.$store.commit('addProductMenu', productMenus)
      })
    },
    doMenuData(children = [], rootCode) {
      return (
        children?.map((i) => {
          const item = {}
          item.cname = i.name
          item.path = i.code ? `/product/${i.code}` : ''
          item.name = i.code || i.name
          item.il8nName = ''
          i.children && (item.children = this.doMenuData(i.children, rootCode))
          item.params = i.code ? { query: { isProduct: rootCode } } : {}
          item.aliasName = i.aliasName
          return item
        }) || []
      )
    },
  }
}
