/*
 * @Author: liyang
 * @Date: 2021-11-05 14:53:24
 * @LastEditTime: 2021-11-08 11:35:05
 * @LastEditors: Do not edit
 * @FilePath: \nginx_configg:\test_project\other\globalweb\utils\locale.js
 * @Description:
 */

import en from 'ant-design-vue/lib/locale/en_US'
import sc from 'ant-design-vue/lib/locale/zh_CN'
import th from 'ant-design-vue/lib/locale/th_TH'
import vi from 'ant-design-vue/lib/locale/vi_VN'
import ja from 'ant-design-vue/lib/locale/ja_JP'
export default {
  en,
  sc,
  th,
  vi,
  ja
}
