/*
 * @Author: liyang
 * @Date: 2021-11-09 14:54:41
 * @LastEditTime: 2023-06-28 15:33:01
 * @LastEditors: yuanxing(01407381)
 * @FilePath: \ibu-owt-core-frontend\store\support.js
 * @Description: 服务支持公用数据
 */
import { pinyin } from 'pinyin-pro'

export const state = () => ({
  allCountryList: [], // 全量国家
  postCountryList: [],
  fileTypeOptions: [], // 文件下载所有类型
  waybillParams: null, // 查运单的参数-for 移动端
  waybillRouteDataArr: [], // 查运单的结果-for 移动端
  waybillRouteDataArrBackup: [],
  initTimeZone: '', // 默认时区
  tagList: [], // 收寄标准查询
  freightTimeTip: {
    name: '', // title
    content: '', // 内容
  }, // 运费时效查询说明
  shippingStandardTip: {
    name: '', // title
    content: '', // 内容
  }, // 收寄标准查询说明
  // 【地区级联组件】
  doubleAreaForm: {
    origin: {}, // 起始地
    dest: {}, // 目的地
  },
  privacyPolicy: '', // 隐私政策
  applyPhoneCodeOptions: [], // 手机区号列表
  applyFormTemplate: [], // 申报详情模版数据
  applyFormData: {},
  applyGoodsTemplate: [],
  applyGoodsData: [],
  applyCanEditfields: [],
  applyInitData: {},
})

export const mutations = {
  SET_ALL_COUNTRY_LIST(state, view) {
    state.allCountryList = view
  },
  SET_POST_COUNTRY_LIST(state, view) {
    state.postCountryList = view
  },
  SET_FILE_TYPE_OPTIONS(state, view) {
    state.fileTypeOptions = view
  },
  // 【查运单】保存查运单参数
  SET_WAWBILL_PARAMS(state, view) {
    state.waybillParams = view
  },
  // 【查运单】保存查运单参数
  SET_WAWBILL_RESULT(state, view) {
    state.waybillRouteDataArr = view
  },
  // 【查运单】保存查运单参数
  SET_WAWBILL_RESULT_BACKUP(state, view) {
    state.waybillRouteDataArrBackup = view
  },
  // 【收寄标准查询】保存默认时区
  SET_INIT_TIME_ZONE(state, view) {
    state.initTimeZone = view
  },
  // 【收寄标准查询】保存推荐品名
  SET_TAG_LIST(state, view) {
    state.tagList = view
  },
  // 保存运费时效查询说明
  SET_FREIGHT_TIME_TIP(state, view) {
    state.freightTimeTip = view
  },
  // 保存运费时效查询说明
  SET_SHIPPING_STANDARD_TIP(state, view) {
    state.shippingStandardTip = view
  },
  // 【地区级联组件】设置起始地或者目的地值
  SET_DOUBLE_AREA_FORM(state, view) {
    state.doubleAreaForm[view.name] = view.value
  },
  // 【地区级联组件】清空起始地/目的地值
  CLEAR_DOUBLE_AREA_FORM(state) {
    state.doubleAreaForm = {
      origin: {},
      dest: {},
    }
  },
  // 【隐私政策】保存隐私政策
  SET_PRIVACY_POLICY(state, view) {
    state.privacyPolicy = view
  },
  // 【在线申报】保存手机区号
  SET_APPLY_PHONE_CODE_OPTIONS(state, view) {
    state.applyPhoneCodeOptions = view
  },
  // 申报详情模版数据
  SET_APPLY_FORM_TEMPLATE(state, template) {
    state.applyFormTemplate = template
  },
  // 申报详情表单数据
  SET_APPLY_FORM_DATA(state, data) {
    state.applyFormData = data
  },
  // 申报详情商品模版数据
  SET_APPLY_GOODS_TEMPLATE(state, template) {
    state.applyGoodsTemplate = template
  },
  // 申报详情商品数据
  SET_APPLY_GOODS_DATA(state, data) {
    state.applyGoodsData = data
  },
  // 申报详情可编辑字段
  SET_APPLY_CAN_EDIT_FIELDS(state, data) {
    state.applyCanEditfields = data
  },
  SET_APPLY_INIT_DADA(state, data) {
    state.applyInitData = data
  },
}

export const actions = {
  // 获取全量国家列表
  async getCountryList(
    { state, commit },
    { lang, respTranslate, callback, postCodeSwitch = false }
  ) {
    if (!postCodeSwitch && state.allCountryList.length !== 0) {
      if (callback) callback.call(this)
      return
    }
    if (postCodeSwitch && state.postCountryList.length !== 0) {
      if (callback) callback.call(this)
      return
    }
    const params = { respTranslate, postCodeSwitch }
    const res = await this.$API.common.getRateCountrysApi(params)
    res?.forEach((item) => {
      item.name = item.countryNameI18n
      // 提取首字母，涉及中文简体，繁体，英文
      item.sortName = pinyin(item.name, { toneType: 'none', type: 'string' })
        .slice(0, 1)
        .toUpperCase()

      item.type = `Zero_${item.countryCode}`
      item.level = 'Zero'
    })
    if (!postCodeSwitch) {
      commit('SET_ALL_COUNTRY_LIST', res)
    } else {
      commit('SET_POST_COUNTRY_LIST', res)
    }
    if (callback) callback.call(this)
  },
  // 【收寄标准查询】获取推荐品名
  async fetchPopularRecommandList({ commit }, params = {}) {
    const res = await this.$API.support.getPopularRecommandedList(params)
    const tagList =
      res && res.length ? res.map((i, index) => ({ id: index, name: i })) : []
    commit('SET_TAG_LIST', tagList)
  },
  // 【隐私政策】获取隐私政策
  async fetchCMSPrivacyPolicy({ commit, state }) {
    if (state.privacyPolicy) return
    const res = await this.$API.support.getTermsOfUse({ type: 2 })
    if (res?.length > 0) {
      commit('SET_PRIVACY_POLICY', res[0].content || '')
    }
  },
  // 【查收集标准】获取说明
  async fetchCMSTimeFreightTips({ commit }, config) {
    try {
      const res = await this.$API.support.getSupportContent({ type: 3 }, config) // 3表示运费时效查询
      if (res?.length > 0) {
        const { name = '', content = '' } = res[0]
        commit('SET_FREIGHT_TIME_TIP', { name, content })
      }
    } catch (e) {}
  },
  setApplyFormTemplate({ commit }, template) {
    commit('SET_APPLY_FORM_TEMPLATE', JSON.parse(JSON.stringify(template)))
  },
  setApplyFormData({ state, commit }, data) {
    const form = { ...state.applyFormData, ...data }
    commit('SET_APPLY_FORM_DATA', form)
  },
  setApplyGoodsTemplate({ commit }, template) {
    commit('SET_APPLY_GOODS_TEMPLATE', JSON.parse(JSON.stringify(template)))
  },
  setApplyGoodsList({ commit }, list) {
    commit('SET_APPLY_GOODS_DATA', JSON.parse(JSON.stringify(list)))
  },
  setApplGoodsData({ state, commit }, data) {
    const goods = state.applyGoodsData.concat(data)
    commit('SET_APPLY_GOODS_DATA', goods)
  },
  editApplGoodsData({ state, commit }, { uid, data }) {
    const index = state.applyGoodsData.findIndex((it) => it.uid === uid)
    const newList = state.applyGoodsData.concat()
    newList.splice(index, 1, data)
    commit('SET_APPLY_GOODS_DATA', newList)
  },
  delApplGoodsData({ state, commit }, uid) {
    const newList = state.applyGoodsData.filter((it) => it.uid !== uid)
    commit('SET_APPLY_GOODS_DATA', newList)
  },
  setApplyCanEditfields({ state, commit }, list) {
    commit('SET_APPLY_CAN_EDIT_FIELDS', list)
  },
  setApplyInitData({ state, commit }, data) {
    commit('SET_APPLY_INIT_DADA', data)
  }
}
