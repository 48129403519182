export const BackTop = () => import('../../components/BackTop.vue' /* webpackChunkName: "components/back-top" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../components/Empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const Identify = () => import('../../components/Identify.vue' /* webpackChunkName: "components/identify" */).then(c => wrapFunctional(c.default || c))
export const Imglazy = () => import('../../components/Imglazy.vue' /* webpackChunkName: "components/imglazy" */).then(c => wrapFunctional(c.default || c))
export const LinkTo = () => import('../../components/LinkTo.vue' /* webpackChunkName: "components/link-to" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const OssImg = () => import('../../components/OssImg.vue' /* webpackChunkName: "components/oss-img" */).then(c => wrapFunctional(c.default || c))
export const SetLang = () => import('../../components/SetLang.vue' /* webpackChunkName: "components/set-lang" */).then(c => wrapFunctional(c.default || c))
export const SvgIcon = () => import('../../components/SvgIcon.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c))
export const VerificationCode = () => import('../../components/verificationCode.vue' /* webpackChunkName: "components/verification-code" */).then(c => wrapFunctional(c.default || c))
export const Animation = () => import('../../components/animation/index.vue' /* webpackChunkName: "components/animation" */).then(c => wrapFunctional(c.default || c))
export const AnimationInit = () => import('../../components/animation/init.vue' /* webpackChunkName: "components/animation-init" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/button/index.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CascadeInputMb = () => import('../../components/cascade/input-mb.vue' /* webpackChunkName: "components/cascade-input-mb" */).then(c => wrapFunctional(c.default || c))
export const CascadeInput = () => import('../../components/cascade/input.vue' /* webpackChunkName: "components/cascade-input" */).then(c => wrapFunctional(c.default || c))
export const CascadeRadio = () => import('../../components/cascade/radio.vue' /* webpackChunkName: "components/cascade-radio" */).then(c => wrapFunctional(c.default || c))
export const CascadeSelect = () => import('../../components/cascade/select.vue' /* webpackChunkName: "components/cascade-select" */).then(c => wrapFunctional(c.default || c))
export const CarouselBase64 = () => import('../../components/carousel/base64.vue' /* webpackChunkName: "components/carousel-base64" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel/index.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const ClassDetailAddsidebar = () => import('../../components/classDetail/addsidebar.vue' /* webpackChunkName: "components/class-detail-addsidebar" */).then(c => wrapFunctional(c.default || c))
export const ClassDetailBanner = () => import('../../components/classDetail/banner.vue' /* webpackChunkName: "components/class-detail-banner" */).then(c => wrapFunctional(c.default || c))
export const ClassDetailSidebar = () => import('../../components/classDetail/sidebar.vue' /* webpackChunkName: "components/class-detail-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CommonHeadBanner = () => import('../../components/common/HeadBanner.vue' /* webpackChunkName: "components/common-head-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonNavTitle = () => import('../../components/common/NavTitle.vue' /* webpackChunkName: "components/common-nav-title" */).then(c => wrapFunctional(c.default || c))
export const CommonQRCodeDialog = () => import('../../components/common/QRCodeDialog.vue' /* webpackChunkName: "components/common-q-r-code-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonAreaCascadeEnhance = () => import('../../components/common/areaCascadeEnhance.vue' /* webpackChunkName: "components/common-area-cascade-enhance" */).then(c => wrapFunctional(c.default || c))
export const CommonAreaCascadeEnhanceNew = () => import('../../components/common/areaCascadeEnhanceNew.vue' /* webpackChunkName: "components/common-area-cascade-enhance-new" */).then(c => wrapFunctional(c.default || c))
export const CommonAreaCascadeEnhanceTc = () => import('../../components/common/areaCascadeEnhanceTc.vue' /* webpackChunkName: "components/common-area-cascade-enhance-tc" */).then(c => wrapFunctional(c.default || c))
export const CommonCookiePanel = () => import('../../components/common/cookiePanel.vue' /* webpackChunkName: "components/common-cookie-panel" */).then(c => wrapFunctional(c.default || c))
export const CommonCookieSetting = () => import('../../components/common/cookieSetting.vue' /* webpackChunkName: "components/common-cookie-setting" */).then(c => wrapFunctional(c.default || c))
export const CommonCountryIcon = () => import('../../components/common/countryIcon.vue' /* webpackChunkName: "components/common-country-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonFooter = () => import('../../components/common/footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonFooterMobile = () => import('../../components/common/footerMobile.vue' /* webpackChunkName: "components/common-footer-mobile" */).then(c => wrapFunctional(c.default || c))
export const CommonHeader = () => import('../../components/common/header.vue' /* webpackChunkName: "components/common-header" */).then(c => wrapFunctional(c.default || c))
export const CommonLogin = () => import('../../components/common/login.vue' /* webpackChunkName: "components/common-login" */).then(c => wrapFunctional(c.default || c))
export const CommonRightNav = () => import('../../components/common/rightNav.vue' /* webpackChunkName: "components/common-right-nav" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/datePicker/index.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DatePickerRange = () => import('../../components/datePicker/range.vue' /* webpackChunkName: "components/date-picker-range" */).then(c => wrapFunctional(c.default || c))
export const DialogInfo = () => import('../../components/dialog/info.vue' /* webpackChunkName: "components/dialog-info" */).then(c => wrapFunctional(c.default || c))
export const Feedback = () => import('../../components/feedback/index.vue' /* webpackChunkName: "components/feedback" */).then(c => wrapFunctional(c.default || c))
export const HomeBottom = () => import('../../components/home/bottom.vue' /* webpackChunkName: "components/home-bottom" */).then(c => wrapFunctional(c.default || c))
export const HomeSearch = () => import('../../components/home/search.vue' /* webpackChunkName: "components/home-search" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/icon/index.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const ImgBase64 = () => import('../../components/img/base64.vue' /* webpackChunkName: "components/img-base64" */).then(c => wrapFunctional(c.default || c))
export const ImgView = () => import('../../components/img/view.vue' /* webpackChunkName: "components/img-view" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/input/index.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const LandingFooter = () => import('../../components/landing/footer.vue' /* webpackChunkName: "components/landing-footer" */).then(c => wrapFunctional(c.default || c))
export const LandingHeader = () => import('../../components/landing/header.vue' /* webpackChunkName: "components/landing-header" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/map/index.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const MessageAlert = () => import('../../components/message/alert.vue' /* webpackChunkName: "components/message-alert" */).then(c => wrapFunctional(c.default || c))
export const MessageIcon = () => import('../../components/message/icon.vue' /* webpackChunkName: "components/message-icon" */).then(c => wrapFunctional(c.default || c))
export const Message = () => import('../../components/message/index.js' /* webpackChunkName: "components/message" */).then(c => wrapFunctional(c.default || c))
export const MobileMiddlePopup = () => import('../../components/mobile/MiddlePopup.vue' /* webpackChunkName: "components/mobile-middle-popup" */).then(c => wrapFunctional(c.default || c))
export const MobileBottom = () => import('../../components/mobile/bottom.vue' /* webpackChunkName: "components/mobile-bottom" */).then(c => wrapFunctional(c.default || c))
export const MobileBottomWithSubmit = () => import('../../components/mobile/bottomWithSubmit.vue' /* webpackChunkName: "components/mobile-bottom-with-submit" */).then(c => wrapFunctional(c.default || c))
export const MobileDrag = () => import('../../components/mobile/drag.vue' /* webpackChunkName: "components/mobile-drag" */).then(c => wrapFunctional(c.default || c))
export const MobileDrawer = () => import('../../components/mobile/drawer.vue' /* webpackChunkName: "components/mobile-drawer" */).then(c => wrapFunctional(c.default || c))
export const MobileField = () => import('../../components/mobile/field.vue' /* webpackChunkName: "components/mobile-field" */).then(c => wrapFunctional(c.default || c))
export const MobileIcon = () => import('../../components/mobile/icon.vue' /* webpackChunkName: "components/mobile-icon" */).then(c => wrapFunctional(c.default || c))
export const MobileNoData = () => import('../../components/mobile/noData.vue' /* webpackChunkName: "components/mobile-no-data" */).then(c => wrapFunctional(c.default || c))
export const MobileRightNav = () => import('../../components/mobile/rightNav.vue' /* webpackChunkName: "components/mobile-right-nav" */).then(c => wrapFunctional(c.default || c))
export const MobileSearchBottom = () => import('../../components/mobile/searchBottom.vue' /* webpackChunkName: "components/mobile-search-bottom" */).then(c => wrapFunctional(c.default || c))
export const MobileSelect = () => import('../../components/mobile/select.vue' /* webpackChunkName: "components/mobile-select" */).then(c => wrapFunctional(c.default || c))
export const OfficeViewDoc = () => import('../../components/officeView/doc.vue' /* webpackChunkName: "components/office-view-doc" */).then(c => wrapFunctional(c.default || c))
export const OfficeViewImg = () => import('../../components/officeView/img.vue' /* webpackChunkName: "components/office-view-img" */).then(c => wrapFunctional(c.default || c))
export const OfficeView = () => import('../../components/officeView/index.vue' /* webpackChunkName: "components/office-view" */).then(c => wrapFunctional(c.default || c))
export const OfficeViewPdf = () => import('../../components/officeView/pdf.vue' /* webpackChunkName: "components/office-view-pdf" */).then(c => wrapFunctional(c.default || c))
export const OfficeViewXlsx = () => import('../../components/officeView/xlsx.vue' /* webpackChunkName: "components/office-view-xlsx" */).then(c => wrapFunctional(c.default || c))
export const PageSupportNav = () => import('../../components/page/support-nav.vue' /* webpackChunkName: "components/page-support-nav" */).then(c => wrapFunctional(c.default || c))
export const PageSupportTips = () => import('../../components/page/support-tips.vue' /* webpackChunkName: "components/page-support-tips" */).then(c => wrapFunctional(c.default || c))
export const PageSupportTop = () => import('../../components/page/support-top.vue' /* webpackChunkName: "components/page-support-top" */).then(c => wrapFunctional(c.default || c))
export const PageTermsNav = () => import('../../components/page/terms-nav.vue' /* webpackChunkName: "components/page-terms-nav" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination/index.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PhoneCode = () => import('../../components/phoneCode/index.vue' /* webpackChunkName: "components/phone-code" */).then(c => wrapFunctional(c.default || c))
export const PhoneEmail = () => import('../../components/phoneEmail/index.vue' /* webpackChunkName: "components/phone-email" */).then(c => wrapFunctional(c.default || c))
export const PhoneEmailMo = () => import('../../components/phoneEmail/mo.vue' /* webpackChunkName: "components/phone-email-mo" */).then(c => wrapFunctional(c.default || c))
export const PhoneEmailPc = () => import('../../components/phoneEmail/pc.vue' /* webpackChunkName: "components/phone-email-pc" */).then(c => wrapFunctional(c.default || c))
export const PhoneVerify = () => import('../../components/phoneVerify/index.vue' /* webpackChunkName: "components/phone-verify" */).then(c => wrapFunctional(c.default || c))
export const Popover = () => import('../../components/popover/index.vue' /* webpackChunkName: "components/popover" */).then(c => wrapFunctional(c.default || c))
export const PreviewImage = () => import('../../components/preview/image.vue' /* webpackChunkName: "components/preview-image" */).then(c => wrapFunctional(c.default || c))
export const Preview = () => import('../../components/preview/index.vue' /* webpackChunkName: "components/preview" */).then(c => wrapFunctional(c.default || c))
export const PreviewPcImage = () => import('../../components/preview/pc-image.vue' /* webpackChunkName: "components/preview-pc-image" */).then(c => wrapFunctional(c.default || c))
export const Radio = () => import('../../components/radio/index.vue' /* webpackChunkName: "components/radio" */).then(c => wrapFunctional(c.default || c))
export const RadioSlide = () => import('../../components/radio/slide.vue' /* webpackChunkName: "components/radio-slide" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/select/index.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const SelectIndexBar = () => import('../../components/select/indexBar.vue' /* webpackChunkName: "components/select-index-bar" */).then(c => wrapFunctional(c.default || c))
export const SelectMb = () => import('../../components/select/mb.vue' /* webpackChunkName: "components/select-mb" */).then(c => wrapFunctional(c.default || c))
export const SelectMbHttp = () => import('../../components/select/mbHttp.vue' /* webpackChunkName: "components/select-mb-http" */).then(c => wrapFunctional(c.default || c))
export const SelectPc = () => import('../../components/select/pc.vue' /* webpackChunkName: "components/select-pc" */).then(c => wrapFunctional(c.default || c))
export const SelectSearch = () => import('../../components/select/search.vue' /* webpackChunkName: "components/select-search" */).then(c => wrapFunctional(c.default || c))
export const SelectSearchHttp = () => import('../../components/select/searchHttp.vue' /* webpackChunkName: "components/select-search-http" */).then(c => wrapFunctional(c.default || c))
export const SelectSingleMb = () => import('../../components/select/single-mb.vue' /* webpackChunkName: "components/select-single-mb" */).then(c => wrapFunctional(c.default || c))
export const SelectSingle = () => import('../../components/select/single.vue' /* webpackChunkName: "components/select-single" */).then(c => wrapFunctional(c.default || c))
export const Sheet = () => import('../../components/sheet/index.vue' /* webpackChunkName: "components/sheet" */).then(c => wrapFunctional(c.default || c))
export const SheetMb = () => import('../../components/sheet/mb.vue' /* webpackChunkName: "components/sheet-mb" */).then(c => wrapFunctional(c.default || c))
export const SlideCircus = () => import('../../components/slideCircus/index.vue' /* webpackChunkName: "components/slide-circus" */).then(c => wrapFunctional(c.default || c))
export const TabsBasic = () => import('../../components/tabs/basic.vue' /* webpackChunkName: "components/tabs-basic" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/tabs/index.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/video/index.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const CommonMenuItem = () => import('../../components/common/menu/Item.vue' /* webpackChunkName: "components/common-menu-item" */).then(c => wrapFunctional(c.default || c))
export const CommonMenuInput = () => import('../../components/common/menu/input.vue' /* webpackChunkName: "components/common-menu-input" */).then(c => wrapFunctional(c.default || c))
export const CommonMenuMobile = () => import('../../components/common/menu/mobile.vue' /* webpackChunkName: "components/common-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const CommonMenuPc = () => import('../../components/common/menu/pc.vue' /* webpackChunkName: "components/common-menu-pc" */).then(c => wrapFunctional(c.default || c))
export const DialogMo = () => import('../../components/dialog/mo/index.vue' /* webpackChunkName: "components/dialog-mo" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
