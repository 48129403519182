/*
 * @Author: liyang
 * @Date: 2021-04-13 10:25:08
 * @LastEditTime: 2023-06-07 14:55:53
 * @LastEditors: yuanxing(01407381)
 * @FilePath: \ibu-owt-core-frontend\plugins\directive.js
 * @Description: 自定义指令集合
 */

import Vue from 'vue'

if (!Vue.useSearchFocus) {
  Vue.useSearchFocus = true
  // 首页-搜索运单
  Vue.directive('searchFocus', {
    bind(el) {
      const inputEle = el.querySelector('input.ant-input')
      // 文本域
      const textareaEleWrap = el.querySelector('.ant-input-prefix')
      const textareaEle = textareaEleWrap.querySelector('.search_more_input')
      // 搜索icon
      const searchIconWrap = el.querySelector('.ant-input-suffix')
      // input focus
      inputEle.addEventListener('focus', function () {
        el.querySelector('.ant-input-prefix').style.display = 'block'
        searchIconWrap.className = 'active ant-input-suffix'
        textareaEle.focus()
      })
      // textarea blur
      textareaEle.addEventListener('blur', function () {
        textareaEleWrap.style.display = 'none'
        searchIconWrap.className = 'ant-input-suffix'
      })
    },
  })
  // 禁止输入特殊字符
  Vue.directive('disable-special-char', {
    bind(el, binding, vnode) {
      // 正则规则可根据需求自定义
      //  /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
      const regRule =
        // eslint-disable-next-line
        /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/gim
      const $inp = findEle(el, 'input')
      el.$inp = $inp
      $inp.handle = function (event) {
        const val = $inp.value
        $inp.value = val.replace(regRule, '')
        // event.target.value = $inp.value
        // binding.value = $inp.value
        // this.value = $inp.value
        // console.info('disable-synmol $inp', $inp)
        // console.info('disable-synmol $inp.value ', $inp.value)
        // console.info('disable-synmol binding', binding)

        // 调用input事件使vue v-model绑定更新
        // $inp.dispatchEvent(new Event('input'))
        // event.target.dispatchEvent(new Event('input'))
        trigger($inp, 'input')
      }
      $inp.addEventListener('keyup', $inp.handle)
    },
    unbind(el) {
      el.$inp.removeEventListener('keyup', el.$inp.handle)
    },
  })
  const findEle = (parent, type) => {
    return parent.tagName.toLowerCase() === type
      ? parent
      : parent.querySelector(type)
  }

  const trigger = (el, type) => {
    const e = document.createEvent('HTMLEvents')
    e.initEvent(type, true, true)
    el.dispatchEvent(e) 
  }
}
