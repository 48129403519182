/*
 * @Author: 01394339
 * @Date: 2022-07-19 18:06:06
 * @LastEditors: Please set LastEditors
 * @Description:
 */

import urlParse from 'url-parse'
import CONFIG from './config'
import { getCookie } from './auth'
import { LangIuopBase, SFExpressSupportLangs } from './constBase'

/**
 * @description: 获取服务端 cookie信息
 * @param {*}
 * @return {*}
 */
export const getcookiesInServer = (req, props) => {
  const cookie = req?.headers?.cookie
  if (cookie) {
    const propsValue = getCookie(props, cookie)
    return propsValue
  }
}

// 组装链接地址

export const linkPath = (
  routeParams,
  path,
  params = { query: {}, hash: '' }
) => {
  if (process.client) {
    const origin = window.location.origin
    let siteLang = ''
    if (routeParams.site && routeParams.lang) {
      siteLang = `/${routeParams.site}/${routeParams.lang}`
    }
    const url = urlParse(`${origin}${siteLang}${path}`)
    url.set('query', params.query)
    url.set('hash', params.hash)
    return url.href
  }
}

// 路由list 转变为有path路径的enum
export const pathObj = (routerList = []) => {
  const len = routerList.length
  const obj = {}
  if (len > 0) {
    for (let i = 0; i < len; i++) {
      const curItem = routerList[i]
      const { children, ...rest } = curItem
      if (children && children.length > 0) {
        const childEnum = pathObj(children)
        Object.assign(obj, childEnum)
      }
      if (curItem.path) {
        obj[curItem.name] = rest
      }
    }
  }
  return obj
}

// 获取axios实例对象
export const getAxios = (axiosInstance) => {
  let realAxios = null
  if (process.client) {
    realAxios = axiosInstance || window?.$nuxt?.$axios
  } else {
    realAxios = axiosInstance
  }
  return realAxios
}

// 获取路径名称
export const getPathInfo = (routeInfo) => {
  try {
    const curPathList = routeInfo.name.split('-')
    let pathStr = curPathList.splice(2).join('/')
    // 如果是动态路由就会出现不匹配现象，需要单独处理
    if (!routeInfo.path.includes(pathStr)) {
      const path = routeInfo.path
      const suffix = path.substring(path.lastIndexOf('/')) // 获取实际最后的详情路由
      pathStr = pathStr.substring(0, pathStr.lastIndexOf('/')) // 获取实际前半截路由
      pathStr += suffix // 组装
    }
    pathStr = pathStr.match(/^\//) ? pathStr : `/${pathStr}`
    return {
      // 去除站点和语言之后的 路径
      pathStr,
    }
  } catch {
    // console.log('getPathInfo error')
    return {}
  }
}

// 是否为空对象
export const isEmptyObject = (obj = {}) => {
  if (!obj) return true
  const arr = Object.keys(obj)
  if (arr.length === 0) {
    return true
  } else {
    return false
  }
}

// 防抖
export const debounce = function (func, wait = 200, immediate = false) {
  let timeout, result

  const debounced = function () {
    const context = this
    const args = arguments
    if (timeout) clearTimeout(timeout)

    const later = function () {
      timeout = null
      if (!immediate) result = func.apply(context, args)
    }

    const callNow = immediate && !timeout
    timeout = setTimeout(later, wait)
    if (callNow) result = func.apply(this, args)

    return result
  }
  return debounced
}

// 节流
export const throttle = (func, delay) => {
  let previous = 0
  return function () {
    const now = Date.now()
    if (now - previous > delay) {
      func.apply(this, arguments)
      previous = now
    }
  }
}

// 未登录-IUOP 登录注册增加当前语言
export const urlEnhanceLang = (params = {}) => {
  const { lang, url, gjUtm } = params
  const sysLang = lang || CONFIG.defaultLang
  const curLang = LangIuopBase[sysLang]
  let iuopUrl = null
  if (url) {
    if (url.match('lang=')) {
      // 替换语言
      iuopUrl = url.replace(/(lang=)([\w-]*)(&?)/, `$1${curLang}$3`)
    } else {
      // 替换语言
      iuopUrl = url.replace(/(\?{1})(\w+)(&?)/, `$1lang=${curLang}&$2$3`)
    }
    // 国际渠道推广参数拼接
    if (gjUtm) {
      iuopUrl += `&gjUtm=${encodeURIComponent(gjUtm)}`
    }
  }

  return iuopUrl
}

// 根据当前语言环境切换到速运对应环境
export const changeSFExpressUrlByLang = (params = {}) => {
  const { lang, url } = params
  const sysLang = lang || CONFIG.defaultLang
  const curLang = SFExpressSupportLangs[sysLang]
  let realUrl = ''
  if (url) {
    realUrl = url.replace(/\/sc\//, `/${curLang}/`)
  }
  return realUrl
}

// 调整IUOP到对应语言
export const changeIuopUrlByLang = (params = {}) => {
  const { lang, url } = params
  const sysLang = lang || CONFIG.defaultLang
  const curLang = LangIuopBase[sysLang]
  let iuopUrl = ''
  if (url) {
    iuopUrl = url.replace(/(lang=)([\w-]*)(&?)/, `$1${curLang}$3`)
  }
  return iuopUrl
}

export const downloadImage = (imgsrc, name) => {
  // 下载图片地址和图片名
  const image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function () {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
    const a = document.createElement('a') // 生成一个a元素
    const event = new MouseEvent('click') // 创建一个单击事件
    a.download = name || 'photo' // 设置图片名称
    a.href = url // 将生成的URL设置为a.href属性
    a.dispatchEvent(event) // 触发a的单击事件
  }
  image.src = imgsrc
}

// 将base64转码 为tiff.js需要的格式
export const decodeBase64 = (code) => {
  const bString = window.atob(code)
  let len = bString.length
  const arr = new Uint8Array(len)
  while (len--) {
    arr[len] = bString.charCodeAt(len)
  }
  return arr
}

// base64 转file 格式
export function  base64ToFile(base64Data, filename, mime) {
  // 将base64的数据部分提取出来
  // const arr = base64Data.split(',');
  // const mime = arr[0].match(/:(.*?);/)[1];
  // const bstr = atob(arr[1]);
  const bstr = atob(base64Data);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // 将Uint8Array转换为Blob对象
  const blob = new Blob([u8arr], { type: mime });

  // 创建File对象
  const file = new File([blob], filename, { type: mime });

  return file;
}

// base64转blob
export const base64ToBlob = (code, suffix) => {
  const raw = window.atob(code)
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: suffix })
}
// base64 下载
export const downloadBase64 = (fileName, content) => {
  const link = document.createElement('a')
  const base64 = content.split(';base64,')
  const type = base64[0].split(':')[1]
  const blob = base64ToBlob(base64[1], type)
  const format = type.split('/')[1]
  if (fileName.includes(`.${format}`)) {
    link.download = fileName
  } else {
    link.download = `${fileName}.${format}`
  }
  link.href = URL.createObjectURL(blob)
  link.click()
}

// base64 下载 -short：比如去掉了常规头部
export const downloadBase64Short = (fileName, item) => {
  const suffix = item.imageType ? item.imageType : 'jpg'
  const aLink = document.createElement('a')
  const blob = base64ToBlob(item.imageBase64, suffix) // new Blob([content]);
  const evt = document.createEvent('HTMLEvents')
  evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
  aLink.download = fileName + '.' + suffix
  aLink.href = URL.createObjectURL(blob)
  aLink.click()
}



// 打印
export const printer = (dom) => {
  const printContent = dom.innerHTML
  const iframe = document.createElement('iframe')
  iframe.style = 'position: "absolute";width: 0; height: 0;'
  document.body.appendChild(iframe)
  const iframeDoc = iframe.contentWindow.document
  iframeDoc.write('<div>' + printContent + '</div>')
  const timer = setTimeout(function () {
    clearTimeout(timer)
    iframe.contentWindow.print()
    document.body.removeChild(iframe)
  }, 50)
}
/**
 * route 路由对象
 */
export const isHiddenHeaderFn = (route, isMobile) => {
  const { name, query } = route
  const { sysCode } = query
  // ICCSP，国际APP,小程序  ICCSP-MINIAPP细化为小程序,IBU_FENGHE_HTMCWS速运及港澳台渠道链接
  const sysCodes = [
    'CCSP', // 速运
    'ICCSP',
    'ICCSP-APP',
    'ICCSP-MINIAPP',
    'IBU_FENGHE_HTMCWS', // 港澳台
    'EOS-ICSM-CORE', // 速运公众号
    'EOS-MINIAPP', // 速运小程序
    'EOS-APP', // 速运APP
    'ICCSP-SFGO', // sfgo
    'IBU-IMS', // 国际咨询台
    'ICCSP-ZALO', // Zalo 小程序
    'EOS-TW', // 速运台湾
    'EOS-HK', // 速运香港
    'IBU-CIOS-CORE', // CIOS 深港半日达
  ]
  // 物流服务详情，收寄标准查询
  const names = [
    'site-lang-product-detail',
    'site-lang-support-querySupport-receiving_standard', // 查收寄标准
    'site-lang-support-querySupport-receiving_standard_tc',
    'site-lang-support-querySupport-areaCascade',
    'site-lang-support-querySupport-areaCascade-tc',
    'site-lang-support-querySupport-freight_aging',
    'site-lang-support-customsClearance-infromaton',
    'site-lang-support-querySupport-exce_rate', // 查汇率
    'site-lang-support-querySupport-holiday', // 节假日
    'site-lang-support-customsClearance-customsTaxFee', // 税种查询
    'site-lang-support-customsClearance-customsRequirements', // 目的国进口正式报关标准
    'site-lang-support-customsClearance-uploadPhotos', // 清关图片，CCSP
    'site-lang-about-detail', // 公告、新闻、推广专区详情
    'site-lang-support-customsClearance-taxQuery', // 支持-清关服务-进口税金
    'site-lang-support-querySupport-waybill', // 查运单
    'site-lang-support-querySupport-waybill-mobile-result', // 查运单结果展示
    'site-lang-support-customsClearance-taxQuery', // 税金查询
  ]
  // 国际app移动端嵌入官网页面，需要隐藏头部、底部
  if (sysCodes.includes(sysCode) && names.includes(name)) {
    return true
  } else if (
    name.startsWith('site-lang-support-customsClearance-apply') &&
    isMobile
  ) {
    // 申报下的页面移动端隐藏头部和nav
    return true
  } else {
    return false
  }
}

// 是否来自ICCSP(国际小程序/国际APP)
export const isFromIntlApp = (route) => {
  const { query } = route
  const { sysCode } = query
  const sysCodes = ['ICCSP', 'ICCSP-APP']
  if (sysCodes.includes(sysCode)) {
    return true
  }
  return false
}
// 是否来自ICCSP(国际小程序/国际APP)
export const isFromIntlMiniApp = (route) => {
  const { query } = route
  const { sysCode } = query
  const sysCodes = ['ICCSP-MINIAPP']
  if (sysCodes.includes(sysCode)) {
    return true
  }
  return false
}
export const compareAppVersion = (version, targetVersion) => {
  if (version && targetVersion) {
    try {
      const versionList = version.split('.')
      const targetList = targetVersion.split('.')
      const length = Math.max(versionList.length, targetList.length)
      let targetResult = ''
      let versionResult = ''
      for (let index = 0; index < length; index++) {
        if (targetList[index] && versionList[index]) {
          const targetNum = targetList[index]
          let versionNum = versionList[index]
          if (targetList[index].length > versionList[index].length) {
            const length = targetList[index].length - versionList[index].length
            for (let index = 0; index < length; index++) {
              versionNum = '0' + versionNum
            }
          } else if (targetList[index].length < versionList[index].length) {
            const length = versionList[index].length - targetList[index].length
            for (let index = 0; index < length; index++) {
              versionNum = '0' + targetNum
            }
          }
          targetResult += targetNum
          versionResult += versionNum
        } else if (targetList[index]) {
          const targetNum = targetList[index]
          let versionNum = ''
          for (let index = 0; index < targetList[index].length; index++) {
            versionNum += '0'
          }
          targetResult += targetNum
          versionResult += versionNum
        } else if (versionList[index]) {
          let targetNum = ''
          for (let index = 0; index < versionList[index].length; index++) {
            targetNum += '0'
          }
          const versionNum = versionList[index]
          targetResult += targetNum
          versionResult += versionNum
        }
      }
      console.log('versionResult', versionResult)
      console.log('targetResult', targetResult)
      if (parseInt(targetResult) > parseInt(versionResult)) {
        return -1
      } else if (parseInt(targetResult) < parseInt(versionResult)) {
        return 1
      } else {
        return 0
      }
    } catch (error) {
      console.log(error)
    }
  }
  return -1
}

export const addWeixinJs = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = 'https://res2.wx.qq.com/open/js/jweixin-1.6.0.js'
    script.type = 'text/javascript'
    document.body.appendChild(script)
    script.onload = () => {
      resolve()
    }
  })
}
// 检测是否支持webp
export const browserCheckWebp = () => {
  try {
    // !![].map 主要是判断浏览器是否为 IE9+ ，以免 toDataURL 方法会挂掉；
    // 如果你直接对数组原型扩展了 map 方法，则需要使用 !![].map 以外的方法进行判断 ，例如  !!window.addEventListener 等；
    return (
      !![].map &&
      document
        .createElement('canvas')
        .toDataURL('image/webp')
        .indexOf('data:image/webp') === 0
    )
  } catch (err) {
    return false
  }
}

// // 处理本地图片-转化为CDN上地址
// /**
//  * @description:
//  * @param {*} url 需要处理的地址
//  * @param {*} isSupportWebp 是否需要返回对应的Webp地址 默认false
//  * @return {*}
//  */
// export const requireCdnUrl = (url, isSupportWebp = false )=>{
//   let newUrl = null;
//   let src = url;
//   // base64不处理
//   if(url.match('data:image')){
//     return url
//   }
//   console.log('requireCdnUrl', url)
//   // 本地地址规则如 ~/assets/img/icons/wx.svg
//   // 需转化为 CONFIG.cdnUrl
//   if(url.match('assets')){
//     newUrl = url.split('assets')[1]
//     if(isSupportWebp){
//       newUrl = `${CONFIG.webpCdnUrl}${newUrl}`
//     }else{
//       newUrl = `${CONFIG.cdnUrl}${newUrl}`
//     }
//     return newUrl
//   }else if(url.match(/http(s)?:\/\//)){
//     // 主要是背景图 直接用的链接地址
//     if(src.match(CONFIG.cdnUrl)){
//         src = src.replace(CONFIG.cdnUrl, '')
//     }else{
//         // 其他网站源图片，先不做处理
//         newUrl = url
//         return newUrl
//     }
//   }

//   if(src.match(/^\//)){
//     // 相对路径进行处理
//     src = src.replace(/^\/?(.*)/g, '$1')
//   }

//   if(src.match(/jpg|png|jpeg/)){
//       let baseUrl = CONFIG.cdnUrl
//       if(isSupportWebp && !src.match(/\/webp\//)){
//           src = src.replace(/\.(jpg|png|jpeg)$/g, '.webp')
//           baseUrl = CONFIG.webpCdnUrl
//       }
//       return `${baseUrl}/${src}`
//   }
// }

// 图片内置
// /**
//  * @description:
//  * @param {*} url 需要处理的地址
//  * @param {*} isSupportWebp 是否需要返回对应的Webp地址 默认false
//  * @return {*}
//  */
export const requireCdnUrl = (url, isSupportWebp = false) => {
  try {
    let newUrl = url
    // base64 http 不处理
    if (url.match('data:image')) {
      return url
    }
    // 第一期 匹配轮播图片地址
    if (url.match(/http(s)?:\/\//)) {
      if (url.match('/admin/file')) {
        let _originFormatUrl = url
        // 兼容运营人员直接传格式化的图
        if (newUrl.match(/\.webp/g)) {
          _originFormatUrl = _originFormatUrl.replace('.webp', '')
        }
        if (isSupportWebp) {
          newUrl = _originFormatUrl + '.webp'
        } else {
          newUrl = _originFormatUrl
        }
      }
    } else if (url.match(/\/assets\//)) {
      // 内连接地址
      if (url.match(/jpg|png|jpeg/)) {
        if (isSupportWebp) {
          newUrl = url.replace(/assets/, 'assets/webp')
          newUrl = newUrl.replace(/\.(jpg|png|jpeg)$/g, '.webp')
        }
      }

      if (newUrl.match(/^[~|@]/)) {
        // 默认的不做处理
      } else if (newUrl.match(/_nuxt/)) {
        newUrl = newUrl.replace(/\/?\w*\/assets/, '~/assets')
      }
      // 去除多余的
      newUrl = newUrl.replace(/[~|@]?\/assets\//, '')
      return require(`~/assets/${newUrl}`)
    }
    return newUrl
  } catch (e) {
    console.log('requireCdnUrl error', e)
  }
}

/**
 * @description: 判断是否为safari浏览器
 * @param {*} isMobileOnly 是否只支持移动端，为true表示只校验是否为苹果移动端safari浏览器
 * @return {*}
 */
export const isSafari = (isMobileOnly = false) => {
  const ua = navigator.userAgent.toLowerCase()
  /* 如果是iphone手机的safiri加这个判断&& ua.indexOf('mobile') > -1 */
  if (
    ua.includes('applewebkit') &&
    (isMobileOnly ? ua.includes('mobile') : true) &&
    ua.includes('safari') &&
    !ua.includes('linux') &&
    !ua.includes('android') &&
    !ua.includes('chrome') &&
    !ua.includes('ios') &&
    !ua.includes('browser')
  ) {
    return true
  } else {
    return false
  }
}

// 判断是否为苹果机
export const isiOS = (isMobileOnly = false) => {
  const ua = navigator.userAgent.toLowerCase()
  /* 如果是iphone手机的safiri加这个判断&& ua.indexOf('mobile') > -1 */
  return !!ua.match(/\(i[^;]+;( U;)? cpu.+mac os x/)
}

/**
 * @description: 四舍五入
 * @param {*} num 实际操作的值
 * @param {*} digits 精确的位数
 * @return {*}
 */
export const roundUp = (num, digits) => {
  const pow = Math.pow(10, digits)
  // 整数直接返回
  return Number.isInteger(num) ? num : Math.round(num * pow) / pow
}

/**
 * @description: 【url传参】之将对象json转换为?param1=...&param2=...的形式
 * 测试 formatAsUrlParamString({a:1,b:2});//'?a=1&b=2'
 * @param {*} json 待转化的json对象
 * @param {*} firstStr
 * @return {*}
 */
export const formatAsUrlParamString = (json, firstStr = '?') =>
  Object.keys(json || {}).length === 0
    ? ''
    : firstStr +
      JSON.stringify(json)
        // eslint-disable-next-line
        .replace(/\t|\n|\r|\"|\{|\}/g, '')
        .replace(/,/g, '&')
        .replace(/:/g, '=')
