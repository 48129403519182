/*
 * @Author: liyang
 * @Date: 2020-05-14 16:38:26
 * @LastEditTime: 2023-05-09 14:42:13
 * @LastEditors: yuanxing(01407381)
 * @FilePath: \ibu-owt-core-frontend\utils\auth.js
 * @Description:
 */

import Cookies from 'js-cookie'

const storage = process.client ? window.localStorage : ''
const Token = 'token'

export function getCookie(TokenKey = Token, cookieStr) {
  if (!cookieStr) {
    return Cookies.get(TokenKey)
  } else {
    let arr
    const reg = new RegExp('(^| )' + TokenKey + '=([^;]*)(;|$)')
    if ((arr = cookieStr.match(reg))) {
      return unescape(arr[2])
    }
  }
}

export function setCookie(TokenKey = Token, token, config) {
  return Cookies.set(TokenKey, token, {
    expires: config.expires || 0.5,
  })
}

export function removeCookie(TokenKey = Token) {
  return Cookies.remove(TokenKey)
}

export function getCookieCommon(key) {
  return Cookies.get(key)
}

export function setCookieCommon(key, value) {
  return Cookies.set(key, value, {
    expires: 0.5,
  })
}

export function removeCookieCommon(key) {
  return Cookies.remove(key)
}

export function setLocalStorage(key, value) {
  let newValue = value
  if (typeof newValue === 'object') {
    newValue = JSON.stringify(value)
  } else {
    newValue = `${newValue}`
  }
  // 编码
  return storage.setItem(key, newValue)
}

export function getLocalStorage(key) {
  let value = ''
  let getValue = ''
  try {
    getValue = storage.getItem(key)
    if (getValue) {
      value = JSON.parse(getValue)
    }
  } catch (e) {
    getValue = storage.getItem(key)
    if (getValue) {
      value = getValue
    }
  }
  return value
}

export function removeLocalStorage(key) {
  return storage.removeItem(key)
}

export function clearLocalStorage() {
  return storage.clear()
}
