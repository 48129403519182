
import {
  ShippingMainlandingpagetopClick,
  TimeCostMainlandingpagetopClick,
  ShippingExpresslandingpagetopClick,
  TimeCostExpresslandingpagetopClick,
} from '@/reporter/track'
export default {
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      logoBlack: '~/assets/img/logo-black-express.png',
      trackMap: {
        'site-lang-landing-consign': ShippingMainlandingpagetopClick,
        'site-lang-landing-freight_aging': TimeCostMainlandingpagetopClick,
        'site-lang-landing-express-consign': ShippingExpresslandingpagetopClick,
        'site-lang-landing-express-freight_aging':
          TimeCostExpresslandingpagetopClick,
      },
    }
  },
  computed: {
    list() {
      return this.menuList
    },
    active() {
      return this.$route.name
    },
  },
  methods: {
    trackFn(name, properties) {
      if (this.trackMap[name]) {
        this.trackMap[name](properties)
      }
    },
    linkPath(path) {
      const site = this.$route.params.site
      const lang = this.$route.params.lang
      return `/${site}/${lang}${path}`
    },
    link(menu) {
      if (this.active !== menu.name) {
        const link = this.linkPath(menu.path)
        this.$router.push({ path: link })
        this.trackFn(menu.name)
      }
    },
  },
}
