
import landingHeader from '@/components/landing/header.vue'
import landingFooter from '@/components/landing/footer.vue'
import { CHANNEL, MESSAGES, KEYWORD, BD_CHANNEL, STORAGE_KEY } from '@/reporter/config/const.js'
import { channelTrack, messageTrack } from '@/reporter/utils'
import { LandingKeywordTrack } from '@/reporter/track'

const menuMap = {
  mainList: [
    {
      title: '首页',
      path: '/landing',
      name: 'site-lang-landing',
    },
    {
      title: '马上寄件',
      path: '/landing/consign',
      name: 'site-lang-landing-consign',
    },
    {
      title: '运费时效查询',
      path: '/landing/freight_aging',
      name: 'site-lang-landing-freight_aging',
    },
  ],
  expressList: [
    {
      title: '首页',
      path: '/landing/express',
      name: 'site-lang-landing-express',
    },
    {
      title: '马上寄件',
      path: '/landing/express/consign',
      name: 'site-lang-landing-express-consign',
    },
    {
      title: '运费时效查询',
      path: '/landing/express/freight_aging',
      name: 'site-lang-landing-express-freight_aging',
    },
  ],
  ecommerceList: [
    {
      title: '首页',
      path: '/landing/ecommerce',
      name: 'site-lang-landing-ecommerce',
    },
  ],
}

export default {
  components: {
    landingHeader,
    landingFooter,
  },
  data() {
    return {
      map: {
        'site-lang-landing': menuMap.mainList,
        'site-lang-landing-consign': menuMap.mainList,
        'site-lang-landing-freight_aging': menuMap.mainList,
        'site-lang-landing-express': menuMap.expressList,
        'site-lang-landing-express-consign': menuMap.expressList,
        'site-lang-landing-express-freight_aging': menuMap.expressList,
        'site-lang-landing-ecommerce': menuMap.ecommerceList,
      },
    }
  },
  computed: {
    name() {
      return this.$route.name
    },
    menuList() {
      return this.map[this.name]
    },
    query() {
      return this.$route.query
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const channel = this.getChannel()
      const message = this.getMessage()
      const keyword = this.getKeyword()
      if (channel) {
        channelTrack(channel)
        this.setChannel(channel)
      }
      if (message) {
        messageTrack(message)
        this.setMessage(message)
        this.setSearch()
      }
      if (keyword) {
        LandingKeywordTrack(keyword)
        this.setKeyword(keyword)
      }
      this.setUrl()
    },
    findAttr(query, object) {
      const list = Object.keys(query)
      const hasValue = list.find((it) => object[it])
      return hasValue ? object[hasValue] : false
    },
    findItem(query) {
      return MESSAGES.reduce((str, next) => {
        if (query[next]) {
          str += `${query[next]};`
        }
        return str
      }, '')
    },
    findKey(query, key) {
      const list = Object.keys(query)
      const hasValue = list.find((it) => it === key)
      return hasValue ? query[hasValue] : ''
    },
    getChannel() {
      const channel = sessionStorage.getItem(STORAGE_KEY.channel)
      return channel || this.findAttr(this.query, CHANNEL)
    },
    setChannel(channel) {
      sessionStorage.setItem(STORAGE_KEY.channel, channel)
    },
    getKeyword() {
      const keyword = sessionStorage.getItem(STORAGE_KEY.keyword)
      return keyword || this.findKey(this.query, KEYWORD)
    },
    setKeyword(keyword) {
      sessionStorage.setItem(STORAGE_KEY.keyword, keyword)
    },
    getMessage() {
      const message = sessionStorage.getItem(STORAGE_KEY.message)
      return message || this.findItem(this.query)
    },
    setMessage(message) {
      sessionStorage.setItem(STORAGE_KEY.message, message)
    },
    setSearch() {
      const search = window.location.search
      sessionStorage.setItem(STORAGE_KEY.search, encodeURIComponent(search))
    },
    setUrl() {
      const url = window.location.href
      sessionStorage.setItem(STORAGE_KEY.localurl, encodeURIComponent(url))
    }
  },
}
