import Cookies from 'js-cookie'
// 必要类cookie
const necessityCookie = [
  'IBU_IUOP_CORE_S22_NG_PROXY_ORDER',
  'option.preference',
  'sidebarStatus',
  'token',
  'userInfo',
  'AWSALBCORS',
  'AWSALB',
]
// 必要类LocalStorage
const necessityLocalStorage = [
  'localLangCode',
  'localSiteConfig',
  'conflictList',
  'isConflict',
  'userCode',
  'resetPasswordConflictParams',
  'loglevel:webpack-dev-server',
  'userInfo',
  'oneParams',
  'twoParams',
  'threeParams',
  'fourParams',
  'loginType',
  'umi_locale',
  'ipcs_userInfo',
  'ipcs_userInfoMore',
  'ipcs_auth',
  'pickupType',
  'applyDetailData'
]
// 必要类SessionStorage
const necessitySessionStorage = [
  'orderDetails',
  'oneForm',
  'twoForm',
  'threeForm',
  'fourForm',
  'sessionShopifyCode',
  'sessionShopifyShop',
  'afterLoginStrongAlert',
]

// 优化类cookie
const optimisationList = []
// 分析类cookie
const analyticsList = [
  'Hm_lvt_',
  '_ga',
  '_gid',
  'monitorCustomerKey',
  'webfunny_ip',
  'webfunny_province',
]

/**
 * 用途：获取cookie的所有key值，返回数组
 */
export function getCookieKey() {
  const str = document.cookie
  const cookieKey = []
  if (str) {
    const arr = str.split('; ')
    arr.forEach((item) => {
      cookieKey.push(item.split('=')[0])
    })
  }
  return cookieKey
}

/**
 * 用途：仅保留必要类cookie，其他cookie删除
 * @param {option} 传入的是cookie政策相关的option.preference数组，或不传
 */
export function onlySaveNecessityCookie() {
  const cookies = getCookieKey()
  const arr = [...necessityCookie]
  cookies.forEach((item) => {
    if (!arr.includes(item)) {
      Cookies.remove(item)
    }
  })
}
/**
 * 用途：删除优化类cookie
 */
export function removeOptimisationCookie() {
  const cookies = getCookieKey()
  cookies.forEach((item) => {
    if (optimisationList.includes(item)) {
      Cookies.remove(item)
    }
  })
}

/**
 * 用途：删除分析类localStorage和sessionStorage
 */
export function removeAnalyticsListStorage() {
  Object.keys(localStorage).forEach((item) =>
    !necessityLocalStorage.includes(item) ? localStorage.removeItem(item) : ''
  )
  Object.keys(sessionStorage).forEach((item) =>
    !necessitySessionStorage.includes(item)
      ? sessionStorage.removeItem(item)
      : ''
  )
}

/**
 * 用途：删除分析类cookie
 */
export function removeAnalyticsListCookie() {
  const cookies = getCookieKey()
  cookies.forEach((item) => {
    if (item.includes('Hm_lvt_')) {
      // 第一个元素的后缀不固定，需要动态赋值，比如Hm_lvt_234566543456
      analyticsList[0] = item
    }
    if (analyticsList.includes(item)) {
      Cookies.remove(item)
    }
  })
  removeAnalyticsListStorage()
}
/**
 * 用途：删除非所有cookie列表内的cookie
 * @param {option} 传入的是cookie政策相关的option.preference数组，或不传
 */
export function removeNoInCookieList() {
  const cookies = getCookieKey()
  const allCookieList = [
    ...necessityCookie,
    ...optimisationList,
    ...analyticsList,
  ]
  cookies.forEach((item) => {
    if (!allCookieList.includes(item)) {
      Cookies.remove(item)
    }
  })
}
/**
 * 用途：根据域名删除option.preferencecookie政策的cookie
 */
export function removeOptionCookie() {
  if (Cookies.get('option.preference')) {
    // 获取域名
    const host = document.domain
    if (host.includes('.sf-express.com')) {
      // 顺丰云测试环境
      Cookies.remove('option.preference', {
        path: '/',
        domain: '.sf-express.com',
      })
    } else if (host.includes('.sf.global')) {
      // 亚马逊云测试环境和生产环境
      Cookies.remove('option.preference', {
        path: '/',
        domain: '.sf.global',
      })
    } else {
      // 本地
      Cookies.remove('option.preference')
    }
  }
}
/**
 * 用途：根据域名设置option.preferencecookie政策的cookie
 */
export function setOptionCookie(agree) {
  // 获取域名
  const host = document.domain
  if (host.includes('.sf-express.com')) {
    // 顺丰云测试环境
    Cookies.set('option.preference', agree, {
      expires: 365,
      path: '/',
      domain: '.sf-express.com',
    })
  } else if (host.includes('.sf.global')) {
    // 亚马逊云测试环境和生产环境
    Cookies.set('option.preference', agree, {
      expires: 365,
      path: '/',
      domain: '.sf.global',
    })
  } else {
    // 本地
    Cookies.set('option.preference', agree, {
      expires: 365,
    })
  }
}
