
import { mapState } from 'vuex'
import Search from './search.vue'
import { RoutesList } from '@/router/jp/index.js'
import { LoginTopClick, RegisterTopClick } from '@/reporter/track'

export default {
  components: {
    Search,
  },
  data() {
    return {
      close: false,
      current: [],
      userInfoList: ['login', 'register']
    }
  },
  computed: {
    ...mapState({
      loginUser: (state) => state.loginUser,
      loginToken: (state) => state.loginToken,
      isHdFtMobile: (state) => state.isHdFtMobile,
      isPopup: (state) => state.isPopup,
      headerHeight: (state) => state.headerHeight,
    }),
    site() {
      return this.$route.params.site
    },
    lang() {
      return this.$route.params.lang
    },
    routeName() {
      return this.$route.name
    },
    activeName() {
      return this.routeName?.split('-').pop() || ''
    },
  },
  watch: {
    isPopup(nv) {
      if (!nv) this.current = []
    },
  },
  mounted() {
    // 动态获取头部高度
    const headerHeight = document.getElementById('header').offsetHeight
    this.$store.commit('SET_HEADER_HEIGHT', headerHeight + 'px')
  },
  methods: {
    onClosePopup(name) {
      this.onTrack(name)
      this.$store.commit('saveIsPopup', false)
    },
    onSelected(ev, parent, current) {
      const name = (parent.name || '') + current.name
      this.current.push(name)
      ev.stopPropagation()
    },
    onSelectedBack(ev) {
      this.current.pop()
      ev.stopPropagation()
    },
    onDropout() {
      this.$API.common.loginOutApi({
        token: this.loginToken,
      }).then((res) => {
        if (res === 'ok') {
          this.$store.commit('resetLogin')
          this.$router.go(0)
        }
      })
    },
    onTrack(name) {
      if (name === 'login') {
        LoginTopClick()
      } else if(name === 'register') {
        RegisterTopClick()
      }
    },
    setSubMenuText(subMenu) {
      if (this.loginUser && this.userInfoList.includes(subMenu.name)) {
        if (subMenu.name === this.userInfoList[0]) {
          return this.loginUser
        } else {
          return <div onClick={this.onDropout}>{this.$t('common.login.dropout')}</div>
        }
      }
      return <LinkTo path={subMenu.path} gjUtm={subMenu.gjUtm} target={subMenu.name !== 'coop' ? '_self' : ''} onClick={() => this.onTrack(subMenu.name)}>{this.$t(subMenu.il8nName)}</LinkTo>
    },
    renderChildren(parent, children) {
      const name = parent.name || ''
      return (
        <div class="menu-item-content">
          {name ? (
            <div
              class="menu-back"
              onClick={($event) => this.onSelectedBack($event)}
            >
              <svg-icon icon-name="chevron-right" class="icon-back"></svg-icon>
              <span class="word-back">{this.$t(parent.il8nName)}</span>
            </div>
          ) : (
            ''
          )}
          {children.map((it) => (
            <div
              key={name + it.name}
              class={[
                'menu-item-content-item',
                this.activeName === it.name ? 'active' : '',
              ]}
            >
              {!(it.children && it.children.length) ? (
                <div>{this.setSubMenuText(it)}</div>
              ) : (
                <div
                  class="menu-item-word"
                  onClick={($event) => this.onSelected($event, parent, it)}
                >
                  <span>{this.$t(it.il8nName)}</span>
                  <svg-icon icon-name="chevron-right" class="icon"></svg-icon>
                  <div
                    class={[
                      'menu-item-content-childred',
                      this.current.includes(name + it.name) ? 'show' : '',
                    ]}
                  >
                    <div class="menu-item-content-childred-content">
                      {this.renderChildren(it, it.children)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )
    },
  },
  render() {
    return (
      <div
        style={{
          top: this.headerHeight,
          height: `calc(100vh - ${this.headerHeight})`,
        }}
        class={['menus', this.isPopup ? 'popup' : 'close']}
      >
        <div class="menu-content">
          <Search />
          <div class="menu-content-items">
            {this.renderChildren('', RoutesList)}
          </div>
        </div>
      </div>
    )
  },
}
