
import {
  linkPath,
  changeIuopUrlByLang,
  changeSFExpressUrlByLang,
} from '@/utils/utils'

export default {
  props: {
    // 路径
    path: {
      type: String,
      default: () => {},
    },
    target: {
      type: String,
      default: null,
    },
    // 参数 hash query
    params: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 是否从站点开始全部替换
    isRootReplace: {
      type: Boolean,
      default: false,
    },
    // 渠道推广标识
    gjUtm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      innerPath: '',
    }
  },
  computed: {
    getTarget() {
      const target = this.path?.match(/^http(s)?:/) ? '_blank' : null
      return this.target || target
    },
    inpath() {
      const routeParams = this.$route.params
      const outLink = this.path

      if (outLink?.match(/^http(s)?:/)) {
        // 速运链接
        if (outLink.includes(window.g.sfExpressUrl)) {
          return changeSFExpressUrlByLang({
            url: outLink,
            lang: routeParams.lang,
          })
        } else if (
          outLink.includes(window.g.iuopUrl) ||
          outLink.includes(window.g.iuopGlobalUrl)
        ) {
          // 跳转iuop链接
          let url = changeIuopUrlByLang({
            url: outLink,
            lang: routeParams.lang,
          })

          if (this.gjUtm) {
            url += `&gjUtm=${encodeURIComponent(this.gjUtm)}`
          }
          return url
        }
        return outLink
      }
      if (this.isRootReplace) {
        return this.path
      }
      const path = linkPath(
        { site: routeParams.site, lang: routeParams.lang },
        this.path,
        this.params
      )
      return this.path === '#' ? 'javascript:;' : path
    },
  },
  mounted() {
    this.innerPath = this.inpath
  },
  updated() {
    this.innerPath = this.inpath
  },
}
