import { SignError } from '@/reporter/track'

const signApiRules = [
  '/sign/site/icrm/*', // cookie政策
  '/sign/site/cms-service/web/*', // 下载文件列表
]

const signRequire = async ($axios) => {
  try {
    await import('sign-prod').then(async ({ axiosUse }) => {
      try {
        await axiosUse({
          axiosInstance: $axios,
          rule: signApiRules,
          // 可选 加签错误处理函数
          signErrorTrack: (params) => {
            SignError(params)
          },
        })
      } catch (e) {
        SignError({
          intl_sign_error_event: 'sign_error',
          intl_sign_error_type: 'unkown',
          intl_sign_error_message: e?.message || 'unkown',
        })
      }
    })
  } catch (e) {
    SignError({
      intl_sign_error_event: 'sign_error',
      intl_sign_error_type: 'resource',
      intl_sign_error_message: e?.message || 'unkown',
    })
    // throw new Error(e)
  }
}

export default signRequire
