// 物流服务---物流产品---电商产品
export const EbizProductList = [
  {
    cname: '集转货运(SFBuy)',
    name: 'sfbuy',
    il8nName: 'modules.logisticsService.productList.sfbuy',
    path: '/product/sfbuy',
    logKey: 'sfbuy_page|顺丰集运页面',
    params: { query: { isProduct: 'product' } },
  },
]
// 物流服务---物流产品---快递产品
export const ExpressProductList = [
  {
    cname: '国际标快',
    name: 'fast',
    il8nName: 'modules.logisticsService.productList.fast',
    path: '/product/fast',
    logKey: 'standard_express_page|国际标快页面',
    params: { query: { isProduct: 'product' } },
  },
  {
    cname: '国际特惠',
    name: 'discts',
    il8nName: 'modules.logisticsService.productList.discts',
    path: '/product/discts',
    logKey: 'economy_express_page|国际特惠页面',
    params: { query: { isProduct: 'product' } },
  },
  {
    cname: '国际重货',
    name: 'heavy',
    il8nName: 'modules.logisticsService.productList.heavy',
    path: '/product/heavy',
    logKey: 'economy_express_page|国际重货页面',
    params: { query: { isProduct: 'product' } },
  },
]

// 物流服务---物流产品
export const LogisticsServiceList = [
  {
    cname: '快递',
    name: 'express_prod',
    classil8nName: 'modules.logisticsService.productList.express',
    il8nName: 'modules.logisticsService.productList.courier',
    path: '',
    children: ExpressProductList,
  },
  {
    cname: '电商',
    name: 'intl_ebiz_prod',
    classil8nName: 'modules.logisticsService.productList.commerce',
    il8nName: 'modules.logisticsService.productList.onlineRetailers',
    path: '',
    children: EbizProductList,
  },
]
// 物流服务---增值服务
export const ValueAddedProductsList = [
  {
    cname: '保价',
    name: 'insure',
    il8nName: 'modules.logisticsService.incrementList.insure',
    path: '/product/insure',
    logKey: 'shippment_protection_page|保价页面',
    params: { query: { isProduct: 'increment' } },
  },
  {
    cname: '派件地址变更服务',
    name: 'addrchange',
    il8nName: 'modules.logisticsService.incrementList.addrchange',
    path: '/product/addrchange',
    logKey: 'address_correction_page|派件地址变更页面',
    params: { query: { isProduct: 'increment' } },
  },
  {
    cname: '包装服务',
    name: 'packservice',
    il8nName: 'modules.logisticsService.incrementList.packservice',
    path: '/product/packservice',
    logKey: 'packaging_page|包装服务页面',
    params: { query: { isProduct: 'increment' } },
  },
]
// 物流服务---附加费
export const AddedProductsList = [
  {
    cname: '国际偏远附加费',
    name: 'remoteAreaSurcharge',
    il8nName: 'modules.logisticsService.additionList.remoteAreaSurcharge',
    path: '/product/remoteAreaSurcharge',
    logKey: 'remote_area_surcharge_page|国际偏远附加费页面',
    params: { query: { isProduct: 'addition' } },
  },
  {
    cname: '国际住宅附加费',
    name: 'housesurcharge',
    il8nName: 'modules.logisticsService.additionList.housesurcharge',
    path: '/product/housesurcharge',
    logKey: 'international_residential_page|国际住宅附加费页面',
    params: { query: { isProduct: 'addition' } },
  },
  {
    cname: '燃油附加费',
    name: 'fuelschge',
    il8nName: 'modules.logisticsService.additionList.fuelschge',
    path: '/product/fuelschge',
    logKey: 'fuel_surcharge_page|燃油附加费页面',
    params: { query: { isProduct: 'addition' } },
  },
  {
    cname: '超长超重附加费',
    name: 'overwgtfee',
    il8nName: 'modules.logisticsService.additionList.overwgtfee',
    path: '/product/overwgtfee',
    logKey: 'oversive_weight_page|超长超重附加费页面',
    params: { query: { isProduct: 'addition' } },
  },
  {
    cname: '资源调节费',
    name: 'resourceallocationfee',
    il8nName: 'modules.logisticsService.additionList.resourceallocationfee',
    path: '/product/resourceallocationfee',
    logKey: 'resourceallocationfee|资源调节费',
    params: { query: { isProduct: 'addition' } },
  },
]

export default {
  path: '',
  name: 'product',
  cname: '物流服务',
  il8nName: 'navs.logisticsService',
  children: [
    // 物流产品
    {
      cname: '物流产品',
      path: '/product',
      name: 'product',
      il8nName: 'modules.logisticsService.product',
      hasNext: true,
      children: LogisticsServiceList,
      params: { hash: 'product' },
    },
    // 增值服务
    {
      cname: '增值服务',
      path: '/product',
      name: 'increment',
      il8nName: 'modules.logisticsService.increment',
      children: ValueAddedProductsList,
      params: { hash: 'increment' },
    },
    // 附加费
    {
      cname: '附加费',
      path: '/product',
      name: 'addition',
      il8nName: 'modules.logisticsService.addition',
      children: AddedProductsList,
      params: { hash: 'addition' },
    },
  ],
}
