import sensor from 'sa-sdk-javascript'
import options from '@/reporter/config/base'
import properties from '@/reporter/config/properties'
import plugins from '@/reporter/config/plugins'
import { initJsResources } from '@/reporter/track/webVitals.js'

export default function ({ store }, inject) {
  sensor.use('PageLeave', plugins.PageLeave)

  sensor.use('PageLoad', plugins.PageLoad)

  sensor.init(options)

  sensor.registerPage({
    ...{ owt_site: store?.state?.site },
    ...properties,
  })
  // 全自动增加站点属性
  sensor.quick('autoTrack', properties)
  inject('$sensor', sensor)
  initJsResources()
}
