/*
 * @Author: liyang
 * @Date: 2021-03-17 14:03:16
 * @LastEditTime: 2024-01-12 19:00:17
 * @LastEditors: Please set LastEditors
 * @FilePath: \ibu-owt-core-frontend\store\index.js
 * @Description:共享数据 https://www.nuxtjs.cn/guide/vuex-store
 */

import { setOptionCookie } from '@/utils/cookieOperation'
import {
  maxContentWidth,
  currencyList,
  DefaultCookieAgree,
} from '@/utils/constBase'
import { CountryLangType } from '@/utils/const'
import { getCookie, removeCookie } from '@/utils/auth'
import { RoutesListFn } from '@/router'

export const state = () => ({
  // 当前站点
  site: '',
  lang: '',
  locales: [],
  // 当前语言
  locale: '',
  // 路由信息
  router: {},
  // 屏幕信息以及是否到移动端
  resizeInfo: {},
  isMobile: false, // 默认为非移动端
  // 通过切换路由和判断PC还是移动端,确认哪些页面需要展示 重要公告
  isShowNotify: false,
  // 区分是否首页
  isHome: false,
  // 顶部和底部导航 是否达到移动端样式展示,涉及到手机端和pad
  isHdFtMobile: false,
  // 移动端是否打开侧边菜单
  isPopup: false,
  // 导航样式是否需要变更
  // isMenuClass: false,
  // 货币-后续可从接口中返回
  currencyList,
  // 用户信息 可从全局变量中获取
  loginUser: null,
  // 登录 token
  loginToken: null,
  // 清关信息收集总价值
  cusValue: null,
  // cookie信息
  cookie_agree: {},
  cookieModalVisible: false, // cookie设置默认关闭
  cookie_terms: {
    title: '',
    show: false,
    type: '',
  },
  cookie_btn_disagree: false,
  detailData: {
    addrInfo: {},
    gdescInfo: [],
  }, // 清关申报信息收集数据
  productMenus: [], // 物流服务下所有菜单
  headerHeight: 76, // 移动端默认头部高度，累加cookie头部高度
  isMap: false,
})

export const mutations = {
  // 此处为设置cookie
  setCookieModalVisible(state, visible) {
    state.cookie_terms.show = visible
  },
  set_COOKIE_AGREE(state, agree) {
    try {
      if (typeof agree === 'string') {
        state.cookie_agree = JSON.parse(agree)
      } else if (typeof agree === 'object') {
        state.cookie_agree = agree
      }
    } catch (e) {
      console.warn('set_COOKIE_AGREE error', e)
    }
  },
  SET_AGREE_COOKIE: (state, agree) => {
    try {
      // 根据域名设置cookie政策的cookie
      setOptionCookie(agree)
      state.cookie_agree = JSON.parse(agree)
    } catch (e) {
      console.warn('SET_AGREE_COOKIE error', e)
    }
  },
  SET_DETAIL_DATA: (state, view) => {
    state.detailData = JSON.parse(JSON.stringify(view))
  },
  SER_COOKIE_BTN_DISAGREE: (state, cookieBtnDisagree) => {
    state.cookie_btn_disagree = cookieBtnDisagree
  },
  SET_COOKIE_TERMS: (state, value) => {
    state.cookie_terms = value
  },
  // 此处为设置site
  setSite(state, site) {
    state.site = site
  },
  setLang(state, lang) {
    state.lang = lang
  },
  // 此处为设置locale
  setLocale(state, locale) {
    if (CountryLangType[locale]) {
      state.locale = CountryLangType[locale]
    } else {
      state.locale = locale
    }
  },
  // 该站点国家具有的语言类型
  saveLocales(state, locales) {
    state.locales = locales
  },
  // 存储页面信息
  setResizeInfo(state, resizeInfo) {
    state.resizeInfo = resizeInfo || {}
    // 顶部和底部导航 是否达到移动端样式展示
    state.isHdFtMobile = resizeInfo.contentRect.width <= maxContentWidth
    state.isMobile = resizeInfo?.isMobile
  },
  // 存储信息
  setSave(state, payload) {
    Object.assign(state, payload)
  },
  // 区分是否首页
  saveIsHome(state, isHome) {
    state.isHome = isHome
  },
  // 移动端是否打开侧边菜单
  saveIsPopup(state, isPopup) {
    state.isPopup = isPopup
  },

  // 设置登录信息
  saveLoginInfo(state, loginUser) {
    state.loginUser = loginUser
  },
  // 复原登录信息
  resetLogin(state) {
    state.loginUser = null
    state.loginToken = null
    removeCookie('loginUser')
    removeCookie('userToken')
  },
  // 设置登录token
  saveLoginToken(state, token) {
    state.loginToken = token
  },
  // 设置清关信息收集总价值
  saveCusValue(state, cusValue) {
    state.cusValue = cusValue
  },
  // 设置清关信息收集总价值
  addProductMenu(state, view = []) {
    const curRouter = RoutesListFn(state.site) || []
    const productItem = curRouter.find((item) => item.name === 'product') || {}
    // 根据接口返回的物流服务菜单进行替换
    productItem.children = view
    // 保存
    state.productMenus = view
  },
  // 设置布局头部整体高度
  SET_HEADER_HEIGHT(state, view) {
    state.headerHeight = view
  },
  SET_ISMAP(state, bool) {
    state.isMap = bool
  },
}

export const actions = {
  setAgreeCookie({ commit }, agree) {
    commit('SET_AGREE_COOKIE', agree)
  },
  // 初始化数据
  nuxtServerInit({ commit }, { req, route }) {
    const {
      query: { token },
    } = route
    const cookie = req.headers.cookie

    const userName = getCookie('loginUser', cookie)
    const loginToken = getCookie('userToken', cookie)
    const preference = getCookie('option.preference', cookie)
    const relToken = token || loginToken
    // 已登录-直接赋值
    if (userName && relToken) {
      commit('saveLoginInfo', userName)
      commit('saveLoginToken', relToken)
    } else {
      // 未登录
      commit('saveLoginInfo', null)
      commit('saveLoginToken', null)
    }
    // 设置cookie数据信息
    if (preference) {
      commit('set_COOKIE_AGREE', preference)
    } else {
      commit('set_COOKIE_AGREE', JSON.stringify(DefaultCookieAgree))
    }
    if (req.url) {
      const urlSplit = req.url.split('/')
      const isHome = urlSplit[3]
      commit('saveIsHome', !isHome)
    }

    // 根据当前浏览器型号判断运行环境
    const userAgent = req.headers['user-agent'].toLowerCase()
    // 用 test 匹配浏览器信息
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        userAgent
      )
    ) {
      // 前端是移动端
      commit('setResizeInfo', {
        contentRect: {
          width: 750,
        },
        isMobile: true,
      })
    } else {
      // 前端是PC端
      commit('setResizeInfo', {
        contentRect: {
          width: 1101,
        },
        isMobile: false,
      })
    }
  },
  setIsMap({ commit }, bool) {
    commit('SET_ISMAP', bool)
  },
}
