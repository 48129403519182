/*
 * @Author: 01394339
 * @Date: 2022-07-19 18:06:06
 * @LastEditTime: 2023-11-24 16:43:45
 * @LastEditors: Please set LastEditors
 * @Description:
 */
import Vue from 'vue'
// import Antd from 'ant-design-vue/lib'
import {
  Row,
  Col,
  Icon,
  Affix,
  Input,
  Upload,
  InputNumber,
  Dropdown,
  Descriptions,
  Select,
  Table,
  message,
  Menu,
  Radio,
  Cascader,
  Result,
  Popconfirm,
  Tabs,
  Form,
  FormModel,
  Layout,
  ConfigProvider,
  Drawer,
  DatePicker,
  Carousel,
  Pagination,
  Popover,
  Tooltip,
  Button,
  Modal,
  Checkbox,
  Divider,
  Collapse,
  Switch,
  AutoComplete,
  Spin,
  Empty,
  Progress,
} from 'ant-design-vue'
import CtMessage from '@/components/message/index.js'

const components = [
  Row,
  Col,
  Icon,
  Affix,
  Input,
  Upload,
  InputNumber,
  Dropdown,
  Descriptions,
  Select,
  Table,
  Radio,
  Cascader,
  Result,
  Popconfirm,
  Tabs,
  Form,
  message,
  Menu,
  FormModel,
  Layout,
  ConfigProvider,
  Drawer,
  DatePicker,
  Carousel,
  Pagination,
  Popover,
  Button,
  Tooltip,
  Modal,
  Checkbox,
  Divider,
  Collapse,
  Switch,
  AutoComplete,
  Spin,
  Empty,
  CtMessage,
  Progress,
]
if (!Vue.useAnt) {
  components.forEach((component) => Vue.use(component))
}
export default (ctx, inject) => {
  if (!Vue.useAnt) {
    Vue.useAnt = true
    inject('message', message)
    inject('confirm', Modal.confirm)
    inject('warning', Modal.warning)
    inject('success', Modal.success)
    inject('info', Modal.info)
  }
}
