
import Cookies from 'js-cookie'
import { mapState, mapMutations, mapActions } from 'vuex'
import {
  onlySaveNecessityCookie,
  removeOptionCookie,
  removeOptimisationCookie,
  removeAnalyticsListCookie,
} from '@/utils/cookieOperation'
export default {
  props: {
    // 用于识别是否是cookie弹窗，特殊处理
    cookieDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: '',
      version: '',
      coookieType: {
        agree: false,
        optimisation: false,
        analytics: false,
      },
      showCookieKey: 'showCookie'
    }
  },
  computed: {
    ...mapState({
      cookieModalVisible: (state) => state.cookieModalVisible,
      terms: (state) => state.cookie_terms,
      locale: (state) => state.locale,
    }),
    showCookie() {
      return this.$route.query[this.showCookieKey] === 'true'
    }
  },
  watch: {
    'terms.show'(n, o) {
      if (n) {
        if (this.terms.id) {
          this.getLatestTermRichTextOfId(this.terms.id)
        } else {
          this.getLatestTermRichText(this.terms.type)
        }
        if (Cookies.get('option.preference') && this.cookieDialog) {
          this.coookieType = JSON.parse(Cookies.get('option.preference'))
          // 防止用户控制台修改cookie的agree属性，其他值没跟着改变
          if (!this.coookieType.agree) {
            this.coookieType.optimisation = false
            this.coookieType.analytics = false
          }
        }
      }
    },
  },
  mounted() {
    if (this.showCookie) {
      this.showDialog()
    }
  },
  methods: {
    ...mapMutations({
      setCookieTerms: 'SET_COOKIE_TERMS',
      setCookieModalVisible: 'setCookieModalVisible',
    }),
    ...mapActions({
      setAgreeCookie: 'setAgreeCookie',
    }),
    showDialog() {
      this.setCookieTerms({
        title: this.$t('cookies.cookie'),
        show: true,
        type: 1,
      })
    },
    handleOk() {
      this.setCookieModalVisible(false)
    },
    handleCancel() {
      this.setCookieModalVisible(false)
    },
    filterShowCookieKey() {
      const hrefObj = new URL(location.href);
      let params = new URLSearchParams(hrefObj.search);
      params.delete(this.showCookieKey);
      hrefObj.search = params.toString();
      return hrefObj.toString();
    },
    async getLatestTermRichText(type) {
      try {
        // 中文简体：zh-CN,中文繁体：zh-HK,英文：en,日语：ja-JP,韩语：ko-KR,越南语：vi-VN
        const langMap = {
          'zh-CN': 'zh-CN',
          'zh-HK': 'zh-HK',
          en: 'en',
          'ja-JP': 'ja-JP',
          'ko-KR': 'ko-KR',
          'vi-VN': 'vi-VN',
        }
        const realLang = langMap[this.locale] || 'en'

        const res = await this.$API.common.getLatestTermRichTextApi(
          { type },
          realLang
        )
        if (res.code === 0 || res.code === '0') {
          this.version = res.data.version
          this.content = res.data.content
        } else {
          this.content = ''
          this.$message.error(res.msg)
        }
      } catch (error) {
        this.content = ''
      }
    },
    async getLatestTermRichTextOfId(id) {
      try {
        const res = await this.$API.common.getLatestTermRichTextOfIdApi({
          termRichTextId: id,
        })
        if (res.code === 0 || res.code === '0') {
          this.content = res.data.content
        } else {
          this.content = ''
          this.$message.error(res.msg)
        }
      } catch (error) {
        this.content = ''
        // console.log(error)
      }
    },
    termsBtn(agree) {
      this.setCookieModalVisible(false)
      if (this.cookieDialog) {
        // 根据用户选择的cookie选项清除对应的cookie
        if (!this.coookieType.agree) {
          onlySaveNecessityCookie()
          removeOptionCookie()
        }
        if (!this.coookieType.optimisation) {
          removeOptimisationCookie()
        }
        if (!this.coookieType.analytics) {
          // 配合SFBuy添加google分析代码
          if (window.dataLayer) window.dataLayer = []
          removeAnalyticsListCookie()
        }
        if (this.coookieType.agree && this.terms.type === 1) {
          this.setAgreeCookie(JSON.stringify(this.coookieType))
        }
        const href = this.filterShowCookieKey()
        location.replace(href)
        // location.reload()
      }
    },
    disagreeBtn() {
      this.setCookieModalVisible(false)
    },
    // 处理cookie类别选择
    handleCookieType() {
      if (!this.coookieType.agree) {
        this.coookieType.optimisation = false
        this.coookieType.analytics = false
      }
    },
    handleDisabled() {
      this.$message.warning(this.$t('cookies.neddCheck'))
    },
  },
}
