
import { mapState } from 'vuex'
import {
  LangEnum,
  CountryList,
  CountryLang,
  SFExpressSupportLangs,
} from '@/utils/constBase'
import { linkPath, getPathInfo } from '@/utils/utils'
import allUrls from '@/utils/config'
import { fontFamilyLangMap, inSiteList } from '@/utils/const/countryLang'

// 选择类型
const selectType = {
  lang: 'lang',
  site: 'site',
}
export default {
  components: {},
  data() {
    return {
      LangBaseList: [],
      CountryList,
      site: '',
      visible: false,
      // 移动端 选择
      mbSelectType: selectType.site,
      selectType,
      currentSite: {},
    }
  },
  computed: {
    ...mapState({
      isAgree: (state) => state.cookie_agree.agree,
      headerHeight: (state) => state.headerHeight,
    }),
    getCountryList() {
      // 过滤掉暂时隐藏的国家列表入口
      return this.CountryList.filter((item) => {
        // 避免服务端报错
        if (process.client) {
          return !window?.hiddenCountryList?.includes(item.key)
        } else {
          return true
        }
      })
    },
    isEnLang() {
      return this.$route.params.lang === 'en'
    }
  },
  created() {
    this.init()
  },
  activated() {
    // 解决部分场景真机回退时站点和语言不匹配的问题
    this.init()
  },
  methods: {
    init() {
      const site = this.$route.params.site
      const lang = this.$route.params.lang
      this.LangBaseList = CountryLang[site]?.lang || []
      this.site = site

      this.mbSelectType = {
        site,
        lang,
      }
      this.filterSf(site)
      if (process.client) {
        this.setFontFamily(lang)
      }
    },
    // 移动端 展示
    showDrawer() {
      this.visible = true
    },
    // 移动端 关闭
    onClose() {
      this.visible = false
    },
    // 选择 国家或者 语言
    selectTypeHandle(type) {
      this.mbSelectType = type
    },
    // 选择 国家
    selectSiteHandle(item) {
      // this.$logger.customTrack({
      //   type: 'click',
      //   event: 'select_region_click|选择国家地区点击率',
      // })
      this.changeCountry({
        key: item.key,
      })
    },
    // 选择 语言
    selectLangHandle(item) {
      // this.$logger.customTrack({
      //   type: 'click',
      //   event: 'change_language_click|切换语言点击率',
      // })
      this.changeLocal({
        key: item.key,
      })
    },
    // 切换路由更改
    routeChange(params = {}, type) {
      const routerInfo = this.$route
      const site = params.site || routerInfo.params.site
      const lang = params.lang || routerInfo.params.lang
      if (type === 'country') {
        let url = ''
        let curSiteExpress = ''
        let curSiteIntel = ''
        if (site === 'ex' || site === 'hk') {
          curSiteExpress = site === 'ex' ? 'cn' : site
          url = allUrls.sfExpressUrl + '/' + curSiteExpress + '/' + lang + '/'
          window.open(url, '_blank')
        } else if (site === 'tw') {
          // 台湾没有简体
          curSiteExpress = site === 'ex' ? 'cn' : site
          const twLang = lang === 'sc' ? 'tc' : 'en'
          url = allUrls.sfExpressUrl + '/' + curSiteExpress + '/' + twLang + '/'
          window.open(url, '_blank')
        } else if (inSiteList.includes(site)) {
          // 站内跳转
          this.currentCountry(site, lang)
        } else if (site === 'sf') {
          this.currentCountry('cn', lang)
        } else {
          curSiteIntel = site === 'sf' ? 'zh' : site + '/' + lang + '/'
          url = allUrls.owtOldUrl + '/' + curSiteIntel
          window.open(url, '_blank')
        }
      } else {
        const { pathStr } = getPathInfo(routerInfo)
        const path = linkPath({ ...routerInfo.params, ...params }, pathStr, {
          query: routerInfo.query,
          hash: routerInfo.hash,
        })
        // 重新加载资源
        location.replace(path)
      }
    },
    // 国家切换
    changeCountry(l) {
      const countryCode = l.key
      const curLang = this.$route.params.lang
      const curCountryCode = this.$route.params.site
      // 避免重复
      if (countryCode !== curCountryCode) {
        // this.LangBaseList = CountryLang[countryCode]?.lang
        const langList =
          CountryLang[countryCode]?.lang?.map((it) => it.code) || []
        const params = {
          site: countryCode,
          lang:
            langList.find((it) => it === curLang) || SFExpressSupportLangs.en,
        }
        // 判断是否需要保留当前语言
        if (!this.LangBaseList.some((item) => item.code === curLang)) {
          Object.assign(params, { lang: this.LangBaseList[0].code })
        }
        this.routeChange(params, 'country')
      }
    },
    // 语言切换
    changeLocal(l) {
      const locale = l.key
      const curLang = this.$route.params.lang
      const langCode = LangEnum[locale].code
      // 避免重复
      if (curLang !== langCode) {
        this.$i18n.locale = locale
        this.routeChange(
          {
            lang: langCode,
          },
          'lang'
        )
      }
    },
    addOrReduceFont(font, FONT_MINE) {
      const fonts = font.split(',')
      if (FONT_MINE && !fonts.includes(FONT_MINE)) {
        fonts.unshift(FONT_MINE)
      }
      return fonts.join(',')
    },
    setFontFamily(lang) {
      const body = document.body
      const font = body.currentStyle
        ? body.currentStyle['font-family']
        : getComputedStyle(body, null)['font-family']
      if (fontFamilyLangMap[lang]) {
        body.style.fontFamily = this.addOrReduceFont(
          font,
          fontFamilyLangMap[lang]
        )
      }
    },
    filterSf(site) {
      if (site === 'cn') {
        this.CountryList = CountryList.filter((it) => it.key !== 'sf')
      }
    },
    currentCountry(site, lang) {
      window.location.href = `${window.location.origin}/${site}/${lang}`
    },
    getCurrentSiteName(site) {
      return CountryList.find((it) => it.key === site)?.name || ''
    },
  },
}
