/*
 * @Author: liyang
 * @Date: 2021-04-06 15:13:42
 * @LastEditTime: 2022-08-09 10:55:01
 * @LastEditors: yuanxing
 * @FilePath: \globalweb\plugins\icon-svg.js
 * @Description:
 */

import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon.vue' // svg component
if (!Vue.useSVG) {
  Vue.useSVG = true
  Vue.component('SvgIcon', SvgIcon)
  const req = require.context('@/assets/svg', true, /\.svg$/)
  const requireAll = (requireContext) =>
    requireContext.keys().map(requireContext)
  requireAll(req)
}
