/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-03-07 11:09:52
 * @LastEditors: yuanxing(01407381)
 * @LastEditTime: 2023-03-27 16:56:47
 * @FilePath: \ibu-owt-core-frontend\plugins\purify-html.js
 * @Description:
 */
import Vue from 'vue'
import VueDomPurifyHTML from 'vue-dompurify-html'
Vue.use(VueDomPurifyHTML)
