// 关于我们
const aboutUsList = {
  path: '',
  cname: '关于我们',
  name: 'about',
  il8nName: 'navs.aboutUs',
  children: [
    {
      path: '/about/introduce',
      cname: '公司介绍',
      name: 'aboutUs',
      il8nName: 'modules.intro.menu.introduce',
    },
    {
      path: '/about/address',
      cname: '公司地址',
      name: 'address',
      il8nName: 'modules.intro.menu.address',
    },
    // {
    //   path: '/about/topInterview',
    //   cname: '精选采访',
    //   name: 'interview',
    //   il8nName: 'modules.intro.menu.topInterview',
    // },
    {
      path: '/about/careers',
      cname: '人才招聘',
      name: 'careers',
      il8nName: 'modules.about.careers',
    },
    {
      path: '/support/querySupport/auth_reseller',
      cname: '授权合作伙伴服务点',
      name: 'authReseller',
      il8nName: 'navs.authReseller',
    }
  ],
}

export default aboutUsList
