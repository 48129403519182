/*
 * @Description:
 * @Author: AaronDing
 * @Date: 2021-10-12 17:34:36
 * @LastEditTime: 2022-01-17 11:20:32
 * @FilePath: \nginx_configg:\test_project\other\globalweb\plugins\resizeObserver.js
 * @LastEditors: yuanxing
 */
import { ResizeObserver } from '@juggle/resize-observer'
import { mobilePx } from '@/utils/constBase'
// import Vue from 'vue'

// 传入需要检测的DOM 通过callback 返回是否为移动端,以及当前检测的DOM状态
// Vue.prototype.$resizeObserver = function (element, callback) {
// const resizeObserver = new ResizeObserver(function (entries) {
//   const entry = entries[0].contentRect
//   const respone = {
//     lessThan: entry.width < 640,
//     contentRect: entry,
//   }
//   callback(respone)
// })
// resizeObserver.observe(element)
// }

/**
 * 影响到别的代码 临时注释掉
 * @param element
 * @param callback
 */
// 动态监听，可在此判断是否为移动端，可获取屏幕大小尺寸
export default ({ app, store }) => {
  if (process.client) {
    const resizeObserver = new ResizeObserver(function (entries) {
      const entry = entries[0].contentRect
      const respone = {
        isMobile: entry.width <= mobilePx,
        contentRect: { ...entry },
      }
      // 存储在store中，可在页面上调用
      store.commit('setResizeInfo', respone)
    })
    resizeObserver.observe(document.body)
  }
}
