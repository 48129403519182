// 清关状态
export const clearanceStatus = (that) => [
  {
    value: '01',
    label: that.$t('serviceSupport.clearanceService.apply.toBeSubmitted'), // 待录入
  },
  {
    value: '02',
    label: that.$t('serviceSupport.clearanceService.apply.submitted'), // 已录入
  },
  {
    value: '03',
    label: that.$t('serviceSupport.clearanceService.apply.underReview'), // 审核中
  },
  {
    value: '04',
    label: that.$t(
      'serviceSupport.clearanceService.apply.pendingCustomsClearance'
    ), // 待申报
  },
  {
    value: '05',
    label: that.$t(
      'serviceSupport.clearanceService.apply.customsClearanceRelease'
    ), // 通关放行
  },
]

// 在线申报跳转入口埋点映射表
export const TRACK_ENTRY_MAP = {
  mainMenu: 'OWT_MainMenu_OnlineCustomDeclaration', // 主菜单入口
  clearanceService: 'OWT_ClearanceService_OnlineCustomDeclaration', // 清关服务下左侧菜单入口
  homePage: 'OWT_Homepage_ClearanceService_OnlineCustomDeclaration', // 主菜单入口
}
