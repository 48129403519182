/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-03-21 10:21:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-15 10:08:28
 * @FilePath: \ibu-owt-core-frontend\reporter\config\base.js
 * @Description:
 */
import config from '@/utils/config'

const options = {
  server_url: config.scUrl,
  is_track_single_page: true,
  use_client_time: false,
  send_type: 'beacon',
  // show_log: window.g.env !== 'prd',
  show_log: false,
  encrypt_cookie: true,
  cross_subdomain: false,
  heatmap: {
    clickmap: 'default',
    scroll_notice_map: 'default',
  },
}

export default options
