/*
 * @Author: liyang
 * @Date: 2021-04-07 15:07:04
 * @LastEditTime: 2021-10-26 18:53:05
 * @LastEditors: Do not edit
 * @FilePath: \nginx_configg:\test_project\other\globalweb\plugins\to-inject.js
 * @Description: 跳转路径
 */

export default ({ app }, inject) => {
  inject('to', (path, toParams = {}) => {
    const { site, lang } = app.context.params
    const curpath = path ? `/${site}/${lang}${path}` : `/${site}/${lang}`
    return {
      name: 'site-lang-' + path,
      path: curpath,
      params: app.context.params,
      ...toParams,
    }
  })
}
